import React, {useContext} from 'react';
import ModalLayout from "../../../common/Modals";
import MarketplaceButton from "../../../common/MarketplaceButton";
import './style.scss'
import {Context} from "../../../../store";
import {useTokenContext} from "../../../../contexts/tokenContext";

const DeactivateKeyModal = ({isOpen, onClose, onUnstake}) => {
    const { selectedToken } = useTokenContext();

    const onContinue = () => {
        onClose()
        onUnstake()
    }

    return (
        <ModalLayout
            padding={'32px'}
            maxWidth={'458px'}
            maxHeight={'352px'}
            withCrossIcon
            onClose={onClose}
            isOpen={isOpen}
        >
            <p className='modal__title'>Deactivate key</p>
            <p className='modal__text deactivateKeyModal__text'>Are you sure to deactivate this key? This action cannot be undone. <span className='deactivateKeyModal__text--purple'>You are about
                to Deactivate key #{selectedToken?.tokenId}.</span> Make sure you to claim all of your rewards. Any TRESR rewards left on key after deactivation will be burned.</p>

            <div className='modal__actionButtons flex-grow flex justify-end items-end'>
                <MarketplaceButton onClick={onClose} title={'Cancel'}/>
                <MarketplaceButton onClick={onContinue} title={'Continue'} isBlue/>
            </div>
        </ModalLayout>
    );
};

export default DeactivateKeyModal;
