import React from 'react';
import './style.scss'
import Modal from "react-modal";
import {customStyles} from "./style";
import DashboardGameTab from "../DashboardGameTab";

const DashboardMobileModal = (props) => {

    return (
        <Modal isOpen={props?.isOpen} onRequestClose={props?.onClose} style={{...customStyles, content: {...customStyles.content, background: "#081326"}, overlay: {...customStyles.overlay, zIndex: 9}}}>
            <DashboardGameTab {...props}/>
        </Modal>
    );
};

export default DashboardMobileModal;
