import React from 'react';
import MarketplaceButton from "../../../common/MarketplaceButton";
import './style.scss'
import ModalLayout from "../../../common/Modals";
import {formatterUS} from "../../../../utils";

const OnStakeTRESRModal = ({
                               isOpen,
                               stake,
                               balance,
                               setStake,
                               onChangeStake,
                               onClose,
                               onStake,
                               onStakeClose,
                               title,
                               description,
                               placeholder,
                               marginTop = 0
                           }) => {
    const onClickMax = () => setStake(balance);

    return (
        <ModalLayout
            padding={'40px'}
            onClose={onClose}
            isOpen={isOpen}
            maxWidth={'458px'}
            maxHeight={'392px'}
            withCrossIcon
        >
            <h2 className="modal__title onStakeTRESRModal__title">{title}</h2>
            <p className='modal__text'>{description}</p>
            <p className='onStakeTRESRModal__text'>Available to stake: {formatterUS(balance)}</p>
            <div className='onStakeTRESRModal__inputContainer'>
                <input
                    className='modal__input onStakeTRESRModal__input'
                    type="number"
                    placeholder={placeholder}
                    value={stake}
                    onChange={onChangeStake}
                />
                <p className='onStakeTRESRModal__input--max' onClick={onClickMax}>max</p>
            </div>
            <div className='modal__actionButtons flex-grow justify-end items-end' style={{marginTop: `${marginTop}px`}}>
                <MarketplaceButton title={'Cancel'} onClick={onStakeClose}/>
                <MarketplaceButton title={'Continue'} onClick={onStake} isBlue disabled={+stake > +balance}/>
            </div>
        </ModalLayout>
    );
};

export default OnStakeTRESRModal;
