import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import {
  NAV_LINKS_FOOTER,
  SOCIALS_FOOTER,
  SOCIALS_FOOTER_LIGHT,
  FOOTER_WALLET_ADDRESS,
} from "../../../constant/singleNFTPage";
import { Context } from "../../../store";
import logoLightIcon from "../../../assets/images/logo-dark-new.svg";
import logoDarkIcon from "../../../assets/images/logo-dark-new.svg";
import footerImg from "../../../assets/images/footer_img.png";
import footerImgMobile from "../../../assets/images/footer_img_mobile.png";
import joinCommunityImg from "../../../assets/images/join_community.svg";

import eyeIcon from "../../../assets/images/eye.svg";
import walletIcon from "../../../assets/images/wallet.svg";
import Tooltip from "../../common/Tooltip";
import useWindowDimensions from "../../../hooks/useWidowDimensions";
import {useTokenContext} from "../../../contexts/tokenContext";
import {useAppContext} from "../../../contexts/appContext";

const SingleNFTFooter = () => {
  const [{ theme }] = useContext(Context);

  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 640;
  const isDarkTheme = theme === "dark";
  const [socials, setSocials] = useState(SOCIALS_FOOTER);
  const [isCopied, setIsCopied] = useState(-1);

  useEffect(() => {
    isDarkTheme ? setSocials(SOCIALS_FOOTER_LIGHT) : setSocials(SOCIALS_FOOTER);
  }, [isDarkTheme]);

  const onCopyImg = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setIsCopied(index);
      setTimeout(() => setIsCopied(-1), 3000);
    });
  };

  return (
    <div className={"singleNFTFooter"}>
      <div className={"singleNFTFooter__img"}>
        {isMobile ? (
          <img className="mx-auto" src={footerImgMobile} alt={"img"} />
        ) : (
          <img className="mx-auto" src={footerImg} alt={"img"} />
        )}
      </div>
      <div className={"singleNFTFooter__container"}>
        <div className={"singleNFTFooter__content"}>
          <div className={"singleNFTFooter__block1"}>
            <a href={"https://nftreasure.com"} target={"_blank"}>
              <img
                src={isDarkTheme ? logoLightIcon : logoDarkIcon}
                alt={""}
              />
            </a>
          </div>

          <div className="flex flex-row flex-wrap text-white gap-10">
            {FOOTER_WALLET_ADDRESS.map((item, key) => (
              <div
                key={key}
                className="flex justify-between items-center w-[335px]"
              >
                <div>{item.label}</div>
                <div className="border-white border rounded-[16px] flex text-[#BBC5FF] gap-2 px-3 leading-9">
                  <Tooltip
                    style={
                      isCopied === key
                        ? {
                            visibility: "visible",
                            opacity: "1",
                          }
                        : {}
                    }
                    width={isCopied == key ? 80 : 160}
                    top={-30}
                    tooltipText={
                      isCopied == key ? "Copied  ✓" : "Copy contract address"
                    }
                  >
                    <div
                      onClick={() => onCopyImg(item.addr, key)}
                      className="cursor-pointer"
                    >
                      {item.addr.slice(0, 4) + "..." + item.addr.slice(-4)}
                    </div>
                  </Tooltip>

                  <div className="bg-white w-[1px]"></div>
                  <div>
                    <Tooltip
                      tooltipText="View on Snowtrace.io"
                      top={-40}
                      left={isMobile ? -250 : 50}
                    >
                      <a
                        href={`https://${process.env.REACT_APP_IS_BETA_TEST === 'true' ? 'testnet.' : ''}snowtrace.io/address/${item.addr}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={eyeIcon} alt="View Token on Snowtrace"/>
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="sm:flex items-center justify-between mt-10 mb-10">
          <div>
            <img className="mx-auto" src={joinCommunityImg} alt={"img"} />
          </div>

          <div className={"flex justify-center"}>
            {socials?.map((item, key) => (
              <div key={key}>
                <a href={item?.link} target="_blank" rel="noreferrer">
                  <img src={item?.image} alt={""} />
                </a>
              </div>
            ))}
          </div>

          <div className="singleNFTFooter__copyright">
            <p className="text-center">
              Copyright © 2024 NFTREASURE Platform. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleNFTFooter;
