import React, { useContext, useEffect } from "react";
import "./style.scss";
import crossImg from "../../../assets/images/cross.svg";
import { Context } from "../../../store";
import {
  ALERT_STATUS_FAILURE,
  ALERT_STATUS_INFO,
  ALERT_STATUS_SUCCESS,
} from "../../../constant/alert";
import viewImgLight from "assets/images/singleCollectionPage/viewImg_light.svg";
import {AVALANCHE_NETWORK_PARAMS} from "../../../constant/blockchain";

const CustomAlert = () => {
  // const [{},ACTION, balance] = useContext(Context);
  // const alert = {
  //     isActive : true,
  //     status: ALERT_STATUS_SUCCESS,
  //     text: "Transaction"
  // }
  const [{ alert, balance }, ACTION] = useContext(Context);
  const classNameStatus = {
    [ALERT_STATUS_SUCCESS]: "customAlert__success",
    [ALERT_STATUS_FAILURE]: "customAlert__failure",
    [ALERT_STATUS_INFO]: "customAlert__info",
  };

  const onClose = () => ACTION.SET_ALERT(false);

  useEffect(() => {
    if (alert?.isActive) setTimeout(onClose, 5000);
  }, [alert?.isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  // if (!alert?.isActive) return null
  return (
    <div
      className={`customAlert ${
        alert?.isActive ? classNameStatus[alert?.status] : ""
      }`}
    >
      <div className={"customAlert__container"}>
        <div className={"customAlert__content"}>
          {!!alert?.image && (
            <video
              src={alert?.image}
              className={"customAlert__image"}
              preload="metadata"
            />
          )}
          {!!alert?.text && (
            <p className={"customAlert__text"}>{alert?.text}</p>
          )}
        </div>

        <div className="customAlert__close" onClick={onClose}>
          <img src={crossImg} alt={"close"} />
        </div>
        {classNameStatus[alert?.status] != "customAlert__failure" && (
          <a
            href={`${AVALANCHE_NETWORK_PARAMS.explorerUrl}tx/${balance?.transactionHash}`}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
            className="flex text-white pl-2 gap-3"
            //className="singleCollectionPage__dropdown--link text-white"
          >
            View on Snowtrace
            <img src={viewImgLight} alt="" />
          </a>
        )}
      </div>
    </div>
  );
};

export default CustomAlert;
