import useHandleContracts from "./useHandleContracts";
import {useAppContext} from "../../contexts/appContext";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

export default function useFaucet() {
  const { refreshBalances } = useAppContext();
  const handleContracts = useHandleContracts();
  const { address } = useWeb3ModalAccount();
  const getSMRTR = async () => {
    return handleContracts
      .contractSmarterCoinWithSigner
      .faucet([address])
      .then(async (tx) => {
        await tx.wait(2);
        await refreshBalances();
      });
  };

  const getTRESR = async () => {
    if (handleContracts.contractTresrFaucetWithSigner) {
      const tx = await handleContracts
        .contractTresrFaucetWithSigner
        .faucet()

      await tx.wait(2);
      await refreshBalances();
    } else {
      console.error("contractTresrFaucetWithSigner not set")
    }
  };

  const getSMRTRLP = async () => {
    const tx = await handleContracts
      .contractLpCoinSMRTRAVAXWithSigner
      .faucet([address])

    await tx.wait(2);
    await refreshBalances();
  };

  const getTRESRLP = async () => {
    const tx = await handleContracts
      .contractLpCoinTRESRAVAXWithSigner
      .faucet([address])

    await tx.wait(2);
    await refreshBalances();
  };

  return {
    getSMRTR,
    getTRESR,
    getSMRTRLP,
    getTRESRLP,
  };
}
