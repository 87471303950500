import {types} from "../types";

const actions = {
    [types.SET_SCALE]:
        (dispatch) =>
            (isActive = false, value = 220) => {
                dispatch({
                    type: types.SET_SCALE,
                    payload: {isActive, value},
                });
            },
};

export default actions;
