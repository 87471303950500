import React from "react";
import { formatterUS } from "../../../../../utils";
import useWindowDimensions from "hooks/useWidowDimensions";
import {BASE_REWARD} from "../../../../../constant/blockchain";

const TreasureBoxResultItem = ({ token }) => {
  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 750;
  return (
    <div className={`upgradeAllKeysModal__list--item ${token.result === 'pending' ? 'grayscale' : token.result === 'success' ? 'success' : 'failure'}`}>
      <div className="upgradeAllKeysModal__wrapper">
        <img
          width={56}
          height={56}
          src={token?.image}
          alt=""
          className="upgradeAllKeysModal__image"
        />
        <div className="upgradeAllKeysModal__name--wrapper">
          <span className="upgradeAllKeysModal__name">
            Treasure Box #{token?.tokenId}
          </span>
          { token.result === 'success' &&
            <div className="upgradeAllKeysModal__level">
              <span>New Tier {+token?.tierTresr}</span>
            </div>
          }
          { token.result === 'fail' &&
            <div className="upgradeAllKeysModal__level">
              <span>Stayed at Tier {token?.tierTresr}</span>
            </div>
          }
          { token.result === 'pending' &&
            <div className="upgradeAllKeysModal__level">
              <span>Waiting for result...</span>
            </div>
          }
          {isMobile && (
            <span className="modal__text text-[16px] mt-1 text-[var(--currencyInputLabel)]">
              {formatterUS(token.level * (token.tierTresr) * BASE_REWARD * 30)} TRESR/MONTH
            </span>
          )}
        </div>
      </div>
      <div className="upgradeAllKeysModal__wrapperRight">
        {!isMobile && <span>{formatterUS(token.level * (token.tierTresr) * BASE_REWARD * 30)} TRESR/MONTH</span>}
      </div>
    </div>
  );
};

export default TreasureBoxResultItem;
