import React, {useCallback, useContext} from 'react';
import './style.scss'
import ModalLayout from "../../../common/Modals";
import MarketplaceButton from "../../../common/MarketplaceButton";
import {useTokenContext} from "../../../../contexts/tokenContext";

const ActivateKeyModal = ({isOpen, onClose, onStake}) => {
    const { selectedToken } = useTokenContext();

    const onContinue = useCallback(() => {
        onClose()
        onStake(selectedToken.tokenId);
    }, [selectedToken]);

    return (
        <ModalLayout
            padding={'32px'}
            maxWidth={'458px'}
            maxHeight={'368px'}
            withCrossIcon
            onClose={onClose}
            isOpen={isOpen}
        >
            <p className='modal__title'>Activate Treasure Key?</p>
            <p className='modal__text deactivateKeyModal__text'>Activating your Treasure Key allows you to open Treasure Boxes and compete for $TRESR rewards.
            Your daily $TRESR Rewards grow as you upgrade your "Key Level" and "Treasure Tier". <br/>You are about to activate
                <span
                    className='deactivateKeyModal__text--purple'>  Treasure Key #{selectedToken?.tokenId}.</span>
            </p>

            <div className='modal__actionButtons flex-grow flex justify-end items-end'>
                <MarketplaceButton onClick={onClose} title={'Cancel'}/>
                <MarketplaceButton onClick={onContinue} title={'Continue'} isBlue/>
            </div>
        </ModalLayout>
    );
};

export default ActivateKeyModal;
