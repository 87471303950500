import { types } from "../types";
import LOADER from "./loader";
import ALERT from "./alert";
import SCALE from "./scale";

const actions = {
  ...LOADER,
  ...ALERT,
  ...SCALE,
  [types.SET_TIMESTAMP]:
    (dispatch) =>
    (value = {}) => {
      dispatch({
        type: types.SET_TIMESTAMP,
        payload: value,
      });
    },
  [types.SET_TOKENS_COUNT]:
    (dispatch) =>
    (value = {}) => {
      dispatch({
        type: types.SET_TOKENS_COUNT,
        payload: value,
      });
    },
  [types.SET_PENDING_LOADER]:
    (dispatch) =>
    (value = {}) => {
      dispatch({
        type: types.SET_PENDING_LOADER,
        payload: value,
      });
    },

  [types.SET_CHEST_MODAL]:
    (dispatch) =>
    (value = {}) => {
      dispatch({
        type: types.SET_CHEST_MODAL,
        payload: value,
      });
    },
  [types.SET_ACCOUNT_BANNER_IMAGE]:
    (dispatch) =>
    (value = {}) => {
      dispatch({
        type: types.SET_ACCOUNT_BANNER_IMAGE,
        payload: value,
      });
    },
};

export default actions;
