import React, {useState, useEffect} from 'react';
import {customStyles} from "./style";
import Modal from "react-modal";
import step1 from '../../../../assets/images/steps_1.svg'
import step3 from '../../../../assets/images/steps_2.svg'
import step2 from '../../../../assets/images/steps_3.svg'
import './style.scss'
import MarketplaceButton from "../../MarketplaceButton";

const ApproveLoadingModal = ({isOpen, onClose, isApproved, statusText, onNextStep}) => {
    const [isDisabledNextStep, setIsDisabledNextStep] = useState(true)
    const [isDisabledCancel, setIsDisabledCancel] = useState(false)
    const [buttonText, setButtonText] = useState('')
    const [loadingImage, setLoadingImage] = useState(step1)

    const handleNextStep = () => {
        onNextStep()
        setIsDisabledNextStep(true)
        setIsDisabledCancel(true)
        setButtonText(statusText.loadingButton)
        setLoadingImage(step3)
    }

    useEffect(() => {
        setIsDisabledNextStep(!isApproved)
        setButtonText(isApproved ? statusText.button : 'Approving')
        setLoadingImage(isApproved ? step2 : step1)
    }, [isApproved])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal isOpen={isOpen} style={customStyles}>

            <div className='approveModal__background'>
                <div className={`approveModal__image 
                ${isApproved ? 'approveModal__image--isApproved' : ''}
                ${!isDisabledNextStep ? 'approveModal__image--noSpinner' : ''}
                `}>
                    <img src={loadingImage} alt={'waiting for approve'}/>
                </div>

                <div className={`approveModal__container ${!isDisabledNextStep ? 'approveModal__container--blurred' : ''}`}>
                    <div className={'approveModal__info'}>
                        <p className={'modal__text'}>{statusText.firstTitle}</p>
                        <p className={'modal__text modal__text--small'}>{statusText.firstText}</p>
                    </div>

                    <div className='approveModal__info'>
                        <p className={'modal__text'}>{statusText.secondTitle}</p>
                        <p className={'modal__text modal__text--small'}>{statusText.secondText}</p>
                    </div>
                </div>

                <div className='approveModal__buttons'>
                    <div className={`approveModal__buttons--note ${!isDisabledNextStep ? 'approveModal__buttons--note__visible' : ''}`}>
                        Click to {buttonText}
                    </div>

                    <MarketplaceButton
                        isNormal
                        title={'Cancel'}
                        onClick={onClose}
                        disabled={isDisabledCancel}
                    />
                    <MarketplaceButton
                        title={buttonText}
                        onClick={handleNextStep}
                        isBlue
                        disabled={isDisabledNextStep}
                        isAnimated={!isDisabledNextStep}
                    />
                </div>

            </div>

        </Modal>
    );
};

export default ApproveLoadingModal;
