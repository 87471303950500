import { Routes, Route } from "react-router-dom";

import HomePage from '../components/HomePage'
import Dashboard from '../components/Dashboard'

export default function Routers() {
    return (
        <>
            <Routes>
                <Route path={`/`} element={<HomePage />}/>
                <Route path={`/game`} element={<Dashboard />}/>
                <Route path={`/game/dashboard`} element={<Dashboard />}/>
            </Routes>
        </>
    );
};
