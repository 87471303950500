import { types } from "../types";

export const initialStateBalance = {
  balance: {
    transactionHash: "",
  },
};

export const reducerBalance = {
  [types.SET_TRANSANCTION_HASH]: (state, transactionHash) => ({
    ...state,
    balance: {
      ...state.balance,
      transactionHash,
    },
  }),
};
