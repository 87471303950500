import React, { useContext, } from "react";
import { Context } from "../../../store";
import MarketplaceButton from "../../common/MarketplaceButton";
import infoImg from "../../../assets/images/info_img.svg";
import infoImgDark from "../../../assets/images/info_img_dark.svg";
import { formatterUS } from "../../../utils";
import Tooltip from "components/common/Tooltip";
import useWindowDimensions from "hooks/useWidowDimensions";
import {useRewardsContext} from "../../../contexts/rewardsContext";
import {useStakingContext} from "../../../contexts/stakingContext";

const DashboardBonusCard = ({ onClaimBonus }) => {
  const [{ theme }] = useContext(Context);
  const { rewardsBalances } = useRewardsContext();
  const { stakingBalances } = useStakingContext();
  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 640;

  const isDark = theme === "dark";

  const bonusTooltip = `The NFTREASURE Bonus Pool is divided into four levers, each weighted differently. Below find the percentage of each reward lever you control. Your daily Bonus Rewards are relational to this percentage.`;

  if (!isMobile)
    return (
      <div className="dashboardBonusCard">
        <div className="totalPoolReward__image">
          <Tooltip tooltipText={bonusTooltip} top={0} left={-650} isBottom>
            <img src={isDark ? infoImg : infoImgDark} alt={""} />
          </Tooltip>
        </div>

        <div className="dashboardBonusCard__actions">
          <div className="votingEscrow__actions--title">Bonus Rewards</div>
          <div className="flex pl-7">
            <div className="votingEscrow__actions--count">
              {formatterUS(rewardsBalances.balanceUnclaimedBonusRewards, 3)}
            </div>
          </div>
          <div className="votingEscrow__actions--subtitle">
            Available $TRESR to claim
          </div>

          <div className="dashboardBonusCard__actions--button">
            <MarketplaceButton
              title="Claim Bonus Rewards"
              onClick={() => onClaimBonus()}
            />
          </div>
        </div>
        <div className="votingEscrow__info rewards__info">
          <ul>
            <li>
              <span className="votingEscrow__info__label">
                Total Rewards Share
              </span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {/* FIXME: what a ugly naming convention */}
                  {formatterUS(rewardsBalances.balanceBonusTotal, 3)}%
                </span>
              </div>
            </li>
            <li>
              <div className="flex justify-between w-full">
                <span className="votingEscrow__info__label w-1/2">
                  Cumulative Key level
                </span>
                <div className="flex">
                  <span className="w-[40px] text-right votingEscrow__info__value">
                    {rewardsBalances.cumulativeKeyLevel}
                  </span>
                </div>
              </div>
            </li>
            <li>
              <span className="votingEscrow__info__label">
                Est. Daily Bonus Rewards
              </span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(rewardsBalances.dailyRewards, 2)}
                </span>
              </div>
            </li>
            <li>
              <span className="votingEscrow__info__label">
                Est. Monthly Bonus Rewards
              </span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(rewardsBalances.monthlyRewards, 2)}
                </span>
              </div>
            </li>
            <li>
              <div className="bg-[#93A3F830] h-[1px] w-full"></div>
            </li>
            <li>
              <div className="flex justify-between w-full">
                <div className="w-1/3">Rewards Pool</div>
                <div className="w-[60px] text-right">Weight</div>
                <div className="w-[60px] text-right">Share</div>
                <div className="w-[60px] text-right">Earned</div>
              </div>
            </li>
            <li>
              <div className="flex justify-between w-full">
                <span className="votingEscrow__info__label w-1/3">veTRESR</span>
                <div className="w-[60px] text-right -ml-6">35%</div>
                <div className="w-[60px] text-right">
                  {formatterUS(rewardsBalances.veTresrShareP, 3)}%
                </div>
                <div className="flex">
                  <span className="w-[60px] text-right votingEscrow__info__value">
                    {formatterUS(rewardsBalances.balanceBonusVeTresr, 3)}
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="flex justify-between w-full">
                <span className="votingEscrow__info__label w-1/3">
                  SMRTR-AVAX-LP
                </span>
                <div className="w-[60px] text-right -ml-6">30%</div>
                <div className="w-[60px] text-right">
                  {formatterUS(stakingBalances.balanceLpTotalStakedSMRTRAVAX, 3)}%
                </div>
                <div className="flex">
                  <span className="w-[60px] text-right votingEscrow__info__value">
                    {formatterUS(rewardsBalances.balanceBonusJlpSmartr, 3)}
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="flex justify-between w-full">
                <span className="votingEscrow__info__label w-1/3">
                  TRESR-AVAX-LP
                </span>
                <div className="w-[60px] text-right -ml-6">25%</div>
                <div className="w-[60px] text-right">
                  {formatterUS(stakingBalances.balanceLpTotalStakedTRESRAVAX, 3)}%
                </div>
                <div className="flex">
                  <span className="w-[60px] text-right votingEscrow__info__value">
                    {formatterUS(rewardsBalances.balanceBonusJlpTresr, 3)}
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="flex justify-between w-full">
                <span className="votingEscrow__info__label w-1/3">
                  Key Level
                </span>
                <div className="w-[60px] text-right -ml-6">10%</div>
                <div className="w-[60px] text-right">
                  {formatterUS(rewardsBalances.KeyLevelP, 3)}%
                </div>
                <div className="flex">
                  <span className="w-[60px] text-right votingEscrow__info__value">
                    {formatterUS(rewardsBalances.balanceBonusKeyLevel, 3)}
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div className="bg-[#93A3F830] h-[1px] w-full"></div>
            </li>
            <li>
              <span className="votingEscrow__info__label">
                Total Earned
              </span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(rewardsBalances.balanceBonusKeyLevel + rewardsBalances.balanceBonusJlpTresr + rewardsBalances.balanceBonusJlpSmartr + rewardsBalances.balanceBonusVeTresr, 2)}
                </span>
              </div>
            </li>

          </ul>
        </div>
      </div>
    );
  else
    return (
      <div className="dashboardBonusCard">
        <div className="totalPoolReward__image">
          <Tooltip tooltipText={bonusTooltip} top={0} left={-650} isBottom>
            <img src={isDark ? infoImg : infoImgDark} alt={""} />
          </Tooltip>
        </div>

        <div className="dashboardBonusCard__actions">
          <div className="votingEscrow__actions--title">Bonus Rewards</div>
          <div className="flex pl-7">
            <div className="votingEscrow__actions--count">
              {formatterUS(rewardsBalances.balanceUnclaimedBonusRewards, 3)}
            </div>
          </div>
          <div className="votingEscrow__actions--subtitle">
            Available $TRESR to claim
          </div>

          <div className="dashboardBonusCard__actions--button">
            <MarketplaceButton
              title="Claim Bonus Rewards"
              onClick={() => onClaimBonus()}
            />
          </div>
        </div>
        <div className="votingEscrow__info rewards__info">
          <ul>
            <li>
              <span className="votingEscrow__info__label">
                Total Rewards Share
              </span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(rewardsBalances.balanceBonusTotal, 3)}%
                </span>
              </div>
            </li>
            <li>
              <span className="votingEscrow__info__label">
                Cumulative Key level
              </span>
              <div className="flex">
                <div className="flex w-fill">
                  <span className="w-[40px] text-right votingEscrow__info__value">
                    {rewardsBalances.cumulativeKeyLevel}
                  </span>
                </div>
              </div>
            </li>
            <li>
              <span className="votingEscrow__info__label">Est. Daily</span>
              <div className="flex">
                <span className="votingEscrow__info__value">{formatterUS(rewardsBalances.dailyRewards, 2)}</span>
              </div>
            </li>
            <li>
              <span className="votingEscrow__info__label">Est. Monthly</span>
              <div className="flex">
                <span className="votingEscrow__info__value">{formatterUS(rewardsBalances.monthlyRewards, 2)}</span>
              </div>
            </li>
            <li>
              <div className="bg-[#93A3F830] h-[1px] w-full"></div>
            </li>
            <li>
              <div className="flex justify-between w-full">
                <div className="w-[60px] text-right">Weight</div>
                <div className="w-[60px] text-right">Share</div>
                <div className="w-[60px] text-right">Daily</div>
              </div>
            </li>
            <li>
              <div className="flex flex-col w-full">
                <span className="votingEscrow__info__label">veTRESR</span>
                <div className="flex justify-between w-full">
                  <div className="w-[60px] text-right -ml-6">35%</div>
                  <div className="w-[60px] text-right">
                    {formatterUS(rewardsBalances.veTresrShareP, 3)}%
                  </div>
                  <div className="flex">
                    <span className="w-[60px] text-right votingEscrow__info__value">
                      {formatterUS(rewardsBalances.balanceBonusVeTresr, 3)}
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="flex flex-col w-full">
                <span className="votingEscrow__info__label">SMRTR-AVAX-LP</span>
                <div className="flex justify-between w-full">
                  <div className="w-[60px] text-right -ml-6">30%</div>
                  <div className="w-[60px] text-right">
                    {formatterUS(stakingBalances.balanceLpTotalStakedSMRTRAVAX, 3)}%
                  </div>
                  <div className="flex">
                    <span className="w-[60px] text-right votingEscrow__info__value">
                      {formatterUS(rewardsBalances.balanceBonusJlpSmartr, 3)}
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="flex flex-col w-full">
                <span className="votingEscrow__info__label">TRESR-AVAX-LP</span>
                <div className="flex justify-between w-full">
                  <div className="w-[60px] text-right -ml-6">25%</div>
                  <div className="w-[60px] text-right">
                    {formatterUS(stakingBalances.balanceLpTotalStakedTRESRAVAX, 3)}%
                  </div>
                  <div className="flex">
                    <span className="w-[60px] text-right votingEscrow__info__value">
                      {formatterUS(rewardsBalances.balanceBonusJlpTresr, 3)}
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="flex flex-col w-full">
                <span className="votingEscrow__info__label">Key Level</span>
                <div className="flex justify-between w-full">
                  <div className="w-[60px] text-right -ml-6">10%</div>
                  <div className="w-[60px] text-right">
                    {formatterUS(rewardsBalances.KeyLevelP, 3)}%
                  </div>
                  <div className="flex w-fill">
                    <span className="w-[60px] text-right votingEscrow__info__value">
                      {formatterUS(rewardsBalances.balanceBonusKeyLevel, 3)}
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="bg-[#93A3F830] h-[1px] w-full"></div>
            </li>
            <li>
              <span className="votingEscrow__info__label">
                Total Earned
              </span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(rewardsBalances.balanceBonusKeyLevel + rewardsBalances.balanceBonusJlpTresr + rewardsBalances.balanceBonusJlpSmartr + rewardsBalances.balanceBonusVeTresr, 2)}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
};

export default DashboardBonusCard;
