export const PubSubEvents = {
  BALANCE_CHANGED: 'BALANCE_CHANGED',
  LOGOUT: 'LOGOUT',
  NFKEYS_CHANGED:  { event: 'NFKEYS_CHANGED', args: (keyIds) => ({keyIds})},
  PROBABILITY_READY: 'PROBABILITY_READY',
  PROBABILITY_EMPTY: 'PROBABILITY_EMPTY',
  CHESTS_OPENED:  { event: 'CHESTS_OPENED', args: (keyIds, successes) => ({keyIds, successes }) },
  KEY_UPGRADED: { event: 'KEY_UPGRADED', args: (keyId) => keyId},
  NFKEYS_BATCH_MINT: 'NFKEYS_BATCH_MINT',
  PRIZE_RESULT: { event: 'PRIZE_RESULT', args: (prizes) => ({ prizes }) }
}