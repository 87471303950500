import React from "react";
import "./style.scss";
import accountArrowDark from "../../../assets/images/account_arrow_dark.svg";
import useWindowDimensions from "../../../hooks/useWidowDimensions";
import HeaderBarAccountComponent from "../HeaderBarAccountComponent";
import { customStylesMobile } from "../../common/stylesMobile";
import { customStylesTablet } from "../../common/stylesTablet";
import Modal from "react-modal";

const HeaderBarAccountOpen = ({ isAccountOpen, setIsAccountOpen }) => {
  const windowParams = useWindowDimensions();
  const isDesktop = windowParams?.width > 1024;
  const isMobile = windowParams?.width <= 450;

  return isDesktop ? (
    <div
      className="headerBarAccountOpen__container"
      onMouseEnter={() => setIsAccountOpen(true)}
      onMouseLeave={() => setIsAccountOpen(false)}
    >
      <div
        className={`headerBarAccountOpen ${
          isAccountOpen ? "headerBarAccountOpen__visible" : ""
        }`}
      >
        <div className="headerBarAccountOpen__arrow">
          <img src={accountArrowDark} className="float-right" alt={""} />
        </div>
        <HeaderBarAccountComponent setIsAccountOpen={setIsAccountOpen} />
      </div>
    </div>
  ) : (
    <Modal
      isOpen={isAccountOpen}
      onRequestClose={() => setIsAccountOpen(false)}
      style={
        isMobile
          ? {
              ...customStylesMobile,
              content: {
                ...customStylesMobile.content,
                background: "#081326",
                height: "500px",
              },
            }
          : {
              ...customStylesTablet,
              content: { ...customStylesTablet.content, background: "#081326" },
            }
      }
    >
      <div className="headerBarAccountOpen__mobile">
        <div className="flex justify-center mb-[32px]">
          <div className="w-[70px] h-[4px] bg-[#93A3F8] opacity-30 rounded-[24px]"></div>
        </div>
        <HeaderBarAccountComponent setIsAccountOpen={setIsAccountOpen} />
      </div>
    </Modal>
  );
};

export default HeaderBarAccountOpen;