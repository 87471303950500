import { types } from "../types";

export const initialStateLoader = {
  loader: {
    isActive: 0,
    opacityLevel: 3,
    componentActive: false
  },
};

export const reducerLoader = {
  [types.SET_LOADER]: (state, { isActive, opacityLevel }) => ({
    ...state,
    loader: {
      ...state.loader,
      isActive,
      opacityLevel,
    },
  }),
  [types.SET_COMPONENT_LOADER]: (state, { componentActive }) => ({
    ...state,
    loader: {
      ...state.loader,
      componentActive
    },
  }),
};
