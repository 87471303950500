import React, {useContext, useState, useEffect, useCallback} from "react";
import { Context } from "../../store";
import useHandleNFT from "../../hooks/blockchain/useHandleNFT";
import Dropdown from "../common/Dropdown";
import "./style.scss";
import {
  DASHBOARD_DROPDOWN_ITEMS_DEFAULT,
  DASHBOARD_FILTERS_DEFAULT,
  DASHBOARD_TAB_MENU_DASHBOARD,
  DASHBOARD_TAB_MENU_DASHBOARD_LINK,
  DASHBOARD_TAB_MENU_FAQ,
  DASHBOARD_TAB_MENU_FAQ_LINK,
  DASHBOARD_TAB_MENU_GAME,
  DASHBOARD_TAB_MENU_GAME_LINK,
  FILTER_ACTIVE,
  FILTER_INACTIVE,
  SORT_HIGHEST_KEY_LEVEL,
  SORT_HIGHEST_TOKENID,
  SORT_LOWEST_KEY_LEVEL,
  SORT_HIGHEST_TIER,
  SORT_LOWEST_TIER,
} from "../../constant/navigation";
import { useLocation, useNavigate } from "react-router-dom";
import useHandleModal from "../../hooks/dom/useHandleModal";
import DashboardFilters from "./DashboardFilters";
import DashboardTokenList from "./DashboardTokenList";
import DashboardMobileModal from "./DashboardMobileModal";
import useWindowDimensions from "../../hooks/useWidowDimensions";
import DashboardGameTab from "./DashboardGameTab";
import DashboardTab from "./DashboardTab";
import TreasureBoxModal from "../common/DashboardModals/TreasureBoxModal";
import TransactionLoadingModal from "../common/Modals/TransactionLoadingModal";
import DeactivateKeyModal from "./DashboardModals/DeactivateKeyModal";
import ActivateKeyModal from "./DashboardModals/ActivateKeyModal";
import { compareUnixDates, formatterUS } from "../../utils";
import SuccessKeyUpgradeModal from "../common/Modals/SuccessKeyUpgradeModal";
import ExpandedNFTView from "../common/Modals/ExpandedNFTView";
import MarketplaceButton from "../common/MarketplaceButton";
import ActivateAllKeysModal from "../common/Modals/ActivateAllKeysModal";
import UpgradeAllKeysModal from "../common/Modals/UpgradeAllKeysModal";
import {
  APPROVE_STATUS_TEXT,
} from "../../constant/approveLoadingModal";
import ApproveLoadingModal from "../common/Modals/ApproveLoadingModal";
import UpgradeKeyModal from "../common/Modals/UpgradeKeyModal";
import OpenTreasureBoxModal from "../common/Modals/OpenTreasureBoxModal";
import TreasureWarningModal from "../common/Modals/TreasureWarningModal";
import DashboardKeyRewards from "./DashboardKeyRewards";
import FAQsItem from "components/FAQsPage/FAQsItem";
import DashboardNotWhitelisted from "./DashboardNotWhitelisted";
import NFTFooter from "components/Dashboard/NFTFooter";
import {useTokenContext} from "../../contexts/tokenContext";
import {useAppContext} from "../../contexts/appContext";
import {useRewardsContext} from "../../contexts/rewardsContext";
import {useStakingContext} from "../../contexts/stakingContext";
import PubSub from "pubsub-js";
import {PubSubEvents} from "../../constant/events";
import OpenAllTreasureBoxesModal from "../common/Modals/OpenAllTreasureBoxes";
import OpenAllTreasureBoxesResultModal from "../common/Modals/OpenAllTreasureBoxesResult";
import useHandleToastAlert from "../../hooks/alert/useHandleToastAlert";

export default function Dashboard() {
  const [{ loader }] = useContext(Context);
  const { tokenList, selectedToken, setSelectedToken, upgradeKey, unlockTreasure, bulkStake, bulkUnlockBoxes, prizeSeasonRoot } = useTokenContext();
  const { balances } = useAppContext();
  const { prizeSeasonBoosts } = useTokenContext();
  const { stakeTresr, unstakeTresr, unstakeLP, stakeLP, restrictedStaking, approveTRESR } = useStakingContext();
  const { claimVeTresr, claimBonus, claimAll, claimAllBase, claimBase } = useRewardsContext();
  const [upgradeAllKeysFlag, setUpgradeAllKeysFlag] = useState(false);
  const [chestStatus, setChestStatus] = useState(null);
  const [activeTab, setActiveTab] = useState(DASHBOARD_TAB_MENU_DASHBOARD);
  const [activeFilter, setActiveFilter] = useState(DASHBOARD_FILTERS_DEFAULT);
  const [activeDropdownItem, setActiveDropdownItem] = useState(
    DASHBOARD_DROPDOWN_ITEMS_DEFAULT
  );
  const [isAnimated, setIsAnimated] = useState(false);
  const [isMaxUpgrades, setIsMaxUpgrades] = useState(true);
  const [showBulkBoxOpen, setShowBulkBoxOpen] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [amountApproved, setAmountApproved] = useState(0);
  const [tokenListToUpgrade, setTokenListToUpgrade] = useState([]);
  const [filterTokenList, setFilterTokenList] = useState([]);
  const [isNotActiveToken, setIsNotActiveToken] = useState([]);
  const [bulkMode, setBulkMode] = useState('keys');
  const [bulkOpenInProgress, setBulkOpenInProgress] = useState(false);
  const [treasureBoxResults, setTreasureBoxResults] = useState([]);
  const [prizeSeasonResults, setPrizeSeasonResults] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const handleNFT = useHandleNFT();
  const handleToastAlert = useHandleToastAlert();

  const handleTreasureModal = useHandleModal();
  const handleChestModal = useHandleModal();
  const handleUpgradeModal = useHandleModal();
  const handleSuccessfulUpgradeModal = useHandleModal();
  const handleTreasureWarningModal = useHandleModal();
  const handleTransactionLoadingModal = useHandleModal();
  const handleDeactivateKeyModal = useHandleModal();
  const handleActivateKeyModal = useHandleModal();
  const handleExpandedKeyViewModal = useHandleModal();
  const handleActivateAllKeysModal = useHandleModal();
  const handleUpgradeAllKeysModal = useHandleModal();
  const handleOpenAllTreasureBoxesModal = useHandleModal();
  const handleOpenAllTreasureBoxesResultsModal = useHandleModal();

  const handleApproveUpgradeModal = useHandleModal();
  const handleApproveUpgradeAllModal = useHandleModal();
  const handleDashboardMobileModal = useHandleModal();

  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 640;



  const callbackOpenTreasure = (status) => {
    if (status === null) return;
    setChestStatus(status);
    handleChestModal.open();
  };

  const onStakeTresr = (value) => {
    stakeTresr(value);
  };
  const onUnstakeTresr = async (value) => {
    handleTransactionLoadingModal.open();
    await unstakeTresr(value)
      .finally(() => handleTransactionLoadingModal.close());
  };
  const onBulkStake = (tokenList) => {
    handleActivateAllKeysModal.close();
    handleTransactionLoadingModal.open();
    bulkStake(tokenList)
      .finally(() => handleTransactionLoadingModal.close());
  };
  const onStake = (tokenId) => {
    handleTransactionLoadingModal.open();
    bulkStake([tokenId]).finally(() => handleTransactionLoadingModal.close());
  };
  const onUnstake = () => {
    handleTransactionLoadingModal.open();
    handleNFT.unstake().finally(() => handleTransactionLoadingModal.close());
  };
  const onBulkApproveKey = useCallback((amountSMRTR) => {
    handleUpgradeAllKeysModal.close();
    handleApproveUpgradeAllModal.open();
    setIsApproved(false);
    setBulkMode('keys');
    handleNFT
      .approveSMRTRByAmount(amountSMRTR)
      .then(() => {
        setIsApproved(true);
      })
      .catch(() => {
        setIsApproved(false);
        handleApproveUpgradeAllModal.close();
        return null;
      });
  }, [handleNFT.approveSMRTRByAmount]);

  const onBulkUpgradeKey = () => {
    handleNFT.bulkUpgradeKeys(tokenListToUpgrade).finally(() => {
      handleApproveUpgradeAllModal.close();
      setIsApproved(false);
      setTokenListToUpgrade([]);
    });
  };

  const onBulkOpenChestsApprove = useCallback((amountTRESR) => {
    handleOpenAllTreasureBoxesModal.close();
    handleApproveUpgradeAllModal.open();
    setIsApproved(false);
    setAmountApproved(0);
    setBulkMode('chests');
    approveTRESR(amountTRESR, 'chest')
      .then(() => {
        setIsApproved(true);
        setAmountApproved(amountTRESR);
      })
      .catch(() => {
        handleToastAlert.error('Problem approving. Try again or report to support');
        setIsApproved(false);
        setAmountApproved(0);
        handleApproveUpgradeAllModal.close();
        return null;
      });
  }, [approveTRESR]);

  const onBulkOpenChests = useCallback(() =>  {
    setBulkOpenInProgress(true);
    setTreasureBoxResults(tokenListToUpgrade.map(tokenId => ({
      tokenId,
      result: 'pending'
    })));
    setPrizeSeasonResults([]);
    bulkUnlockBoxes(tokenListToUpgrade, amountApproved).then(() => {
      handleOpenAllTreasureBoxesResultsModal.open();
    }).catch(() => {
      handleToastAlert.error('Problem opening boxes. Try again or report to support');
      setBulkOpenInProgress(false);
      setTreasureBoxResults([]);
      setPrizeSeasonResults([]);
    }).finally(() => {
      handleApproveUpgradeAllModal.close();
      setIsApproved(false);
      setAmountApproved(0);
      setTokenListToUpgrade([]);
    });
  }, [amountApproved]);

  const onCloseAllTreasureBoxesResults = () => {
    handleOpenAllTreasureBoxesResultsModal.close();
    setBulkOpenInProgress(false);
    setTreasureBoxResults([]);
    setPrizeSeasonResults([]);
  }

  const onApproveUpgradeKey = () => {
    handleUpgradeModal.close();
    handleApproveUpgradeModal.open();
    handleNFT
      .approveSMRTR()
      .then(() => setIsApproved(true))
      .catch((err) => {
        console.error(err)
        setIsApproved(false);
        return null;
      });
  };

  const onUpgradeKey = () => {
   upgradeKey()
      .catch(() => {
        handleApproveUpgradeModal.close();
        setIsApproved(false);
      });
  };

  useEffect(() => {
    const keyChanged = PubSub.subscribe(PubSubEvents.KEY_UPGRADED.event, (_, id) => {
      handleApproveUpgradeModal.close();
      setIsApproved(false);
      handleSuccessfulUpgradeModal.open();
    });

    return () => {
      PubSub.unsubscribe(keyChanged);
    }
  }, []);

  const onOpenTreasure = useCallback(async () => {
    setChestStatus(null);
    setIsAnimated(true);
    handleChestModal.close();
    handleTreasureModal.close();
    handleTransactionLoadingModal.open();
    setPrizeSeasonResults([]);
    try {
      await unlockTreasure();
    } catch (err) {
      console.log(err)
      handleTransactionLoadingModal.close();
      setIsAnimated(false);
    }
  }, [selectedToken, unlockTreasure]);

  useEffect(() => {
    const unlockTreasure = PubSub.subscribe(PubSubEvents.CHESTS_OPENED.event, (_, {keyIds, successes}) => {
      if (!bulkOpenInProgress) {
        callbackOpenTreasure(successes[0]);
        handleTransactionLoadingModal.close();
      } else {
        setTreasureBoxResults(results => {
          for (let i = 0; i < keyIds.length; i++) {
            const keyId = keyIds[i];
            const success = successes[i];
            const token = results.find(token => token.tokenId === keyId);
            if (token) {
              token.result = success ? 'success' : 'fail';
            }
          }
          return results;
        })
      }
    });

    const prizeSeason = PubSub.subscribe(PubSubEvents.PRIZE_RESULT.event, (_, args) => {
      setPrizeSeasonResults(results => [...results, ...args.prizes]);
    });

    return () => {
      PubSub.unsubscribe(unlockTreasure);
      PubSub.unsubscribe(prizeSeason);
    }
  }, [bulkOpenInProgress]);

  const onStakeLP = (value, type) => {
    handleTransactionLoadingModal.open();
    stakeLP(value, type)
      .finally(() => handleTransactionLoadingModal.close());
  };
  const onUnstakeLP = (value, type) => {
    handleTransactionLoadingModal.open();
    unstakeLP(value, type)
      .finally(() => handleTransactionLoadingModal.close());
  };
  const onclaimVeTresr = () => {
    handleTransactionLoadingModal.open();
    claimVeTresr()
      .finally(() => handleTransactionLoadingModal.close());
  };
  const onClaimBase = () => {
    handleTransactionLoadingModal.open();
    claimBase()
      .finally(() => handleTransactionLoadingModal.close());
  };
  const onClaimAllBase = () => {
    handleTransactionLoadingModal.open();
    claimAllBase()
      .finally(() => handleTransactionLoadingModal.close());
  };
  const onClaimBonus = () => {
    handleTransactionLoadingModal.open();
    claimBonus()
      .finally(() => handleTransactionLoadingModal.close());
  };
  const onClaimAll = () => {
    handleTransactionLoadingModal.open();
    claimAll()
      .finally(() => handleTransactionLoadingModal.close());
  };

  const onCloseApproveUpgradeModal = () => {
    handleApproveUpgradeModal.close();
    setIsApproved(false);
  };

  const onCloseApproveUpgradeAllModal = () => {
    handleApproveUpgradeAllModal.close();
    setIsApproved(false);
  };

  const onCloseTransactionLoadingModal = () => {
    handleTransactionLoadingModal.close();
    setIsAnimated(false);
  }

  const onTryAgainOpenChest = () => {
    handleTreasureModal.open();
    handleChestModal.close();
    setIsAnimated(false);
  };

  const onView = () => handleExpandedKeyViewModal.open();
  const onViewTokenPage = () =>
    navigate(
      `/marketplace/${process.env.REACT_APP_NFKEY_ADDRESS}/${selectedToken?.tokenId}`
    );
  const onOpenActivateAllKeysModal = () => handleActivateAllKeysModal.open();
  const onOpenUpgradeAllKeysModal = () => {
    if (!upgradeAllKeysFlag) handleUpgradeAllKeysModal.open();
  };
  const onOpenAllTreasureBoxesModal = () => {
    handleOpenAllTreasureBoxesModal.open();
  }

  useEffect(() => {
    if (location.pathname === DASHBOARD_TAB_MENU_GAME_LINK)
      setActiveTab(DASHBOARD_TAB_MENU_GAME);
    else if (location.pathname === DASHBOARD_TAB_MENU_DASHBOARD_LINK)
      setActiveTab(DASHBOARD_TAB_MENU_DASHBOARD);
    else if (location.pathname === DASHBOARD_TAB_MENU_FAQ_LINK)
      setActiveTab(DASHBOARD_TAB_MENU_FAQ);
  }, [location]);

  // Hook that updates the token list in initial load, filter, sort
  useEffect(() => {
    if (!tokenList.length || !setSelectedToken) return;
    const currentSelectedId = selectedToken
      ? selectedToken?.tokenId
      : undefined;

    const ft = tokenList
      ?.filter((item) => {
        if (activeFilter?.value === FILTER_ACTIVE) return item?.staked;
        if (activeFilter?.value === FILTER_INACTIVE) return !item?.staked;
        return true;
      })
      ?.sort((a, b) => {
        if (activeDropdownItem?.value === SORT_HIGHEST_KEY_LEVEL)
          return b?.level - a?.level;
        else if (activeDropdownItem?.value === SORT_LOWEST_KEY_LEVEL)
          return a?.level - b?.level;
        else if (activeDropdownItem?.value === SORT_HIGHEST_TOKENID)
          return b?.tokenId - a?.tokenId;
        else if (activeDropdownItem?.value === SORT_HIGHEST_TIER)
          return b?.tierTresr - a?.tierTresr;
        else if (activeDropdownItem?.value === SORT_LOWEST_TIER)
          return a?.tierTresr - b?.tierTresr;
        else return a?.tokenId - b?.tokenId;
      })
      .map((item, index) => ({ ...item, index }));
    setFilterTokenList(ft);
    if (ft.length !== 0) {
      let foundSelectedToken = ft?.find(
        (item) => item?.tokenId === currentSelectedId
      );

      if (!foundSelectedToken) {
        setSelectedToken(ft[0]);
      }
      setIsNotActiveToken(ft.some((item) => !item?.staked));
    }
    
    setIsMaxUpgrades(
      ft?.some((item, key) =>
        item.staked && compareUnixDates(new Date().getTime(), item.upgradeToDelay)
      )
    );

    setShowBulkBoxOpen(ft?.some((item, key) =>
      item.staked
    ));
  }, [tokenList, activeDropdownItem, activeFilter, selectedToken]);

  if (loader.isActive) return null;

  return (
    <>
      <section className="dashboard">
        <>
          <div className="dashboard__data">
            {activeTab === DASHBOARD_TAB_MENU_GAME && (
              <div
                className={`dashboard__data__content ${
                  !selectedToken ? "dashboard__data__content--noToken" : ""
                }`}
              >
                <div className="dashboard__data__content--leftBlock">
                  {isMobile &&  tokenList.length > 0 && (
                    <div className="flex-col flex gap-2">
                      <FAQsItem
                        answer={
                          <div className="flex flex-col">
                            <span className="navbar__dashboard--burnBlock__smrtr">
                              {formatterUS(balances.burnedSmrtr)} $SMRTR
                            </span>
                            <span className="navbar__dashboard--burnBlock__tresr">
                              {formatterUS(balances.burnedTresr)} $TRESR
                            </span>
                          </div>
                        }
                        question={"🔥 Burn Count"}
                      />
                      <FAQsItem
                        answer={
                          <>
                            <div className="navbar__dashboard--poolBlock__text">
                              <span>Key Rewards Pool:</span>
                              <span>
                                {formatterUS(balances.poolAllocation)}
                              </span>
                            </div>
                            <div className="navbar__dashboard--poolBlock__text">
                              <span>Bonus Pool:</span>
                              <span>
                                {formatterUS(balances.bonusPoolAllocation)}
                              </span>
                            </div>
                          </>
                        }
                        question={"💰 Game Emission Remaining"}
                      />
                      <FAQsItem
                        answer={
                          prizeSeasonBoosts?.map((boost, index) => (<div key={boost.name} style={{marginBottom: index !== prizeSeasonBoosts.length - 1 ? "24px" : undefined}}>
                              <div className="navbar__dashboard--poolBlock__text" style={{ marginBottom: '8px'}}>
                                <span>{boost.name.toUpperCase()}</span>
                              </div>
                              {
                                boost.boosts.map((b) => (
                                  <div key={b.name} className="navbar__dashboard--poolBlock__text" style={{ marginBottom: '8px'}}>
                                    <span>{b.name} (up to {b.weight}%)</span>
                                    <span>{formatterUS(b.perc/100 * b.weight)}%</span>
                                  </div>
                                ))
                              }
                              <div className="navbar__dashboard--poolBlock__text" style={{ marginBottom: '8px', borderTop: "2px solid #FEC90C"}}>
                                <em> <span>Total Boost</span></em>
                                <em><span>{formatterUS(boost.boosts.reduce((tot, b) => (b.perc/100 * b.weight) + tot, 0))}%</span></em>
                              </div>
                            </div>)
                          )
                        }
                        question={"🎉 Prize Season Boosts"}
                      />
                    </div>
                  )}
                  {isMobile &&
                    !!(selectedToken?.tokenId) && (
                      <DashboardKeyRewards
                        onClaimBase={onClaimBase}
                        onClaimAllBase={onClaimAllBase}
                        isAnimated={isAnimated}
                        isDark={true}
                        isMobile={isMobile}
                      />
                    )}
                  {
                    isMobile && tokenList.length === 0 && (
                      <DashboardNotWhitelisted showStaking={!restrictedStaking} />
                    )
                  }

                  {selectedToken && (
                    <div
                      className={`dashboard__data__content--list ${
                        isAnimated && "nft__image--placeholder"
                      }`}
                    >
                      <DashboardFilters
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                        handleTransactionLoadingModal={
                          handleTransactionLoadingModal
                        }
                      />
                      <Dropdown
                        activeItem={activeDropdownItem}
                        onSelect={setActiveDropdownItem}
                      />
                      <div className="dashboard__data__content--list--item-wrapper">
                        <DashboardTokenList
                          isAnimated={isAnimated}
                          onOpenModal={handleDashboardMobileModal.open}
                          filterTokenList={filterTokenList}
                        />
                      </div>

                      {(isNotActiveToken || isMaxUpgrades || showBulkBoxOpen) && (
                        <div
                          className="dashboard__data__content--list__buttons"
                          style={
                            !isMobile
                              ? {
                                  bottom:
                                    filterTokenList?.length >= 6
                                      ? 0
                                      : `${
                                          (6 - filterTokenList?.length) * 86
                                        }px`,
                                }
                              : {
                                  bottom:
                                    filterTokenList?.length >= 4
                                      ? 0
                                      : `${
                                          (4 - filterTokenList?.length) * 86
                                        }px`,
                                }
                          }
                        >
                          {isNotActiveToken && (
                            <div style={{flexBasis: "40%"}}>
                            <MarketplaceButton
                              title="Activate All Keys"
                              isBlue
                              onClick={onOpenActivateAllKeysModal}
                            />
                            </div>
                          )}
                          { isMaxUpgrades && (
                            <div style={{flexBasis: "40%"}}>

                            <MarketplaceButton
                              title="Max Upgrade Keys"
                              isBlue
                              onClick={onOpenUpgradeAllKeysModal}
                            />
                            </div>
                          )}
                          {showBulkBoxOpen &&
                            <div style={{flexBasis: "100%", maxWidth: "85%"}}>
                              <MarketplaceButton
                                title={"Bulk Treasure Box Open"}
                                isGolden
                                onClick={onOpenAllTreasureBoxesModal}
                                fullWidth={true}
                              />
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {!isMobile && (
                  <div
                    className={`dashboard__data__content--form ${
                      !selectedToken
                        ? "dashboard__data__content--form__noToken"
                        : ""
                    }`}
                  >
                    <DashboardGameTab
                      isAnimated={isAnimated}
                      probToOpen={selectedToken?.openChestProbability}
                      amountUpgradeKey={selectedToken?.keyUpgradeAmount}
                      handleUpgradeModal={handleUpgradeModal}
                      onView={onView}
                      handleTreasureModal={handleTreasureModal}
                      handleTreasureWarningModal={handleTreasureWarningModal}
                      amountOpenChest={selectedToken?.unlockChestAmount}
                      onOpenDeactivateKeyModal={handleDeactivateKeyModal.open}
                      onOpenActivateKeyModal={handleActivateKeyModal.open}
                      onClaimBonus={onClaimBonus}
                      onClaimBase={onClaimBase}
                      onClaimAllBase={onClaimAllBase}
                      onStakeLP={onStakeLP}
                      onUnstakeLP={onUnstakeLP}
                      onViewTokenPage={onViewTokenPage}
                      setIsMaxUpgrades={setIsMaxUpgrades}
                      filterTokenList={filterTokenList}
                      handleTransactionLoadingModal={
                        handleTransactionLoadingModal
                      }
                    />
                  </div>
                )}
              </div>
            )}

            {activeTab === DASHBOARD_TAB_MENU_DASHBOARD && (!restrictedStaking || tokenList.length > 0) && (
              <DashboardTab
                onStakeTresr={onStakeTresr}
                onUnstakeTresr={onUnstakeTresr}
                onClaimBonus={onClaimBonus}
                onClaimAll={onClaimAll}
                onStakeLP={onStakeLP}
                onUnstakeLP={onUnstakeLP}
                onclaimVeTresr={onclaimVeTresr}
              />
            )}
            {
              activeTab === DASHBOARD_TAB_MENU_DASHBOARD &&
              restrictedStaking &&
              tokenList.length === 0 && (
                <DashboardNotWhitelisted showStaking={!restrictedStaking} />
              )
            }
          </div>
        </>

        {handleSuccessfulUpgradeModal.isActive && (
          <SuccessKeyUpgradeModal
            isOpen={handleSuccessfulUpgradeModal.isActive}
            onClose={handleSuccessfulUpgradeModal.close}
          />
        )}

        {handleUpgradeModal.isActive && (
          <UpgradeKeyModal
            isOpen={handleUpgradeModal.isActive}
            onClose={handleUpgradeModal.close}
            selectedToken={selectedToken}
            amountUpgradeKey={selectedToken?.keyUpgradeAmount}
            onApproveUpgradeKey={onApproveUpgradeKey}
          />
        )}
        {handleTreasureModal.isActive && (
          <OpenTreasureBoxModal
            onClose={handleTreasureModal.close}
            isOpen={handleTreasureModal.isActive}
            selectedToken={selectedToken}
            amountOpenChest={selectedToken?.unlockChestAmount}
            onOpenTreasure={onOpenTreasure}
            balances={balances}
            probToOpen={selectedToken?.openChestProbability}
            avaxFee={selectedToken?.openChestAvaxFee}
          />
        )}

        {handleTreasureWarningModal.isActive && (
          <TreasureWarningModal
            isOpen={handleTreasureWarningModal.isActive}
            onClose={handleTreasureWarningModal.close}
          />
        )}
        {handleDeactivateKeyModal.isActive && (
          <DeactivateKeyModal
            isOpen={handleDeactivateKeyModal.isActive}
            onClose={handleDeactivateKeyModal.close}
            onUnstake={onUnstake}
          />
        )}

        {handleActivateKeyModal.isActive && (
          <ActivateKeyModal
            onClose={handleActivateKeyModal.close}
            isOpen={handleActivateKeyModal.isActive}
            onStake={onStake}
          />
        )}
        {handleChestModal.isActive && (
          <TreasureBoxModal
            amountOpenChest={selectedToken?.unlockChestAmount}
            isOpen={handleChestModal.isActive}
            onClose={handleChestModal.close}
            onTryAgain={onTryAgainOpenChest}
            chestStatus={chestStatus}
            setIsAnimated={setIsAnimated}
            prize={prizeSeasonResults?.find(prize => prize.tokenId === selectedToken?.tokenId)}
          />
        )}

        {handleExpandedKeyViewModal.isActive && (
          <ExpandedNFTView
            onClose={handleExpandedKeyViewModal.close}
            isOpen={handleExpandedKeyViewModal.isActive}
            currentToken={selectedToken}
            isGame
          />
        )}

        {handleActivateAllKeysModal.isActive && (
          <ActivateAllKeysModal
            onClose={handleActivateAllKeysModal.close}
            onClick={onBulkStake}
            isOpen={true}
            filterTokenList={filterTokenList}
          />
        )}

        {handleUpgradeAllKeysModal.isActive && (
          <UpgradeAllKeysModal
            onClick={onBulkApproveKey}
            setTokenListToUpgrade={setTokenListToUpgrade}
            onClose={handleUpgradeAllKeysModal.close}
            isOpen={handleUpgradeAllKeysModal.isActive}
            handleTransactionLoadingModal={handleTransactionLoadingModal}
            setUpgradeAllKeysFlag={setUpgradeAllKeysFlag}
            upgradeAllKeysFlag={upgradeAllKeysFlag}
            filterTokenList={filterTokenList}
          />
        )}

        {
          handleOpenAllTreasureBoxesModal.isActive && (
            <OpenAllTreasureBoxesModal
              isOpen={handleOpenAllTreasureBoxesModal.isActive}
              onClose={handleOpenAllTreasureBoxesModal.close}
              filterTokenList={filterTokenList}
              onClick={onBulkOpenChestsApprove}
              setTokenListToUpgrade={setTokenListToUpgrade}
            />
          )
        }

        {
          handleOpenAllTreasureBoxesResultsModal.isActive && (
            <OpenAllTreasureBoxesResultModal
              isOpen={handleOpenAllTreasureBoxesResultsModal.isActive}
              onClose={onCloseAllTreasureBoxesResults}
              tokenResultList={treasureBoxResults}
              tokenList={tokenList}
              prizeSeasonResults={prizeSeasonResults}
            />
          )
        }

        {handleApproveUpgradeModal.isActive && (
          <ApproveLoadingModal
            isOpen={handleApproveUpgradeModal.isActive}
            statusText={APPROVE_STATUS_TEXT.APPROVE_UPGRADE_KEY}
            isApproved={isApproved}
            onClose={onCloseApproveUpgradeModal}
            onNextStep={onUpgradeKey}
          />
        )}
        {handleApproveUpgradeAllModal.isActive && (
          <ApproveLoadingModal
            isOpen={handleApproveUpgradeAllModal.isActive}
            statusText={bulkMode === 'keys' ? APPROVE_STATUS_TEXT.APPROVE_UPGRADE_ALL_KEYS : APPROVE_STATUS_TEXT.APPROVE_OPEN_ALL_CHESTS}
            isApproved={isApproved}
            onClose={onCloseApproveUpgradeAllModal}
            onNextStep={ bulkMode === 'keys' ? onBulkUpgradeKey : onBulkOpenChests}
          />
        )}
        {handleTransactionLoadingModal.isActive && (
          <TransactionLoadingModal
            isOpen={handleTransactionLoadingModal.isActive}
            onClose={onCloseTransactionLoadingModal}
          />
        )}

        {handleDashboardMobileModal.isActive &&
          isMobile &&
          activeTab === DASHBOARD_TAB_MENU_GAME && (
            <DashboardMobileModal
              isOpen={handleDashboardMobileModal.isActive}
              onClose={handleDashboardMobileModal.close}
              activeTab={activeTab}
              amountOpenChest={selectedToken?.unlockChestAmount}
              onClaimAll={onClaimAll}
              onClaimBase={onClaimBase}
              onClaimBonus={onClaimBonus}
              onStakeTresr={onStakeTresr}
              onUnstakeTresr={onUnstakeTresr}
              amountUpgradeKey={selectedToken?.keyUpgradeAmount}
              handleUpgradeModal={handleUpgradeModal}
              handleTreasureModal={handleTreasureModal}
              handleTreasureWarningModal={handleTreasureWarningModal}
              onView={onView}
              onOpenDeactivateKeyModal={handleDeactivateKeyModal.open}
              onOpenActivateKeyModal={handleActivateKeyModal.open}
              probToOpen={selectedToken?.openChestProbability}
              onStakeLP={onStakeLP}
              onUnstakeLP={onUnstakeLP}
            />
          )}
      </section>
      <NFTFooter />
    </>
  );
}
