import React, { useContext } from "react";
import "./style.scss";
import MarketplaceButton from "../../common/MarketplaceButton";
import { Context } from "../../../store";
import { formatterUS } from "../../../utils";
import Tooltip from "components/common/Tooltip";
import {useTokenContext} from "../../../contexts/tokenContext";
import {useAppContext} from "../../../contexts/appContext";
import {useRewardsContext} from "../../../contexts/rewardsContext";
import {useStakingContext} from "../../../contexts/stakingContext";

const DashboardKeyRewards = ({
  onClaimBase,
  onClaimAllBase,
  isAnimated,
  isDark,
  isMobile,
}) => {
  // console.log("[DashboardKeyRewards/index.js] Component Rendering...");

  const [{ loader }] = useContext(Context);
  const { selectedToken } = useTokenContext();
  const { rewardsBalances } = useRewardsContext();
  const { stakingBalances } = useStakingContext();
  const balanceMonthlyTresr = selectedToken?.rewardsPerSecond * 24 * 3600 * 30;
  const totalMonthlyBaseReward = stakingBalances.balanceTotalTresrRewardsPerSec * 24 * 3600 * 30;

  const tokenID = formatterUS(selectedToken?.tokenId, 0, 'decimal', 'standard');

  const onClickClaimBaseRewards = () => onClaimBase();

  const onClickClaimAllBaseRewards = () => onClaimAllBase();

  return (
    <div
      className={`dashboardKeyRewards ${
        isAnimated
          ? "nft__image--placeholder dashboardKeyRewards__isAnimated"
          : ""
      }`}
    >
      {!isAnimated && (
        <>
          <div className="dashboardKeyRewards__rewards">
            <Tooltip tooltipText={"Est for All Keys"} top={-30}>
              <div className="dashboard__data__content--form__selectedToken--info__tresrPerDay bg-transparent">
                {formatterUS(totalMonthlyBaseReward)} TRESR/MONTH
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {/* {loader?.componentActive && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )} */}
                </div>
              </div>
            </Tooltip>
            <div className="dashboardKeyRewards__title">Total Key Rewards</div>
            <div className="dashboardKeyRewards__amount">
              {/* <ClipLoader
                loading={loader?.componentActive}
                size={24}
                color={isDark ? "#fff" : "#4964fe80"}
                cssOverride={{ marginLeft: "10px" }}
              /> */}
              {!loader?.componentActive && (
                <>{rewardsBalances.balanceUnclaimedBase > 1 ? formatterUS(rewardsBalances.balanceUnclaimedBase) : rewardsBalances.balanceUnclaimedBase.toFixed(5)}</>
              )}
            </div>
            <div className="dashboardKeyRewards__text">
              Available $TRESR to claim
            </div>
            <div className="dashboardKeyRewards__button">
              <MarketplaceButton
                title="Claim All Key Rewards"
                isNormal
                onClick={onClickClaimAllBaseRewards}
              />
            </div>
          </div>

          {!isMobile && (
            <div className="dashboardKeyRewards__rewards">
              <Tooltip
                tooltipText={`Est. for Key #${selectedToken?.tokenId}`}
                top={-30}
              >
                <div className="dashboard__data__content--form__selectedToken--info__tresrPerDay bg-transparent">
                  {formatterUS(balanceMonthlyTresr)} TRESR/MONTH
                  <div className="w-[23px] ml-[5px] -mr-[35px]">
                    {/* {loader?.componentActive && (
                      <ClipLoader
                        size={16}
                        color={isDark ? "#fff" : "#4964fe80"}
                      />
                    )} */}
                  </div>
                </div>
              </Tooltip>
              <div className="dashboardKeyRewards__title">
                Key #{selectedToken?.tokenId ? tokenID : "0"} Rewards
              </div>
              <div className="dashboardKeyRewards__amount">
                {/* <ClipLoader
                  loading={loader?.componentActive}
                  size={24}
                  color={isDark ? "#fff" : "#4964fe80"}
                  cssOverride={{ marginLeft: "10px" }}
                /> */}

                {!loader?.componentActive && (
                  <>
                    {selectedToken?.keyBaseReward ? selectedToken.keyBaseReward.toFixed(5) : 0}
                  </>
                )}
              </div>
              <div className="dashboardKeyRewards__text">
                Available $TRESR to claim
              </div>
              <div className="dashboardKeyRewards__button">
                <MarketplaceButton
                  title="Claim Key Rewards"
                  isNormal
                  onClick={onClickClaimBaseRewards}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DashboardKeyRewards;
