import React, {useEffect, useMemo, useState} from "react";
import "./style.scss";
import ModalLayout from "../index";
import MarketplaceButton from "../../MarketplaceButton";
import { formatterUS } from "../../../../utils";
import useWindowDimensions from "hooks/useWidowDimensions";
import {useAppContext} from "../../../../contexts/appContext";
import TreasureBoxItem from "./TreasureBoxItem";
import {useTokenContext} from "../../../../contexts/tokenContext";
const OpenAllTreasureBoxesModal = ({
                               isOpen,
                               onClose,
                               filterTokenList,
                               onClick,
                               setTokenListToUpgrade,
                             }) => {
  const { balances } = useAppContext();
  const { getBulkUnlockFee } = useTokenContext();
  const [tokenList, setTokenList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 750;
  const [avaxChestFee, setAvaxChestFee] = useState(0);

  const isInsufficient = +totalAmount > +balances.balanceTresr;


  const onDeleteItem = async (id) => {
    setTokenList(
      tokenList?.map((item, key) => {
        if (item.tokenId === id) item.flag = false;
        return item;
      }).sort((a, b) => a.tierTresr < b.tierTresr ? -1 : 1)
    );
    const total = tokenList.filter(item => item.flag).reduce((toRet, item) => toRet + item.unlockChestAmount, 0);
    setTotalAmount(total);

    const newChestFee = await getBulkUnlockFee(tokenList.filter(item => item.flag));
    setAvaxChestFee(newChestFee);
  };

  const onDeleteItems = async (index) => {
    setTokenList(list => {
      for (let i = index; i < list.length; i++) {
        list[i].flag = false
      }
      return list;
    });
    const total = tokenList.filter(item => item.flag).reduce((toRet, item) => toRet + item.unlockChestAmount, 0);
    setTotalAmount(total);

    const newChestFee = await getBulkUnlockFee(tokenList.filter(item => item.flag));
    setAvaxChestFee(newChestFee);
  }

  const onAddItem = async (id) => {
    setTokenList(
      tokenList?.map((item, key) => {
        if (item.tokenId === id) item.flag = true;
        return item;
      }).sort((a, b) => a.tierTresr < b.tierTresr ? -1 : 1)
    );
    const total = tokenList.filter(item => item.flag).reduce((toRet, item) => toRet + item.unlockChestAmount, 0);
    setTotalAmount(total);

    const newChestFee = await getBulkUnlockFee(tokenList.filter(item => item.flag));
    setAvaxChestFee(newChestFee);
  };

  const onUpgrade = () => {
    if (tokenList?.length) {
      setTokenListToUpgrade(
        tokenList
          ?.filter((item) => item?.flag === true && item.staked)
          ?.map((item) => +item?.tokenId)
      );
      onClick(totalAmount);
    }
  };

  useEffect(() => {
    if (isOpen && filterTokenList?.length) {
      setTokenList(
        filterTokenList
          ?.filter((item) => item.staked)
          ?.map((tokenItem) => {
            return { ...tokenItem, flag: true };
          }).sort((a, b) => a.tierTresr < b.tierTresr ? -1 : 1)
      );
      setTotalAmount(filterTokenList.filter(item => item.staked).reduce((toRet, item) => toRet + item.unlockChestAmount, 0));

      const setFee = async () => {
        const newChestFee = await getBulkUnlockFee(filterTokenList.filter(item => item.staked));
        setAvaxChestFee(newChestFee);
      };
      setFee();
    }
  }, [filterTokenList, isOpen, getBulkUnlockFee]); // eslint-disable-line react-hooks/exhaustive-deps

  const buttonTitle = isInsufficient
    ? "Insufficient TRESR"
    : avaxChestFee > balances.balanceAvax ? "Insufficient AVAX"
      : `Open ${
        tokenList?.filter((item) => item?.flag === true)?.length || 0
      } Treasure Boxes`;

  return (
    <ModalLayout
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={"608px"}
      maxHeight={"582px"}
      padding={"32px"}
      withCrossIcon
    >
      <p className="modal__title">Open Multiple Treasure Boxes</p>

      {!isMobile && (
        <div className="modal__text mt-4 text-center">
          Upgrading your Treasure Tiers helps you earn more $TRESR.
        </div>
      )}
      {!isMobile && (
        <div className="modal__text text-right text-[16px] mr-[20px]">
          {tokenList?.filter((item) => item?.flag)?.length} /{" "}
          {tokenList?.length}
        </div>
      )}
      <div className={`upgradeAllKeysModal__list`}>
        {tokenList?.map((item, index) => {
          if (item.flag) {
            return (
              <TreasureBoxItem
                key={item?.tokenId}
                token={item}
                onDeleteItem={onDeleteItem}
                onAddItem={onAddItem}
                index={index}
                onDeleteItems={onDeleteItems}
              />
            );
          }
        })}
        {tokenList?.map((item, index) => {
          if (!item.flag)
            return (
              <TreasureBoxItem
                key={item?.tokenId}
                token={item}
                onDeleteItem={onDeleteItem}
                onAddItem={onAddItem}
                index={index}
                onDeleteItems={onDeleteItems}
              />
            );
        })}
      </div>

      <div className="upgradeAllKeysModal__total">
        <span className="upgradeAllKeysModal__total--text">
          You need to Burn
        </span>
        <div className="upgradeAllKeysModal__total--price">
          {formatterUS(totalAmount)} TRESR
        </div>
      </div>
      <div className="upgradeAllKeysModal__total">
        <span className="upgradeAllKeysModal__total--text">
          Gelato VRF
        </span>
        <div className="upgradeAllKeysModal__total--price">
          {formatterUS(avaxChestFee, 4)} AVAX
        </div>
      </div>

      <div className="upgradeAllKeysModal__buttons flex-grow justify-end items-end">
        <MarketplaceButton title="Cancel" onClick={onClose} />
        <MarketplaceButton
          disabled={isInsufficient || avaxChestFee > balances.balanceAvax}
          title={buttonTitle}
          onClick={onUpgrade}
          isBlue
        />
      </div>
    </ModalLayout>
  );
};

export default OpenAllTreasureBoxesModal;
