import React from "react";
import "./style.scss";
import MarketplaceButton from "../../MarketplaceButton";
import ModalLayout from "../index";

const MintConfirmationModal = ({ isOpen, onClose, onMint }) => {
  return (
    <ModalLayout
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={"458px"}
      maxHeight={"280px"}
      padding={"40px"}
      withCrossIcon
    >
      <p className={"modal__title"}>Minting</p>

      <div className="modal__text mt-[32px] max-md:mt-[20px]">
        If you are minting multiple keys, you will be asked to confirm a
        transaction for each key being minted.
      </div>

      <div className="mintCModal__buttons max-md:mt-[20px] max-md:justify-end max-md:items-end max-md:h-[370px]">
        <MarketplaceButton title={"Cancel"} isWhite onClick={onClose} />
        <MarketplaceButton title={"Continue"} isBlue onClick={onMint} />
      </div>
    </ModalLayout>
  );
};

export default MintConfirmationModal;
