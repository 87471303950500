import React, { useState, useEffect } from "react";
import ModalLayout from "../index";
import MarketplaceButton from "../../MarketplaceButton";
import { useNavigate } from "react-router-dom";
import customSlider_left from "assets/images/customSlider-left.svg";
import customSlider_right from "assets/images/customSlider-right.svg";
import "./style.scss";
import Slider from "react-slick";
import {useTokenContext} from "../../../../contexts/tokenContext";

const SuccessMintModal = ({
  isOpen,
  onClose,
  tokenIdList,
}) => {
  const { tokenList } = useTokenContext();
  const [activeSlide, setActiveSlide] = useState(0);
  const [modalTokenList, setModalTokenList] = useState([]);
  const [sliderRef, setSliderRef] = useState(null);
  const navigate = useNavigate();

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: true,
    afterChange: (current) => setActiveSlide(current ?? 0),
  };

  const handleCloseModal = () => {
    setModalTokenList([]);
    setActiveSlide(0);
    onClose();
  };

  const onGoToGame = () => {
    handleCloseModal();
    navigate("/game");
  };

  useEffect(() => {
    setModalTokenList(
      tokenList.filter((item) => tokenIdList.includes(item?.tokenId))
    );
  }, [tokenIdList, tokenList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (modalTokenList?.length) {
      const blockOldPrev = document.querySelector(`.slick-slide.prev`);
      if (blockOldPrev) blockOldPrev?.classList?.remove("prev");

      const blockOldNext = document.querySelector(`.slick-slide.next`);
      if (blockOldNext) blockOldNext?.classList?.remove("next");

      setTimeout(() => {
        const blockPrev = document.querySelector(
          `[data-index='${activeSlide - 1}']`
        );
        if (blockPrev) blockPrev?.classList?.add("prev");
      }, 0);

      const blockNext = document.querySelector(
        `[data-index='${activeSlide + 1}']`
      );
      if (blockNext) blockNext?.classList?.add("next");
    }
  }, [modalTokenList, activeSlide]); // eslint-disable-line react-hooks/exhaustive-deps

  // if (enoughFlag) {
  return (
    //FIXME: is this correct to set maxwidth like this?
    <ModalLayout
      maxWidth={"458px"}
      maxHeight={"638px"}
      padding={"0"}
      isOpen={isOpen}
      onClose={handleCloseModal}
      withCrossIcon
      isFullModalBg
    >
      <p className="modal__title successMintModal__title">
        Welcome to the Club!
      </p>
      <p className="modal__text successMintModal__text">
        You have successfully minted{" "}
        {modalTokenList?.length === 1
          ? `Treasure Key #${modalTokenList[0]?.tokenId}`
          : `${modalTokenList?.length?.toString()} keys`}
      </p>

      <div className="successMintModal__slider">
        {modalTokenList?.length === 1 ? (
          <div className="successMintModal__singleItem">
            <img src={modalTokenList[0]?.image} alt={""} />
            <div className="text-white py-2">
              Treasure Key #{modalTokenList[0]?.tokenId}
            </div>
          </div>
        ) : (
          <Slider {...settings} ref={setSliderRef}>
            {modalTokenList?.map((item, key) => (
              <div className="successMintModal__slider--item" key={key}>
                <img src={item?.image} alt="" />
                <p>NFT#{item?.tokenId}</p>
              </div>
            ))}
          </Slider>
        )}
      </div>

      <div className="text-[#fff] px-[30px] text-center pt-[32px]">
        Learn how to upgrade your key, open treasure boxes, and earn TRESR
        tokens by continuing to the game dashboard.
      </div>
      {modalTokenList.length > 1 && (
        <div className="absolute left-[30px] top-[260px] z-30 cursor-pointer">
          <img
            src={customSlider_left}
            alt="NFTDetail-left"
            onClick={sliderRef?.slickPrev}
          />
        </div>
      )}
      {modalTokenList.length > 1 && (
        <div className="absolute right-[30px] top-[260px] z-30 cursor-pointer">
          <img
            src={customSlider_right}
            alt="NFTDetail-right"
            onClick={sliderRef?.slickNext}
          />
        </div>
      )}
      <div className="flex mt-5 gap-[11px] justify-center">
        <MarketplaceButton
          title={"Stay on Mint page"}
          onClick={handleCloseModal}
        />
        <MarketplaceButton
          title={"Go to the Game"}
          isBlue
          onClick={onGoToGame}
        />
      </div>
    </ModalLayout>
  );
  // }
  // else {
  //   return (
  //     <ModalLayout
  //       maxWidth={"458px"}
  //       maxHeight={"257px"}
  //       isOpen={isOpen}
  //       onClose={handleCloseModal}
  //       withCrossIcon
  //       isFullModalBg
  //     >
  //       <div className="p-[32px] text-white">
  //         <div className="text-[#F44550] text-[16px] text-center">
  //           You do not have enough AVAX.
  //         </div>
  //         <div className="text-[16px] pt-[32px]">
  //           You need to lower the number of Keys you are minting, or replenish
  //           your AVAX supply.
  //         </div>
  //         <div className="py-4">
  //           <hr className="bg-[#fbfbfb22] border-0 h-0.5" />
  //         </div>
  //         <div className="flex justify-between">
  //           <div>You need to pay</div>
  //           <div className="flex gap-2">
  //             <div className="text-[#fbfbfb77]">
  //               ${(AVAXPrice * sumCommission).toFixed(2)}
  //             </div>
  //             <div className="text-[#BBC5FF] font-medium">
  //               {sumCommission} AVAX
  //             </div>
  //           </div>
  //         </div>
  //         <div className="flex justify-between">
  //           <div>Balance:</div>
  //           <div className="flex gap-2">
  //             <div className="text-[#fbfbfb77]">
  //               ${(AVAXPrice * curAVAX).toFixed(2)}
  //             </div>
  //             <div className="text-[#BBC5FF] font-medium">{curAVAX} AVAX</div>
  //           </div>
  //         </div>
  //       </div>
  //     </ModalLayout>
  //   );
  // }
};

export default SuccessMintModal;
