import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./style.scss";
import ModalLayout from "../index";
import MarketplaceButton from "../../MarketplaceButton";
import useWindowDimensions from "hooks/useWidowDimensions";
import TreasureBoxResultItem from "./TreasureBoxResultItem";
import Slider from "react-slick";
import customSlider_left from "../../../../assets/images/customSlider-left.svg";
import customSlider_right from "../../../../assets/images/customSlider-right.svg";
import Lottie from "lottie-react";
import celebration from "../../DashboardModals/TreasureBoxModal/celebration.json";
import {useTokenContext} from "../../../../contexts/tokenContext";
import axios from "axios";

const PrizesList = ({ prizesWithInfo, setShowPrizes, onClose }) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const { prizeSeasonRoot } = useTokenContext();
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    focusOnSelect: true,
    arrows: false,
    infinite: false,
    afterChange: (current) => setActiveSlide(current ?? 0),
  };
  const goBack = useCallback(() => {
    setShowPrizes(false);
  }, [setShowPrizes]);

  const closeCallback = useCallback(() => {
    onClose();
  }, [onClose]);

  const wonPrizes = useMemo(() => {
    return prizesWithInfo.filter(result => result.won);
  }, [prizesWithInfo]);

  return (
    <>
      {wonPrizes.length > 0 &&
        <div className="prizes__slider">
          <Lottie className={"prizes__celebration"} animationData={celebration} loop={true} />

          <Slider {...settings} ref={setSliderRef}>
            {wonPrizes?.map((item) => (
              <div
                className={`prizes__slider--item`}
                key={item.tokenId}
              >
                <p className="modal__title prizes__slider-title">You've Won! Key Id: {item.tokenId}</p>
                <img className={'prizes__prize'} src={item.image} />
                <p className="modal__text prizes__slider-description">{item.info?.description}</p>
              </div>
            ))}
          </Slider>
        </div>
      }
      {
        wonPrizes.length === 0 && (
          <div
            style={{ height: "auto"}}
            className={`prizes__success prizes__visible`}
          >
            <p className="modal__title prizes__slider-title">No extra prizes this time.</p>
            <img className={"prizes__prize"} src={`${prizeSeasonRoot}images/0.svg`} />
            <p className="modal__text prizes__slider-description">But feel free to take a deep breath.</p>
          </div>
        )
      }
      <div className="prizes__buttons prizes__buttons--visible">
        <MarketplaceButton
          title={"Back to Results"}
          onClick={goBack}
        />
        <MarketplaceButton
          title={"Close"}
          isBlue
          onClick={closeCallback}
        />
      </div>
      {wonPrizes.length > 1 && activeSlide !== 0 && (
        <div className="absolute left-[30px] top-[180px] z-30 cursor-pointer">
          <img
            src={customSlider_left}
            alt="NFTDetail-left"
            onClick={sliderRef?.slickPrev}
          />
        </div>
      )}
      {wonPrizes.length > 1 && activeSlide !== wonPrizes.length - 1 && (
        <div className="absolute right-[30px] top-[180px] z-30 cursor-pointer">
          <img
            src={customSlider_right}
            alt="NFTDetail-right"
            onClick={sliderRef?.slickNext}
          />
        </div>
      )}
    </>
  );
}

const OpenAllTreasureBoxesResultModal = ({
                                           isOpen,
                                           onClose,
                                           tokenResultList,
                                           tokenList,
                                           prizeSeasonResults
                                         }) => {
  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 750;
  const haveAllResults = tokenResultList.every(token => token.result !== 'pending');
  const fullTokenList = useMemo(() => {
    return tokenResultList.map(resultItem => ({
      ...tokenList.find(token => token.tokenId === resultItem.tokenId),
      result: resultItem.result
    }))
  }, [tokenResultList, tokenList]);
  const [showPrizes, setShowPrizes] = useState(false);
  const [prizesShown, setPrizesShown] = useState(false);
  const { prizeSeasonRoot } = useTokenContext();
  const [prizesWithInfo, setPrizesWithInfo] = useState([]);
  const hasPrizeResults = prizesWithInfo.length > 0;

  useEffect(() => {
    if (!prizeSeasonResults) {
      return;
    }
    const fillResults = async () => {
      const info = await Promise.all(prizeSeasonResults.map(async (prize) => {
        if (prize?.won) {
          prize.image = `${prizeSeasonRoot}images/${prize.prizeId}.svg`;
          const {data} = await axios.get(`${prizeSeasonRoot}info/${prize.prizeId}.json`);
          prize.info = data;
        } else {
          prize.image = `${prizeSeasonRoot}images/0.svg`;
        }
        return prize;
      }));
      setPrizesWithInfo(info);
    };
    fillResults();
  }, [prizeSeasonResults, prizeSeasonRoot]);

  const showPrizesCallback = useCallback(() => {
    setPrizesShown(true);
    setShowPrizes(true);
  }, []);

  return (
    <ModalLayout
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={"608px"}
      maxHeight={"535px"}
      padding={"32px"}
      withCrossIcon
    >
      {!showPrizes &&
        <>
          <p className="modal__title">Treasure Box Results</p>

          {!isMobile && (
            <div className="modal__text mt-4 text-center">
              The VRF has spoken. How lucky did you get?
            </div>
          )}
          <div className={`treasure-box-results__list`}>
            {fullTokenList?.map((item, key) => {
              return (
                <TreasureBoxResultItem
                  key={key}
                  token={item}
                  index={item?.tokenId}
                />
              )
            })}
          </div>
        </>
      }
      {
        showPrizes && <PrizesList
          setShowPrizes={setShowPrizes}
          onClose={onClose}
          prizesWithInfo={prizesWithInfo}
        />
      }


      { !showPrizes &&
        <div className="upgradeAllKeysModal__buttons flex-grow justify-end items-end">
          {(!prizeSeasonRoot || prizesShown) &&
            <MarketplaceButton
              disabled={!haveAllResults}
              title={"Close"}
              onClick={onClose}
              isBlue
              fullWidth={!hasPrizeResults}
            />
          }
          {
            prizeSeasonRoot &&
            <MarketplaceButton
              disabled={!haveAllResults || prizesWithInfo.length === 0}
              title={!haveAllResults ? "Opening Chests" : prizesWithInfo.length  > 0 ? "Show Prizes Won" : 'Calculating Prizes'}
              onClick={showPrizesCallback}
              isGolden={true}
              fullWidth={!prizesShown}
            />
          }
        </div>
      }

    </ModalLayout>
  );
};

export default OpenAllTreasureBoxesResultModal;
