import {types} from "../types";
import {ALERT_STATUS_SUCCESS} from "../../constant/alert";

const actions = {
    [types.SET_ALERT]:
        (dispatch) =>
            (isActive, status = ALERT_STATUS_SUCCESS, text = '', image = '') => {
                dispatch({
                    type: types.SET_ALERT,
                    payload: {isActive, status, text, image},
                });
            },
};

export default actions;
