export const customStylesDark = {
    content: {
        background: 'linear-gradient(180deg, rgba(6, 16, 51, 0.5) 0%, rgba(10, 29, 96, 0.5) 16.2%, rgba(7, 23, 79, 0.5) 40.23%, rgba(8, 20, 67, 0.5) 68.13%, rgba(4, 11, 38, 0.5) 100%)',
        backdropFilter: 'blur(24px)',
        borderRadius: '24px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '40px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        border: '1px solid #93A3F8'
        },
    overlay: {
        background: 'rgba(3, 13, 51, 0.6)',
        backdropFilter: 'blur(2.5px)'
    }
}
