import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import { Context } from "../../../store";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { formatterUS } from "../../../utils";
import crossIcon from "../../../assets/images/cross_keyList_white.svg";
import crossIconBlack from "../../../assets/images/cross_keyList_black.svg";
import useOutsideClick from "../../../hooks/dom/useOutsideClick";
import {useAppContext} from "../../../contexts/appContext";
import useWindowDimensions from "../../../hooks/useWidowDimensions";
import {NAVBAR_ITEMS_GAME_PAGE} from "../../../constant/navigation";
import {useTokenContext} from "../../../contexts/tokenContext";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";



const NavBar = () => {
  const [{ theme }] = useContext(Context);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { balances } = useAppContext();
  const { prizeSeasonBoosts } = useTokenContext();
  const location = useLocation();
  const navigate = useNavigate();
  const isDark = theme === "dark";
  const headerRef = useRef();
  const poolRef = useRef();
  const burnRef = useRef();
  const prizeRef = useRef();
  const tryItOutRef = useRef();
  const contractAddress = location?.pathname?.split("/")[2];

  const [activeLink, setActiveLink] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [headerOffsetTop, setHeaderOffsetTop] = useState(false);
  const [navbarItems, setNavbarItems] = useState([]);
  const [isOpenBurn, setIsOpenBurn] = useState(false);
  const [isOpenPool, setIsOpenPool] = useState(false);
  const [isOpenPrize, setIsOpenPrize] = useState(false);

  const windowParams = useWindowDimensions();
  const isDesktop = windowParams?.width > 1024;
  const isMobile = windowParams?.width <= 450;
  const isGamePage = location?.pathname?.includes("game");

  const setStickyHeader = () => {
    if (!headerRef?.current?.classList) return;

    if (window?.pageYOffset - 2 >= headerOffsetTop)
      headerRef?.current?.classList?.add("sticky");
    else headerRef?.current?.classList?.remove("sticky");
  };

  const onClickTab = (item, key) => {
    setActiveLink(key);
    if (item?.link === "/account") navigate(`${item?.link}/${address}`);
    else if (item?.link === "/account/favourites")
      navigate(`account/${address}/favourites`);
    else if (item?.link === "/account/auctions")
      navigate(`account/${address}/auctions`);
    else if (item?.link === "collections/allItems")
      navigate(`collections/${contractAddress}/allItems`);
    else if (item?.link === "collections/activity")
      navigate(`collections/${contractAddress}/activity`);
    else navigate(item?.link);
  };

  const onCloseBurn = () => {
    setIsOpenBurn(false);
  };

  const onClosePool = () => {
    setIsOpenPool(false);
  };

  const onClosePrize = () => {
    setIsOpenPrize(false);
  }


  const onToggleBurn = () => {
    setIsOpenBurn(!isOpenBurn);
    setIsOpenPool(false);
    setIsOpenPrize(false);
  };

  const onTogglePool = () => {
    setIsOpenPool(!isOpenPool);
    setIsOpenBurn(false);
    setIsOpenPrize(false);
  };

  const onTogglePrize = () => {
    setIsOpenPrize(prize => !prize);
    setIsOpenBurn(false);
    setIsOpenPool(false);
  }

  const setHeaderButton = () => {
    if (isGamePage && isDesktop)
      return (
        <div className="navbar__dashboard">
          <span className="navbar__dashboard--burn" onClick={onToggleBurn}>
            🔥
          </span>
          <div
            className={`navbar__dashboard--burnBlock ${
              isOpenBurn ? "navbar__dashboard--burnBlock__open" : ""
            }`}
            ref={burnRef}
            id={"burnBlock"}
          >
            <div className="navbar__dashboard--burnBlock__title">
              <span>🔥 Burn Count</span>
              <img
                src={isDark ? crossIcon : crossIconBlack}
                alt={"close"}
                onClick={onCloseBurn}
              />
            </div>
            <p className="navbar__dashboard--burnBlock__smrtr">
              {formatterUS(balances.burnedSmrtr)} $SMRTR
            </p>
            <p className="navbar__dashboard--burnBlock__tresr">
              {formatterUS(balances.burnedTresr)} $TRESR
            </p>
          </div>

          <span className="navbar__dashboard--burn" onClick={onTogglePool}>
            💰
          </span>
          <div
            className={`navbar__dashboard--poolBlock ${
              isOpenPool ? "navbar__dashboard--poolBlock__open" : ""
            }`}
            ref={poolRef}
            id={"poolBlock"}
          >
            <div className="navbar__dashboard--burnBlock__title">
              <span>💰 Game Emissions Remaining</span>
              <img
                src={isDark ? crossIcon : crossIconBlack}
                alt={"close"}
                onClick={onClosePool}
              />
            </div>
            <div className="navbar__dashboard--poolBlock__text">
              <span>Key Rewards Pool:</span>
              <span>{formatterUS(balances.poolAllocation)}</span>
            </div>
            <div className="navbar__dashboard--poolBlock__text">
              <span>Bonus Pool:</span>
              <span>{formatterUS(balances.bonusPoolAllocation)}</span>
            </div>
          </div>
          <span className="navbar__dashboard--burn" onClick={onTogglePrize}>
            🎉
          </span>
          <div
            style={{
              height: "auto"
            }}
            className={`navbar__dashboard--poolBlock ${
              isOpenPrize ? "navbar__dashboard--poolBlock__open" : ""
            }`}
            ref={prizeRef}
            id={"poolBlock"}
          >
            <div className="navbar__dashboard--burnBlock__title">
              <span>🎉 Prize Season Boosts</span>
              <img
                src={isDark ? crossIcon : crossIconBlack}
                alt={"close"}
                onClick={onClosePrize}
              />
            </div>
            {
              prizeSeasonBoosts?.map((boost, index) => (<div key={boost.name} style={{marginBottom: index !== prizeSeasonBoosts.length - 1 ? "24px" : undefined}}>
                  <div className="navbar__dashboard--poolBlock__text" style={{ marginBottom: '8px'}}>
                    <span>{boost.name.toUpperCase()}</span>
                  </div>
                  {
                    boost.boosts.map((b) => (
                      <div key={b.name} className="navbar__dashboard--poolBlock__text" style={{ marginBottom: '8px'}}>
                        <span>{b.name} (up to {b.weight}%)</span>
                        <span>{formatterUS(b.perc/100 * b.weight)}%</span>
                      </div>
                    ))
                  }
                  <div className="navbar__dashboard--poolBlock__text" style={{ marginBottom: '8px', borderTop: "2px solid #FEC90C"}}>
                   <em> <span>Total Boost</span></em>
                    <em><span>{formatterUS(boost.boosts.reduce((tot, b) => (b.perc/100 * b.weight) + tot, 0))}%</span></em>
                  </div>
              </div>)
              )
            }
          </div>
        </div>
      );
  };

  useEffect(() => {
    setHeaderOffsetTop(headerRef?.current?.offsetTop);
  }, []);

  useEffect(() => {
    window?.addEventListener("scroll", () => setStickyHeader());

    return window?.removeEventListener("scroll", () => setStickyHeader());
  }, [headerOffsetTop]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location?.pathname?.includes("/favourites")) {
      setActiveLink(
        navbarItems?.indexOf(
          navbarItems?.find((item) => item.link?.includes("/favourites"))
        )
      );
    } else if (location?.pathname?.includes("/auctions")) {
      setActiveLink(
        navbarItems?.indexOf(
          navbarItems?.find((item) => item.link?.includes("/auctions"))
        )
      );
    } else if (location?.pathname?.includes("/game")) {
      setActiveLink(
        navbarItems?.indexOf(
          navbarItems?.find((item) => item.link?.includes(location?.pathname))
        )
      );
    } else {
      setActiveLink(
        navbarItems?.indexOf(
          navbarItems?.find((item) => location?.pathname.includes(item.link))
        )
      );
    }
  }, [location?.pathname, navbarItems]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isGamePage) return setNavbarItems(NAVBAR_ITEMS_GAME_PAGE);
    else setNavbarItems([]);
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  useOutsideClick(poolRef, onClosePool, tryItOutRef);
  useOutsideClick(burnRef, onCloseBurn, tryItOutRef);
  useOutsideClick(prizeRef, onClosePrize, tryItOutRef);

  return (
    <>
      {/*{isDesktop ? (*/}
      {/*  <NavBarEditMode*/}
      {/*    isOpen={isOpenModal}*/}
      {/*    onClose={() => setIsOpenModal(false)}*/}
      {/*  />*/}
      {/*) : (*/}
      {/*  <NavBarEditModeMobile*/}
      {/*    isOpen={isOpenModal}*/}
      {/*    onClose={() => setIsOpenModal(false)}*/}
      {/*  />*/}
      {/*)}*/}
      <div className={`navbar`} ref={headerRef}>
        <div className="navbar__header">
          {setHeaderButton()}

          {/* {!isMobile && isShownFilters ? (
            <></>
          ) : (
            <> */}
          {/* FIXME */}
          <div className="navbar__items">
            {!isMobile &&
              navbarItems?.map((item, key) => (
                <div
                  onClick={() => onClickTab(item, key)}
                  key={key}
                  className={activeLink === key ? "active" : ""}
                >
                  {item?.label}
                </div>
              ))}
            {isMobile &&
              navbarItems?.map((item, key) => (
                <div
                  onClick={() => onClickTab(item, key)}
                  key={key}
                  className={
                    `navbar__item ${activeLink === key ? "active gap-2 flex flex-col" : ""}`
                  }
                >
                  {item?.label}
                  {activeLink === key ? (
                    <div className="w-full h-0.5 bg-[var(--link-active)]" />
                  ) : (
                    <div />
                  )}
                </div>
              ))}
          </div>
          <div className="navbar__buttons">
          </div>
        </div>
      </div>

    </>
  );
};
export default NavBar;
