import {types} from "../types";
import {ALERT_STATUS_SUCCESS} from "../../constant/alert";

export const initialStateAlert = {
    alert: {
        isActive: false,
        status: ALERT_STATUS_SUCCESS,
        text: '',
        image: ''
    },
};

export const reducerAlert = {
    [types.SET_ALERT]: (state, {isActive, status, text, image}) => {
        return {
            ...state,
            alert: {
                ...state.alert,
                isActive, status, text, image
            },
        }
    }
};
