import React, {useState, useContext} from 'react';
import './style.scss'
import {Context} from "../../../store";

const InputRange = () => {
	const [inputBgSize, setInputBgSize] = useState('100% 100%')
	const [{scale}, ACTION] = useContext(Context);

	return (
		<div className='inputRange'>
			<input
				type="range"
				min={120}
				max={315}
				value={scale?.value}
				step={13}
				style={{backgroundSize: inputBgSize}}
				onChange={(event) => {
					const min = event?.target?.min
					const max = event?.target?.max
					const val = event?.target?.value
					setInputBgSize((val - min) * 100 / (max - min) + '% 100%')
					ACTION.SET_SCALE(true, event?.target?.value)
				}}
			/>
		</div>
	);
};

export default InputRange;
