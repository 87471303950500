import { types } from "../types";

const actions = {
    [types.SET_LOADER]:
        (dispatch) =>
            (isActive, opacityLevel = 3) => {
                dispatch({
                    type: types.SET_LOADER,
                    payload: { isActive, opacityLevel },
                });
            },
    [types.SET_COMPONENT_LOADER]:
        (dispatch) =>
            (componentActive) => {
                dispatch({
                    type: types.SET_COMPONENT_LOADER,
                    payload: { componentActive },
                });
            },
};

export default actions;
