import React from "react"
import './style.scss'

const BenefitsCopy = [
  {
    title: 'INCREASE ECOMMERCE PAYOUTS',
    subtitle: 'UP TO 40% COMMISSIONS'
  },
  {
    title: 'BUY DISCOUNTED MERCH',
    subtitle: 'UP TO 40% DISCOUNT'
  },
  {
    title: 'UNLOCK DEFI REWARDS',
    subtitle: 'REDEEM FOR MERCH, PRIZES & CRYPTO'
  },
  {
    title: 'UNLOCK GOVERNANCE POWER',
  },
  {
    title: 'ACCESS TO EXCLUSIVE PRIZE POOLS',
  },
  {
    title: 'EXCLUSIVE DISCORD CHANNELS',
  },
  {
    title: 'KEYHOLDER ONLY MERCHANDISE',
  },
  {
    title: 'FIRST ACCESS TO NEW FEATURES',
  },
  {
    title: 'UNLOCK COMMUNITY CHEST CARDS',
  },
  {
    title: 'UNLOCK YOUR CRYPTO BUSINESS IN BOX',
  },  
]

const Benefit = ({ title, subtitle }) => (
  <div className="Benefit">
    <div className="Benefit__topline">
      <span className="Benefit__bullet" />
      {title}
    </div>
    {subtitle && (
      <div className="Benefit__subtitle">({subtitle})</div>
    )}
  </div>
)

const Benefits = () => {
  return (
    <div className="Benefits-wrapper">
      <div className="Benefits-column">
        {BenefitsCopy.slice(0,4).map(({ title, subtitle }, index) => (
          <Benefit key={index} title={title} subtitle={subtitle} />
        ))}
      </div>
      <div className="Benefits-column">
        {BenefitsCopy.slice(4).map(({ title, subtitle }, index) => (
          <Benefit key={index} title={title} subtitle={subtitle} />
        ))}
      </div>
    </div>
  )
}

export default Benefits








