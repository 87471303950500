import React, {createContext, useState, useContext, useCallback, useEffect} from 'react';
import PubSub from "pubsub-js";
import {PubSubEvents} from "../constant/events";
import useHandleContracts from "../hooks/blockchain/useHandleContracts";
import axios from "axios";
import papaparse from 'papaparse';
import {hexToNumber} from "../utils/blockchain";
import ipfsHashes from '../ipfs/ipfs_hashes.json';
import {Context} from "../store";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";


const WhiteListContext = createContext({
  whiteList: {
    proof: [],
    details: {
      whitelistAddress: '',
      level: 1,
      zone1: false,
      zone2: false,
      zone3: false,
      zone4: false,
    },
    merkle: {
      whitelistAddress: '',
      level: 1,
      zone1: false,
      zone2: false,
      zone3: false,
      zone4: false,
    }
  },
  commissions: [0, 0, 0, 0, 0],

});
export const useWhiteListContext = () => useContext(WhiteListContext);
const initialState = {
  proof: [],
  details: {
    whitelistAddress: '',
    level: 1,
    zone1: false,
    zone2: false,
    zone3: false,
    zone4: false,
  },
  merkle: {
    whitelistAddress: '',
    level: 1,
    zone1: false,
    zone2: false,
    zone3: false,
    zone4: false,
  }
};
export const WhiteListContextProvider = ({ children }) => {
  const { address, isConnected } = useWeb3ModalAccount();
  const [ whiteList, setWhiteList ] = useState(initialState);
  const [foundWallet, setFoundWallet] = useState(null);
  const [ commissions, setCommissions ] = useState([]);
  const [, ACTION] = useContext(Context);

  const { contractWhitelistWithSigner, contractNFKey } = useHandleContracts();



  useEffect(() => {
    if (!isConnected || !contractNFKey) {
      return;
    }

    const getWhiteListDetails = async () => {
      const key = address.substring(2,4).toLowerCase();
      const hash = ipfsHashes[key];
      const ipfsAddress = `${process.env.REACT_APP_IPFS_ROOT}${hash}`
      const { data: wallets } = await axios.get(ipfsAddress);
      const parsed = papaparse.parse(wallets, { headers: true, dynamicTyping: true  }).data;
      const found = parsed.find(item => item[0].toLowerCase() === address.toLowerCase());
      if (!found) {
        setFoundWallet({
          found: false
        });
      } else {
        const foundWallet = {
          merkle: {
            whitelistAddress: found[0].toLowerCase(),
            level: Number(found[1]),
            zone1: found[2],
            zone2: found[3],
            zone3: found[4],
            zone4: found[5],
          },
          proof: found[6].split(',')
        };
        setFoundWallet(foundWallet);
      }
    }

    const getCommissions = async () => {
      const commissionValues = await Promise.all([
        contractNFKey
          .getZoneCommission(address, 1, 1)
          .then((res) => Number(res) / 10**18),
        contractNFKey
          .getZoneCommission(address, 2, 1)
          .then((res) => Number(res) / 10**18),
        contractNFKey
          .getZoneCommission(address, 3, 1)
          .then((res) => Number(res) / 10**18),
        contractNFKey
          .getZoneCommission(address, 4, 1)
          .then((res) => Number(res) / 10**18),
        contractNFKey
          .getZoneCommission(address, 4, 2)
          .then((res) => Number(res) / 10**18),
        contractNFKey
          .getZoneCommission(address, 5, 1)
          .then((res) => Number(res) / 10**18)
      ]);
      setCommissions([
        commissionValues[0],
        commissionValues[1],
        commissionValues[2],
        [
          commissionValues[3],
          commissionValues[4],
        ],
        commissionValues[5],
      ]);
    }
    getCommissions();
    getWhiteListDetails();
  }, [address, isConnected, contractNFKey])



  useEffect(() => {
    if (!isConnected || !contractWhitelistWithSigner || foundWallet === null || commissions[0] === undefined) {
      return;
    }

    if (foundWallet.found === false) {
      ACTION.SET_LOADER(false);
      setWhiteList({
        proof: [],
        details: {
          whitelistAddress: address.toLowerCase(),
          level: 1,
          zone1: false,
          zone2: false,
          zone3: false,
          zone4: false,
        },
        merkle: {
          whitelistAddress: address.toLowerCase(),
          level: 1,
          zone1: false,
          zone2: false,
          zone3: false,
          zone4: false,
        }
      })
      return;
    }

    const run = async () => {
      const zones = await Promise.all([
        contractWhitelistWithSigner
          .getWhitelistZone(foundWallet.merkle.whitelistAddress, 1, foundWallet.merkle, foundWallet.proof),
        contractWhitelistWithSigner
          .getWhitelistZone(foundWallet.merkle.whitelistAddress, 2, foundWallet.merkle, foundWallet.proof),
        contractWhitelistWithSigner
          .getWhitelistZone(foundWallet.merkle.whitelistAddress, 3, foundWallet.merkle, foundWallet.proof),
        contractWhitelistWithSigner
          .getWhitelistZone(foundWallet.merkle.whitelistAddress, 4, foundWallet.merkle, foundWallet.proof)
      ]);

      setWhiteList({
        proof: foundWallet.proof,
        details: {
          whitelistAddress: foundWallet.merkle.whitelistAddress,
          level: foundWallet.merkle.level,
          zone1: zones[0],
          zone2: zones[1],
          zone3: zones[2],
          zone4: zones[3],
        },
        merkle: foundWallet.merkle
      });

      ACTION.SET_LOADER(false);
    }

    run();
  }, [isConnected, address, foundWallet, commissions, contractWhitelistWithSigner]);

  const refreshWhitelist = useCallback(async(owner) => {
    if (owner !== whiteList.merkle.whitelistAddress) {
      return;
    }

    const zones = await Promise.all([
      contractWhitelistWithSigner
        .getWhitelistZone(owner, 1, whiteList.merkle, whiteList.proof),
      contractWhitelistWithSigner
        .getWhitelistZone(owner, 2, whiteList.merkle, whiteList.proof),
      contractWhitelistWithSigner
        .getWhitelistZone(owner, 3, whiteList.merkle, whiteList.proof),
      contractWhitelistWithSigner
        .getWhitelistZone(owner, 4, whiteList.merkle, whiteList.proof),
    ])
    setWhiteList({
      proof: whiteList.proof,
      details: {
        whitelistAddress: owner,
        level: whiteList.merkle.level,
        zone1: zones[0],
        zone2: zones[1],
        zone3: zones[2],
        zone4: zones[3],
      },
      merkle: whiteList.merkle
    });
  }, [whiteList, contractWhitelistWithSigner]);

  useEffect(() => {
    if (!isConnected || !contractNFKey) {
      return;
    }
    const ev = (owner) => {
      refreshWhitelist(owner)
    };
    contractNFKey.on('Minted', ev);
    return () => {
      contractNFKey.off('Minted', ev);
    };
  }, [refreshWhitelist, isConnected, contractNFKey]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    const logoutToken = PubSub.subscribe(PubSubEvents.LOGOUT, () => {
      setWhiteList(initialState);
      setCommissions([]);
      setFoundWallet(null);
    });

    const refresh = PubSub.subscribe(PubSubEvents.NFKEYS_BATCH_MINT, () => {
      refreshWhitelist(address)
    });

    return () => {
      PubSub.unsubscribe(logoutToken);
      PubSub.unsubscribe(refresh);
    };
  }, [refreshWhitelist, address, isConnected]);

  const value = {
    whiteList,
    commissions,
  };

  return (
    <WhiteListContext.Provider value={value}>
      {children}
    </WhiteListContext.Provider>
  );
};
