import { types } from "../types";
import { reducerLoader, initialStateLoader } from "./loader";
import { reducerTheme, initialStateTheme } from "./theme";
import { reducerBalance, initialStateBalance } from "./balance";
import { reducerAlert, initialStateAlert } from "./alert";
import { initialStateScale, reducerScale } from "./scale";

export const initialState = {
  user: null,
  wallet: null,
  whitelist: null,
  tokens_count: null,
  pending_loader: false,
  chest_modal: null,
  chest_hash: null,
  timestamp: Date.now(),
  accountBannerImage: "",
  ...initialStateLoader,
  ...initialStateTheme,
  ...initialStateBalance,
  ...initialStateAlert,
  ...initialStateScale,
};

export const reducers = {
  ...reducerLoader,
  ...reducerTheme,
  ...reducerBalance,
  ...reducerAlert,
  ...reducerScale,
  [types.SET_TOKENS_COUNT]: (state, tokens_count) => ({
    ...state,
    tokens_count,
  }),
  [types.SET_TIMESTAMP]: (state, timestamp) => ({
    ...state,
    timestamp,
  }),
  [types.SET_PENDING_LOADER]: (state, pending_loader) => ({
    ...state,
    pending_loader,
  }),

  [types.SET_CHEST_MODAL]: (state, chest_modal) => ({
    ...state,
    chest_modal,
  }),
  [types.SET_ACCOUNT_BANNER_IMAGE]: (state, accountBannerImage) => ({
    ...state,
    accountBannerImage,
  }),
};
