import React, { useCallback, useEffect, useState } from 'react'

export const useNavigationButtons = (emblaApi, onButtonClick) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
    if (onButtonClick) onButtonClick(emblaApi)
  }, [emblaApi, onButtonClick])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
    if (onButtonClick) onButtonClick(emblaApi)
  }, [emblaApi, onButtonClick])

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}

const NavigationButton = (props) => {
  const { children, direction, ...restProps } = props

  return (
    <button
      className={`KeyArt__button KeyArt__button--${direction}`}
      type="button"
      {...restProps}
    >
      <svg className="KeyArt__button__svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
        <g clipPath="url(#clip0_6715_70545)">
          <rect x="56" width="56" height="56" rx="28" transform="rotate(90 56 0)" fill="#F6CB46"/>
          <path d="M17.5 27.8L38.221 27.779M31.221 35.578L39 27.8M31.221 20L38.999 27.779" stroke="#080F20" strokeWidth="3" strokeLinecap="round"/>
        </g>
        <rect x="54.5" y="1.5" width="53" height="53" rx="26.5" transform="rotate(90 54.5 1.5)" stroke="#FDFDFD" strokeWidth="3"/>
        <defs>
          <clipPath id="clip0_6715_70545">
          <rect x="56" width="56" height="56" rx="28" transform="rotate(90 56 0)" fill="white"/>
        </clipPath>
        </defs>
      </svg>
      {children}
    </button>
  )
}

export const PrevButton = (props) => <NavigationButton direction='prev' {...props} />
export const NextButton = (props) => <NavigationButton direction='next' {...props} />