import "./style.scss";
import { Context } from "../../../../store";
import { useContext, useEffect, useState } from "react";
import { MAX_CHEST_TIER } from "../../../../constant/blockchain";
import KeyTimer from "../../../common/KeyTimer";
import useHandleNFT from "../../../../hooks/blockchain/useHandleNFT";
import { compareUnixDates } from "../../../../utils";
import moment from "moment/moment";
import {useTokenContext} from "../../../../contexts/tokenContext";

export default function NFKey({token}) {
  const {
    tierTresr,
    tokenId,
    contractAddress,
    image,
    isAnimated,
    level,
    staked,
  } = token;
  const [_, ACTION] = useContext(Context);
  const { tokenList, selectedToken, setSelectedToken } = useTokenContext();

  const handleNFT = useHandleNFT();
  
  const [timeToUpgrade, setTimeToUpgrade] = useState(0);
  const [startUpgradeDelay, setStartUpgradeDelay] = useState(0);
  const [percentToUpgrade, setPercentToUpgrade] = useState(0);
  const [timerID, setTimerID] = useState(0);
  const isActive = tokenId === selectedToken.tokenId;

  const chestProgressLine = MAX_CHEST_TIER / tierTresr;
  const chestProgressLineWidth =
    chestProgressLine <= 1 ? 100 : 100 / chestProgressLine;

  const onNFKey = () => {
    if (!isActive) {
      const findToken = tokenList.find(
        (item) =>
          item?.tokenId === tokenId && item?.contractAddress === contractAddress
      );
      if (findToken && findToken !== selectedToken) {
        ACTION.SET_COMPONENT_LOADER(true);
        setSelectedToken(findToken);
      }
    }
  };

  // useEffect(() => {
  //   if (!selectedToken) {
  //     return
  //   }
  //   const foundToken = tokenList.find(
  //     (item) =>
  //       item?.tokenId === tokenId
  //   );
  //   console.log("UPGRADE DELAYS", foundToken.keyUpgradeDelay);
  //   // setTimeToUpgrade(foundToken.keyUpgradeDelay);
  //   // handleNFT.getUpgradeDelays([tokenId]).then((res) => setTimeToUpgrade(res[0]));
  //   // handleNFT
  //   //   .getStartUpgradeDelays([tokenId])
  //   //   .then((res) => setStartUpgradeDelay(res[0]));
  // }, [tokenId, level, selectedToken?.level]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   clearInterval(timerID);
  //
  //   if (compareUnixDates(new Date().getTime() / 1000, timeToUpgrade)) {
  //     // console.log('full', new Date().getTime() / 1000, timeToUpgrade);
  //
  //     setPercentToUpgrade(100);
  //   } else {
  //     const timer = setInterval(() => {
  //       setPercentToUpgrade(() => {
  //         const percent =
  //           100 -
  //           ((timeToUpgrade - moment().unix()) * 100) /
  //             (timeToUpgrade - startUpgradeDelay);
  //         console.log(percent);
  //         if (percent >= 100) return 100;
  //         return percent;
  //       });
  //     }, 1000);
  //     setTimerID(timer);
  //   }
  //
  //   return () => clearInterval(timerID);
  // }, [timeToUpgrade, timerID, startUpgradeDelay]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`NFKey ${isActive ? "active" : ""} hover:border-[#2D46D6]`}
      onClick={onNFKey}
    >
      <div
        className="NFKey__image"
        style={{
          background: `linear-gradient(45.44deg, #4964FE ${percentToUpgrade}%, rgba(73, 100, 254, 0) 48.31%)`,
        }}
      >
        <img src={image} alt="" />
      </div>
      {isAnimated ? (
        <></>
      ) : (
        <div className="NFKey__info">
          <div className="NFKey__info--title">
            <div
              className={`NFKey__info--title__name ${
                isActive ? "NFKey__info--title__name--active" : ""
              }`}
            >
              Treasure Key #{tokenId}
            </div>
            <div
              className={`NFKey__info--title__count ${
                isActive ? "NFKey__info--title__count--active" : ""
              }`}
            >
              Level {level}
            </div>
          </div>
          {staked ? (
            <div className="NFKey__info--wrapper">
              <div className="NFKey__info--progressLine">
                <div
                  className={`NFKey__info--progressLine__bar yellow ${
                    isActive ? "yellow__active" : ""
                  }`}
                  style={{ width: `${chestProgressLineWidth}%` }}
                ></div>
                {/* <div className={`text-center text-[10px] ${tierTresr > 4 ? 'text-black': ''}`}>
                                    Tier {tierTresr}/{MAX_CHEST_TIER}
                                </div> */}
                <div
                  className={`text-center text-[10px] text-[#FEF9EC]`}
                  style={{ mixBlendMode: "difference" }}
                >
                  Tier {tierTresr}/{MAX_CHEST_TIER}
                </div>
              </div>
              <KeyTimer
                size='small'
                token={token}
                isActive={isActive}
              />
            </div>
          ) : (
            <div
              className={`NFKey__info--inactive ${
                isActive ? "NFKey__info--inactive__active" : ""
              }`}
            >
              inactive
            </div>
          )}
        </div>
      )}
    </div>
  );
}
