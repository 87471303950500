import React from 'react';
import './style.scss'
import {getImagePath} from "../../../constant/blockchain";
import {useNavigate} from "react-router-dom";

const DashboardNotWhitelisted = ({showStaking}) => {
  const navigate = useNavigate();

    return (
        <div className="dashboard__data__content--form__selectedToken">
            <div className={'dashboard__data__content--form__selectedToken--wrapper'}>
                <img src={getImagePath(1)} alt={'Key'} />
            </div>
            <div className="dashboard__data__content--form__selectedToken--info">

                <p className='dashboardNotWhitelisted__title'>No Treasure Keys Detected</p>
                <p className='dashboardNotWhitelisted__text'>Click below to mint a Treasure Key and get access to the full NFTREASURE universe!</p>

                <a
                  className="dashboardNotWhitelisted__link"
                  onClick={() => navigate('/')}
                >
                  <button className="button">
                    Go to mint page
                  </button>
                </a>
              { showStaking && <p style={{ paddingTop: '25px'}} className='dashboardNotWhitelisted__text'>Otherwise you can also stake $TRESR or LP to earn rewards.</p> }
              { showStaking &&
                <a
                  onClick={() => navigate('/game/dashboard')}
                  className="dashboardNotWhitelisted__link"
                >
                  <button className="button">
                    Go to staking
                  </button>
                </a>
              }
            </div>
        </div>
    );
};


export default DashboardNotWhitelisted;
