import { useContext, useState } from "react";
import HeaderBar from "../../components/HeaderBar";
import useEffectFirstLoad from "../../hooks/useFirstLoad";
import { useLocation } from "react-router-dom";
import NavBar from "../../components/common/NavBar";
import React from "react";
import Footer from "../../components/Footer";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import { Context } from "../../store";
import CustomAlert from "../../components/common/CustomAlert";
import useFaucet from "../../hooks/blockchain/useFaucet";
import HeaderBarAccountOpen from "../../components/HeaderBar/HeaderBarAccountOpen";
import TransactionLoadingModal from "../../components/common/Modals/TransactionLoadingModal";
import useHandleModal from "../../hooks/dom/useHandleModal";
import InputRange from "../../components/common/InputRange";
import useWindowDimensions from "hooks/useWidowDimensions";
import PageLoader from "components/Loaders/PageLoader";
import {useAppContext} from "../../contexts/appContext";
import {useTokenContext} from "../../contexts/tokenContext";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

export default function UserLayout({ children }) {
  const [{ loader, scale }] = useContext(Context);
  const { isConnected } = useWeb3ModalAccount()
  const location = useLocation();
  const faucet = useFaucet();
  const handleTransactionLoadingModal = useHandleModal();
  const { hasUnclaimedTresr, claimTresr } = useTokenContext();
  const [showFaucet, toggleFaucet] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);

  const isHomePage = location?.pathname === "/";
  const isCollectionPage = location?.pathname?.includes("collections");
  const isAccountPage = location?.pathname?.includes("account");
  const isGame = location?.pathname?.includes("game");
  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 450;

  const handleToggleFaucet = () => toggleFaucet(!showFaucet);

  const onCloseTransactionLoadingModal = () => {
    handleTransactionLoadingModal.close();
  }

  const onGetTresr = () => {
    handleTransactionLoadingModal.open();
    faucet.getTRESR().finally(() => handleTransactionLoadingModal.close());
  };
  const onGetSmrtr = () => {
    handleTransactionLoadingModal.open();
    faucet.getSMRTR().finally(() => handleTransactionLoadingModal.close());
  };
  const onGetTresrLP = () => {
    handleTransactionLoadingModal.open();
    faucet.getTRESRLP().finally(() => handleTransactionLoadingModal.close());
  };
  const onGetSmrtrLP = () => {
    handleTransactionLoadingModal.open();
    faucet.getSMRTRLP().finally(() => handleTransactionLoadingModal.close());
  };

  useEffectFirstLoad();

  return (
    <>
      <div id="layout-wrapper">
        {loader?.componentActive && (
          <div className="w-screen h-screen bg-[#ffffff10] fixed left-0 top-0 z-50">
            <PageLoader
              isLoaderActive={true}
              opacityLevel={0}
              customClass="pageLoaderWrapper mt-[30rem]"
            />
          </div>
        )}
        <HeaderBar
          setIsAccountOpen={setIsAccountOpen}
          isAccountOpen={isAccountOpen}
        />
        {isConnected && (
          <HeaderBarAccountOpen
            isAccountOpen={isAccountOpen}
            setIsAccountOpen={setIsAccountOpen}
          />
        )}
        <CustomAlert />
        {!isHomePage && !loader?.isActive && <NavBar />}
        <div className={`main-content main-content__bg`}>
          <div className="page-content">{children}</div>
        </div>
        <Footer />
        {isGame && loader?.isActive && (
          <FullPageLoader
            isLoaderActive={loader?.isActive}
            opacityLevel={loader?.opacityLevel}
          />
        )}

        {scale?.isActive && (isCollectionPage || isAccountPage) && (
          <div className="main__range">
            <InputRange />
          </div>
        )}

        {isConnected && !isMobile && (process.env.REACT_APP_IS_BETA_TEST === 'true' || hasUnclaimedTresr) &&  (
          <div className="faucet" onClick={!hasUnclaimedTresr ? handleToggleFaucet : claimTresr}>
            { process.env.REACT_APP_IS_BETA_TEST === 'true' && !hasUnclaimedTresr && <div>Faucet</div> }
            { hasUnclaimedTresr && <div>Claim $TRESR</div> }
          </div>
        )}

        {showFaucet && !hasUnclaimedTresr && (
          <div className="faucet-list">
            <button className="button" onClick={onGetTresr}>
              TRESR
            </button>
            <button className="button" onClick={onGetSmrtr}>
              SMARTR
            </button>
            <button className="button" onClick={onGetTresrLP}>
              TRESR-LP
            </button>
            <button className="button" onClick={onGetSmrtrLP}>
              SMARTR-LP
            </button>
          </div>
        )}

        {handleTransactionLoadingModal.isActive && (
          <TransactionLoadingModal
            isOpen={handleTransactionLoadingModal.isActive}
            onClose={onCloseTransactionLoadingModal}
          />
        )}
      </div>
    </>
  );
}
