import React, { useContext, useRef, useState } from "react";
import avatarPlaceholder from "../../../assets/images/key_yellow.svg";
import avaxImg from "../../../assets/images/avax_blue_img.svg";
import smrtrImg from "../../../assets/images/smrtr_img.png";
import smrtrLPImg from "../../../assets/images/smrtr_lp.png";
import tresrImg from "../../../assets/images/tresr_img.svg";
import tresrLPImg from "../../../assets/images/tresr_lp.svg";
import disconnectLight from "../../../assets/images/disconnect_light.svg";
import disconnectDark from "../../../assets/images/disconnect_dark.svg";
import { Context } from "../../../store";
import useCommon from "../../../hooks/useCommon";
import {useAppContext} from "../../../contexts/appContext";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

const HeaderBarAccountComponent = () => {
  const [{ theme }] = useContext(Context);
  const { logout, balances } = useAppContext();
  const [isCopied, setIsCopied] = useState(false);
  const { address } = useWeb3ModalAccount()
  const textRef = useRef();
  const { americanFormatNumber } = useCommon();

  const balanceAvax = americanFormatNumber(balances.balanceAvax, 3);
  const balanceSmrtr = americanFormatNumber(balances.balanceSmrtr, 3);
  const balanceTresr = americanFormatNumber(balances.balanceTresr, 3);
  const balanceLpSMRTRAVAX = americanFormatNumber(
    balances.balanceLpSMRTRAVAX,
    3
  );
  const balanceLpTRESRAVAX = americanFormatNumber(
    balances.balanceLpTRESRAVAX,
    3
  );

  const isDark = theme === "dark";
  const onCopyImg = () => {
    navigator.clipboard.writeText(textRef?.current?.value).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 5000);
    });
  };

  const onDisconnect = () => {
    logout();
  };

  return (
    <>
      <div className="headerBarAccountOpen__account">
        <img
          className="headerBarAccountOpen__avatar"
          src={avatarPlaceholder}
          alt=""
        />
        <div>
          <p className="headerBarAccountOpen__network">Avalanche</p>
          <div
            className="headerBarAccountOpen__address--container"
            onClick={onCopyImg}
          >
            <p
              className={`headerBarAccountOpen__address ${
                isCopied && "headerBarAccountOpen__address--copied"
              }`}
            >
              {isCopied
                ? "Copied to Clipboard"
                : address.slice(0, 9) +
                  "..." +
                  address.slice(-5)}
            </p>
            <input
              value={address}
              ref={textRef}
              hidden
              onChange={() => {}}
            />
            <div
              className={`headerBarAccountOpen__address--img 
                            ${
                              isCopied &&
                              "headerBarAccountOpen__address--imgCopied"
                            }`}
            />
          </div>
        </div>
      </div>

      <div className="headerBarAccountOpen__wallet mt-6">
        <div className="headerBarAccountOpen__profile">
          <img src={avaxImg} alt={"avax"} />
          <p className="headerBarAccountOpen__network">{balanceAvax} AVAX</p>
        </div>
        <div className="headerBarAccountOpen__profile">
          <img src={smrtrImg} alt={"smrtr"} />
          <p className="headerBarAccountOpen__network">{balanceSmrtr} SMRTR</p>
        </div>
        <div className="headerBarAccountOpen__profile">
          <img src={tresrImg} alt={"tresr"} />
          <p className="headerBarAccountOpen__network">{balanceTresr} TRESR</p>
        </div>
        <div className="headerBarAccountOpen__profile">
          <img src={tresrImg} alt={"tresr"} />
          <p className="headerBarAccountOpen__network">
            {americanFormatNumber(balances.balanceVeTresr, 3)} veTRESR
          </p>
        </div>
        <div className="headerBarAccountOpen__profile">
          <img src={smrtrLPImg} alt={"tresr"} />
          <p className="headerBarAccountOpen__network headerBarAccountOpen__network--lp">
            {balanceLpSMRTRAVAX} SMRTR AVAX LP
          </p>
        </div>
        <div className="headerBarAccountOpen__profile">
          <img src={tresrLPImg} alt={"tresr"} />
          <p className="headerBarAccountOpen__network headerBarAccountOpen__network--lp">
            {balanceLpTRESRAVAX} TRESR AVAX LP
          </p>
        </div>
      </div>

      <div
        className="headerBarAccountOpen__profile headerBarAccountOpen__disconnect -mb-[10px]"
        onClick={onDisconnect}
      >
        <img src={isDark ? disconnectLight : disconnectDark} alt="" />
        <p className="headerBarAccountOpen__address">Disconnect</p>
      </div>
    </>
  );
};

export default HeaderBarAccountComponent;
