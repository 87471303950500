import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import { Provider } from "./store";
import { TimerProvider } from "./store/context/TimerContext";
import Modal from "react-modal";


const root = ReactDOM.createRoot(document.getElementById("root"));
Modal.setAppElement('#root');
root.render(
  <Router>
    <TimerProvider>
      <Provider>
        <App />
      </Provider>

    </TimerProvider>
  </Router>
);
