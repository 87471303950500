import React from 'react';
import ModalLayout from "../index";
import MarketplaceButton from "../../MarketplaceButton";
import './style.scss'

const TreasureWarningModal = ({onClose, isOpen}) => {
    return (
        <ModalLayout
            onClose={onClose}
            isOpen={isOpen}
            maxWidth={'458px'}
            maxHeight={'268px'}
            padding={'32px'}
            withCrossIcon>

            <p className='modal__title'>Warning</p>
            <p className='modal__text treasureWarningModal__text'>First activate your Treasure Key and try again.</p>

            <div className='upgradeKeyModal__buttons'>
                <MarketplaceButton onClick={onClose} title="OK" isBlue/>
            </div>

        </ModalLayout>
    );
};

export default TreasureWarningModal;
