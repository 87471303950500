import React, { useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { DotButton, useDotButton } from './useDotButton'
import Autoplay from 'embla-carousel-autoplay'
import './style.scss'

import {useNavigationButtons, PrevButton, NextButton} from './useNavigationButtons'
import useWindowDimensions from 'hooks/useWidowDimensions'
import {getImagePath} from 'constant/blockchain'

const images = [
  getImagePath(3),
  getImagePath(11),
  getImagePath(15),
  getImagePath(23),
  getImagePath(27),
  getImagePath(36),
  getImagePath(31),
  getImagePath(39),
]

const KeyArtCarousel = () => {
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions?.width <= 1000;
  const [emblaRef, emblaApi] = useEmblaCarousel({ align: isMobile ? 'center' : 'start', loop: false }, [Autoplay()])
  const slides = images

  const onButtonClick = useCallback((emblaApi) => {
    const { autoplay } = emblaApi.plugins()
    if (!autoplay) return
    if (autoplay.options.stopOnInteraction !== false) autoplay.stop()
  }, [])

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = useNavigationButtons(emblaApi, onButtonClick)

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onButtonClick
  )

  return (
    <div className="KeyArt">
      <div className="KeyArt__viewport" ref={emblaRef}>
        <div className="KeyArt__container">
          {slides.map((src, index) => (
            <div className="KeyArt__slide" key={index}>
              <img
                className="KeyArt__slide__img"
                src={src}
                alt="Your alt text"
              />
            </div>
          ))}
        </div>
      </div>

      <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
      <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />

      <div className="KeyArt__dots">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => onDotButtonClick(index)}
            className={'KeyArt__dot'.concat(
              index === selectedIndex ? ' KeyArt__dot--selected' : ''
            )}
          />
        ))}
      </div>
    </div>
  )
}

export default KeyArtCarousel


// const KeyArtImageRow = () => {
//   return (
//     <div className="KeyArt-wrapper">
//       <div className="KeyArt-wrapper__row">
//         <img 
//           alt="NFTreasure Key Art"
//           src={key15}
//         />
//         <img 
//           alt="NFTreasure Key Art"
//           src={key36}
//         />
//         <img 
//           alt="NFTreasure Key Art"
//           src={key41}
//         />
//         <img 
//           alt="NFTreasure Key Art"
//           src={key49}
//         />
//       </div>
//     </div>
//   )
// }

// export default KeyArtImageRow