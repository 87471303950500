import React, {useContext, useEffect} from 'react';
import DashboardVotingEscrow from "../DashboardVotingEscrow";
import DashboardBonusCard from "../DashboardBonusCard";
import DashboardTotalPoolReward from "../DashboardTotalPoolReward";
import DashboardTresrSmartrAvaxStakingWrapper from "../DashboardTresrSmartrAvaxStakingWrapper";
import {Context} from "../../../store";
import {useRewardsContext} from "../../../contexts/rewardsContext";
import {useAppContext} from "../../../contexts/appContext";

const DashboardTab = ({
                          onStakeTresr,
                          onUnstakeTresr,
                          onClaimBonus,
                          onClaimAll,
                          onStakeLP,
                          onUnstakeLP,
                          onApprove,
                          onclaimVeTresr
                      }) => {
    const { stopRewardsTimer, startRewardsTimer } = useRewardsContext();
    useEffect(() => {
      startRewardsTimer();
      return () => {
        stopRewardsTimer();
      }
    }, [startRewardsTimer, stopRewardsTimer]);
    return (
        <div className="learMoreWrapper">

            <div className='learMoreWrapper__leftBlock'>
                <DashboardTotalPoolReward onClaimAll={onClaimAll}/>
                <DashboardBonusCard onClaimBonus={onClaimBonus}/>
            </div>


            <div className='learMoreWrapper__rightBlock'>
            <DashboardVotingEscrow onStakeTresr={onStakeTresr} onUnstakeTresr={onUnstakeTresr} onclaimVeTresr={onclaimVeTresr}/>

                <DashboardTresrSmartrAvaxStakingWrapper
                    onStakeLP={onStakeLP}
                    onUnstakeLP={onUnstakeLP}
                    onApprove={onApprove}
                />
            </div>
        </div>
    );
};

export default DashboardTab;
