import PriceApi from "../../api/PriceApi";

export default function useGetTokenPrice() {
  const getTokenPrice = async (selectedCurrency) => {
    const {price, liquidity} = await new PriceApi().getPrice(
      selectedCurrency.pool
    );
    return {
      price,
      liquidity,
    };
  };

  return {
    getTokenPrice,
  };
}
