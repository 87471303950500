import axios from "axios";
const DEXSCREENER_API = 'https://api.dexscreener.com/latest/dex/pairs/avalanche/'

export default class PriceApi {
    async getPrice(lpAddress) {
        try {
            const {data} = await axios
              .get(`${DEXSCREENER_API}${lpAddress}`);

            const price = data.pair.priceUsd;
            const liquidity = data.pair.liquidity.usd;
            return {
                price,
                liquidity,
            }
        } catch(error) {
            return { status: false, message: error }
        }
    }
}

// {
// pair: {
//     chainId: "avalanche",
//       dexId: "traderjoe",
//       url: "https://dexscreener.com/avalanche/0x7b7617c7b2236d7871741783cae8bcc222c2e05d",
//       pairAddress: "0x7B7617c7B2236D7871741783caE8BCc222C2e05D",
//       baseToken: {
//         address: "0x6D923f688C7FF287dc3A5943CAeefc994F97b290",
//           name: "SmarterCoin",
//           symbol: "SMRTr"
//     },
//     quoteToken: {
//         address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
//           name: "Wrapped AVAX",
//           symbol: "WAVAX"
//     },
//     priceNative: "0.00000001317",
//       priceUsd: "0.0000004835"
//     }
// }