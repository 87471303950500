import {types} from "../types";

export const initialStateScale = {
    scale: {
        isActive: false,
        value: 220
    },
};

export const reducerScale = {
    [types.SET_SCALE]: (state, {isActive, value}) => {
        return {
            ...state,
            scale: {
                ...state.scale,
                isActive, value
            },
        }
    }
};
