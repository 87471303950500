import React, {useCallback, useState} from "react";
import ModalLayout from "../index";
import MarketplaceButton from "../../MarketplaceButton";

const AcceptTermsModal = ({
    onClose,
    isOpen,
    onAccept,
}) => {
  const [isAccepting, setIsAccepting] = useState(false);
  const acceptCallback = useCallback(() => {
    setIsAccepting(true);
    onAccept();
  }, [onAccept]);
  return (
    <ModalLayout
      onClose={onClose}
      isOpen={isOpen}
      maxWidth={"460px"}
      maxHeight={"300px"}
      padding={"32px"}
      withCrossIcon
    >
      <p className="modal__title">Accept Terms Before Minting</p>
      <p className="modal__text upgradeKeyModal__text">
        To participate in NFTREASURE you must first <a style={{
          color: "rgb(254, 201, 12)",
          textDecorationLine: 'underline'
      }} target={"_blank"} href={`${process.env.REACT_APP_IPFS_ROOT}${process.env.REACT_APP_TERMS_HASH}`}>read, understand and accept the Disclaimer.</a>
      </p>

      <div className="upgradeKeyModal__buttons">
        <MarketplaceButton title={"Cancel"} onClick={onClose} />
        <MarketplaceButton
          title={"Accept"}
          isBlue
          onClick={acceptCallback}
          disabled={isAccepting}
        />
      </div>
    </ModalLayout>
  );
};

export default AcceptTermsModal;
