import React, { useEffect, useState } from "react";
import "./style.scss";
import ModalLayout from "../index";
import { BASE_REWARD } from "../../../../constant/blockchain";
import { NFKEY_SOCIALS } from "../../../../constant/singleCollectionPage";
import SocialLinkComponent from "../../SocialLinkComponent";
import useWindowDimensions from "../../../../hooks/useWidowDimensions";
import {useTokenContext} from "../../../../contexts/tokenContext";
import MarketplaceButton from "../../MarketplaceButton";

const SuccessKeyUpgradeModal = ({ onClose, isOpen }) => {
  const { selectedToken } = useTokenContext();
  const [socials, setSocials] = useState([]);
  const tierPerMonth =
    +selectedToken?.level * selectedToken?.tierTresr * BASE_REWARD * 30;
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions?.width <= 850;

  useEffect(() => {
    setSocials(NFKEY_SOCIALS);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalLayout
      padding={"40px"}
      maxHeight={"598px"}
      maxWidth={"458px"}
      onClose={onClose}
      withCrossIcon
      isOpen={isOpen}
    >
      <p className="modal__title successKeyUpgradeModal__title">
        Upgrade Complete!
      </p>

      <p className="modal__text successKeyUpgradeModal__text">
        Treasure Key #{selectedToken?.tokenId} is updated to{" "}
        <span className="successKeyUpgradeModal__text--yellow">
          Level {selectedToken.level}
        </span>
        !
      </p>
      <p className="modal__text successKeyUpgradeModal__text">
        You're currently earning{" "}
        <span className="successKeyUpgradeModal__text--yellow">
          {tierPerMonth} $TRESR/month
        </span>
      </p>
      <p className="modal__text successKeyUpgradeModal__text">
        Open a Treasure Box to earn more TRESR
      </p>

      <div className="successModal__image successKeyUpgradeModal__image">
        <img src={selectedToken?.image} alt={"New token"}/>
      </div>

      <p className="modal__text successModal__text--bottom">
        Share it and help grow the ecosystem!
      </p>
      {!isMobile && (
        <div className="successKeyUpgradeModal_socials justify-center  ">
          {!!socials?.length &&
            socials?.map((item, key) => (
              <SocialLinkComponent
                key={key}
                isEditMode={false}
                link={item?.link}
                index={key}
              />
            ))}
        </div>
      )}
      {
        isMobile && (
          <div className="flex gap-[11px] justify-center">
            <MarketplaceButton
              title={"Close"}
              isBlue
              onClick={onClose}
            />
          </div>
        )
      }
    </ModalLayout>
  );
};

export default SuccessKeyUpgradeModal;
