import { DASHBOARD_FILTERS } from "../../../constant/navigation";
import refreshIcon from "assets/images/refresh.svg";
import Tooltip from "components/common/Tooltip";
import {useTokenContext} from "../../../contexts/tokenContext";

export default function DashboardFilters({
  activeFilter,
  setActiveFilter,
  handleTransactionLoadingModal,
}) {
  const { refreshTokenList } = useTokenContext();
  return (
    <div className="dashboard__data__content--list__filters">
      {DASHBOARD_FILTERS.map((filter, key) => (
        <div
          key={key}
          className={`dashboard__data__content--list__filters--item ${
            filter?.value === activeFilter?.value ? "active" : ""
          }`}
          onClick={() => setActiveFilter(filter)}
        >
          {filter.label}
        </div>
      ))}
      <div
        className="absolute right-4 cursor-pointer"
        onClick={async () => {
          handleTransactionLoadingModal.open();
          await refreshTokenList();
          handleTransactionLoadingModal.close();
        }}
      >
        <Tooltip tooltipText="Manual Update" top={-40} width={100}>
          <img src={refreshIcon} alt="" />
        </Tooltip>
      </div>
    </div>
  );
}
