import { NavLink, useNavigate } from "react-router-dom";
import {useContext, useState, useEffect, useCallback} from "react";
import { Context } from "../../store";
import logoLightIcon from "../../assets/images/logo-dark.svg";
import logoDarkIcon from "../../assets/images/logo-dark.svg";
import burgerDarkIcon from "../../assets/images/burgerDark.svg";
import burgerLightIcon from "../../assets/images/burgerLight.svg";
import avatarPlaceholder from "../../assets/images/key_yellow.svg";
import Icon_Wallet from "../../assets/images/icon-wallet.svg";
import "./style.scss";
import { NAVIGATION_LIST } from "../../constant/navigation";
import { setActiveNavLink } from "../../utils";
import useCommon from "../../hooks/useCommon";
import useHandleModal from "../../hooks/dom/useHandleModal";
import useFaucet from "hooks/blockchain/useFaucet";
import useWindowDimensions from "hooks/useWidowDimensions";
import {useAppContext} from "../../contexts/appContext";
import {useTokenContext} from "../../contexts/tokenContext";
import {useWeb3Modal, useWeb3ModalAccount} from "@web3modal/ethers/react";

export default function HeaderBar({ setIsAccountOpen, isAccountOpen }) {
  const [{ theme }] = useContext(Context);
  const { balances } = useAppContext();
  const { address, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { hasUnclaimedTresr, claimTresr } = useTokenContext();
  const [showSidebar, setShowSidebar] = useState(false);
  const faucet = useFaucet();
  const handleToggleFaucet = () => toggleFaucet(!showFaucet);
  const [showFaucet, toggleFaucet] = useState(false);
  const handleTransactionLoadingModal = useHandleModal();
  const isProductionPreview = process.env.REACT_APP_PREVIEW_SITE === 'true';

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions?.width <= 1000;

  const navigate = useNavigate();
  const { americanFormatNumber } = useCommon();
  const balanceAvax = americanFormatNumber(balances.balanceAvax, 3);
  const titleWalletAddress = `${address?.substr(
    0,
    6
  )}...${address?.substr(-4)}`;

  const onGetTresr = () => {
    handleTransactionLoadingModal.open();
    faucet.getTRESR().finally(() => handleTransactionLoadingModal.close());
  };
  const onGetSmrtr = () => {
    handleTransactionLoadingModal.open();
    faucet.getSMRTR().finally(() => handleTransactionLoadingModal.close());
  };
  const onGetTresrLP = () => {
    handleTransactionLoadingModal.open();
    faucet.getTRESRLP().finally(() => handleTransactionLoadingModal.close());
  };
  const onGetSmrtrLP = () => {
    handleTransactionLoadingModal.open();
    faucet.getSMRTRLP().finally(() => handleTransactionLoadingModal.close());
  };

  const onShowAccount = () => {
    if (!isConnected) return null;
    else setIsAccountOpen(true);
  };

  const onHideAccount = () => {
    setIsAccountOpen(false);
  };

  const onToggleAccount = () => {
    if (!isConnected) { open(); }
    else setIsAccountOpen(!isAccountOpen);
  };

  const onHome = () => navigate("/game");

  return (
    <header className={`header`}>
      {!isMobile && (
        <div className="header__wrapper desktop">
          <div className="header__nav">
            <img
              src={theme === "dark" ? logoLightIcon : logoDarkIcon}
              alt=""
              onClick={onHome}
            />
            <nav>
              {NAVIGATION_LIST?.map((link) => {
                // if (link?.path === '/game' && !token?.selected) return null

                return (
                  <NavLink
                    className={setActiveNavLink}
                    to={link.path}
                    key={link.label}
                  >
                    {link.label}
                    <div className={"header__nav--decorator"} />
                  </NavLink>
                );
              })}
            </nav>
          </div>


          {!isProductionPreview && (
          <div className="header__controls">
            <div className="header__balance--container">
              {isConnected && (
                <div className="header__balance">{`${balanceAvax} AVAX`}</div>
              )}
              <button
                className={`${isConnected ? "connected" : ""} button`}
                onMouseEnter={onShowAccount}
                onMouseLeave={onHideAccount}
                onClick={onToggleAccount}
              >
                {isConnected ? titleWalletAddress : "Connect Wallet"}
              </button>
            </div>
          </div>
          )}
        </div>
      )}
      {isMobile && (
        <div className="header__wrapper mobile">
          <div className="firstHeaderRow">
            <img
              src={theme === "dark" ? logoLightIcon : logoDarkIcon}
              alt=""
              onClick={onHome}
            />
            <div className="firstHeaderRow--controls">
            {!isProductionPreview && (
              <div className={"header__balance--container"}>
                <button
                  className={`${isConnected  ? "connected" : ""} button`}
                  style={{ background: "none" }}
                  onClick={onToggleAccount}
                >
                  {isConnected  ? (
                    <img
                      src={avatarPlaceholder}
                      className={"header__avatar"}
                      alt={""}
                    />
                  ) : (
                    <img
                      src={Icon_Wallet}
                      className={"header__avatar"}
                      alt={""}
                    />
                  )}
                </button>
              </div>
              )}
              <div
                className="burger-menu"
                onClick={() => setShowSidebar(!showSidebar)}
              >
                <img
                  src={theme === "dark" ? burgerDarkIcon : burgerLightIcon}
                  alt=""
                />
              </div>
            </div>
          </div>
          {showSidebar && (
            <div className="header__sidebar">
              <nav>
                {NAVIGATION_LIST.map((item, key) => (
                  <NavLink
                    className={setActiveNavLink}
                    to={item.path}
                    key={key}
                    onClick={() => setShowSidebar(!showSidebar)}
                  >
                    <div className="flex flex-grow">
                      <span className="mr-8"></span>
                      {item.label}
                    </div>
                  </NavLink>
                ))}
              </nav>
              {isConnected && (process.env.REACT_APP_IS_BETA_TEST === 'true' || hasUnclaimedTresr) && (
                <div className="faucet" onClick={handleToggleFaucet}>
                  { process.env.REACT_APP_IS_BETA_TEST === 'true' &&  !hasUnclaimedTresr && <div>Faucet</div> }
                  { hasUnclaimedTresr && <div>Claim $TRESR</div> }
                </div>
              )}

              {showFaucet && !hasUnclaimedTresr && (
                <div className="faucet-list">
                  <button className="button" onClick={onGetTresr}>
                    TRESR
                  </button>
                  <button className="button" onClick={onGetSmrtr}>
                    SMARTR
                  </button>
                  <button className="button" onClick={onGetTresrLP}>
                    TRESR-LP
                  </button>
                  <button className="button" onClick={onGetSmrtrLP}>
                    SMARTR-LP
                  </button>
                </div>
              )}

              {showFaucet && hasUnclaimedTresr && (
                <div className="faucet-list">
                  <button className="button" onClick={claimTresr}>
                    KEY ATTACHED TRESR
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </header>
  );
}
