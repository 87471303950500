import React, {useContext, useEffect, useState} from "react";
import "./style.scss";
import infoImg from "../../../assets/images/info_img.svg";
import infoImgDark from "../../../assets/images/info_img_dark.svg";
import { Context } from "../../../store";
import nfKeyLogo from "../../../assets/images/NFTreasure_Brand.svg";
import avaxImg from "../../../assets/images/avax_blue_img.svg";
import smrtrImg from "../../../assets/images/smrtr_img.svg";
import MarketplaceButton from "../../common/MarketplaceButton";
import Tooltip from "../../common/Tooltip";
import useGetTokenPrice from "../../../hooks/blockchain/useGetTokenPrice";
import {formatterUS} from "../../../utils";
import {useAppContext} from "../../../contexts/appContext";
import {useStakingContext} from "../../../contexts/stakingContext";

const DashboardTresrSmartrAvaxStaking = ({
  isTRESR,
  balanceLP,
  onClickStake,
  onClickUnStake,
  balanceLPStaked,
  poolShareCalc,
  communityLPStaked
}) => {
  const [{ theme }] = useContext(Context);
  const [lpLiquidity, setlpLiquidity] = useState(0);
  const { stakingBalances } = useStakingContext();
  const { getTokenPrice } = useGetTokenPrice();
  const [stakedTokens, setStakedTokens] = useState(0);
  useEffect(() => {
    const getPrice = async () => {
      const {liquidity, price} = await getTokenPrice({
        name: isTRESR ? "TRESR" : "SMRTR",
        pool: isTRESR ? '0x71ddeb079b4deded2eb6651420c816620a1d3cfd' : "0x7b7617c7b2236d7871741783cae8bcc222c2e05d",
      });
      const perc = isTRESR ?  communityLPStaked / stakingBalances.poolSizeLpTRESRAVAX : communityLPStaked / stakingBalances.poolSizeLpSMRTRAVAX;
      const stakedAmount = liquidity * perc;
      const tokenAmount = stakedAmount / 2;
      const estTokens = tokenAmount / price;
      setlpLiquidity(stakedAmount);
      setStakedTokens(estTokens);
    };
    getPrice();
  }, [stakingBalances, communityLPStaked]);
  const TVL = formatterUS(lpLiquidity, 0, 'currency');

  const isDark = theme === "dark";

  const poolLinkTRESR =
    `https://traderjoexyz.com/avalanche/pool/v1/${process.env.REACT_APP_TRESR_ADDRESS}/AVAX`;
  const poolLinkSMRTR =
    `https://traderjoexyz.com/avalanche/pool/v1/${process.env.REACT_APP_SMARTR_ADDRESS}/AVAX`;
  const analyticsLinkTRESR =
    `https://analytics.traderjoexyz.com/pairs/${process.env.REACT_APP_LP_TRESRAVAX_TOKEN_ADDRESS}`;
  const analyticsLinkSMRTR =
    `https://analytics.traderjoexyz.com/pairs/${process.env.REACT_APP_LP_SMRTRAVAX_TOKEN_ADDRESS}`;

  const poolLink = isTRESR ? poolLinkTRESR : poolLinkSMRTR;
  const analyticsLink = isTRESR ? analyticsLinkTRESR : analyticsLinkSMRTR;

  return (
    <div className="DashboardTresrSmartrAvaxStaking">
      <div className="totalPoolReward__image">
        <Tooltip
          tooltipText={`Stake ${
            isTRESR ? "$TRESR" : "$SMRTR"
          } - AVAX - LP liquidity pool tokens to increase rewards.`}
          top={0} left={-650} 
          isBottom
        >
          <img src={isDark ? infoImg : infoImgDark} alt={""} />
        </Tooltip>
      </div>

      <div className="DashboardTresrSmartrAvaxStaking__currency">
        <img src={isTRESR ? nfKeyLogo : smrtrImg} alt="" />
        {isTRESR ? "TRESR" : "SMRTR"} /
        <img src={avaxImg} alt="" />
        AVAX
      </div>

      <div className="DashboardTresrSmartrAvaxStaking__amount">{balanceLPStaked}</div>
      <div className="DashboardTresrSmartrAvaxStaking__text">
        LP tokens staked
      </div>

      <div className="DashboardTresrSmartrAvaxStaking__buttons">
        <MarketplaceButton
          isBlue
          title={`Stake ${isTRESR ? "TRESR" : "SMRTR"}-AVAX-LP`}
          onClick={onClickStake}
        />
        <MarketplaceButton
          isNormal
          title={`Unstake ${isTRESR ? "TRESR" : "SMRTR"}-AVAX-LP`}
          onClick={onClickUnStake}
        />
      </div>

      <div className="DashboardTresrSmartrAvaxStaking__info">
        <div>
          <span className="DashboardTresrSmartrAvaxStaking__info--title">
            TVL
          </span>
          <span className="DashboardTresrSmartrAvaxStaking__info--value">
            {TVL}
          </span>
        </div>
        <div>
          <span className="DashboardTresrSmartrAvaxStaking__info--title">
            Available LP
          </span>
          <span className="DashboardTresrSmartrAvaxStaking__info--value">
            {balanceLP}
          </span>
        </div>
        <div className="bg-[#93A3F830] h-[1px] w-full"></div>
        <div>
          <span className="DashboardTresrSmartrAvaxStaking__info--title">
            {isTRESR ? "% Bonus Pool from LP" : "% Bonus Pool from LP"}
          </span>
          <span className="DashboardTresrSmartrAvaxStaking__info--value">
            {poolShareCalc}%
          </span>
        </div>
        <div>
          <span className="DashboardTresrSmartrAvaxStaking__info--title">
            Community Staked LP
          </span>
          <span className="DashboardTresrSmartrAvaxStaking__info--value">
            {formatterUS(communityLPStaked)}
          </span>
        </div>
        <div>
          <span className="DashboardTresrSmartrAvaxStaking__info--title">
            Est. Staked { isTRESR ? 'TRESR' : 'SMRTR' }
          </span>
          <span className="DashboardTresrSmartrAvaxStaking__info--value">
            {formatterUS(stakedTokens)}
          </span>
        </div>
      </div>

      <div className="DashboardTresrSmartrAvaxStaking__actions">
        <a href={poolLink} target="_blank" rel="noreferrer">
          <span>Add Liquidity</span>
        </a>
      </div>
    </div>
  );
};

export default DashboardTresrSmartrAvaxStaking;
