export const types = {
  SET_TOKENS_COUNT: "SET_TOKENS_COUNT",
  SET_PENDING_LOADER: "SET_PENDING_LOADER",
  SET_CHEST_MODAL: "SET_CHEST_MODAL",
  SET_ACCOUNT_BANNER_IMAGE: "SET_ACCOUNT_BANNER_IMAGE",

  //LOADER
  SET_LOADER: "SET_LOADER",
  SET_COMPONENT_LOADER: "SET_COMPONENT_LOADER",

  //ALERT
  SET_ALERT: "SET_ALERT",

  //TIMESTAMP
  SET_TIMESTAMP: "SET_TIMESTAMP",

  //THEME
  SET_THEME: "SET_THEME",

  //SCALE
  SET_SCALE: "SET_SCALE",

  //BALANCE
  SET_TRANSANCTION_HASH: "SET_TRANSANCTION_HASH",
};
