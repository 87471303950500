import React, {useCallback, useContext, useEffect, useState} from "react";
import "./style.scss";
import { Context } from "../../../store";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import OnStakeTRESRModal from "../DashboardModals/OnStakeTRESRModal";
import OnUnstakeTRESRModal from "../DashboardModals/OnUnstakeTRESRModal";
import infoImg from "../../../assets/images/info_img.svg";
import infoImgDark from "../../../assets/images/info_img_dark.svg";
import MarketplaceButton from "../../common/MarketplaceButton";
import Tooltip from "../../common/Tooltip";
import { formatterUS } from "../../../utils";
import { APPROVE_STATUS_TEXT } from "../../../constant/approveLoadingModal";
import ApproveLoadingModal from "../../common/Modals/ApproveLoadingModal";
import { ClipLoader } from "react-spinners";
import {useAppContext} from "../../../contexts/appContext";
import {useStakingContext} from "../../../contexts/stakingContext";
import {useRewardsContext} from "../../../contexts/rewardsContext";

export default function DashboardVotingEscrow({
  onUnstakeTresr,
  onclaimVeTresr,
}) {
  // console.log("[DashboardVotingEscrow/index.js] rendering...");
  const [{ theme }, ACTION] = useContext(Context);
  const { user, balances } = useAppContext();
  const { rewardsBalances } = useRewardsContext();
  const { stakingBalances, approveTRESR, stakeTresr } = useStakingContext();
  const [stake, setStake] = useState("");
  const [unstake, setUnstake] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [flag, setFlag] = useState(false);

  const isDark = theme === "dark";

  const handleStakeModal = useHandleModal();
  const handleUnstakeModal = useHandleModal();
  const handleApproveStakingTresrModal = useHandleModal();

  const balanceEstDailyVeTRESR = formatterUS(
    stakingBalances.balanceTresrRewardsPerSec * 60 * 60 * 24
  );

  const onChangeStake = (event) =>
    !isNaN(event?.target?.value) && setStake(event?.target?.value);
  const onChangeUnstake = (event) =>
    !isNaN(event?.target?.value) && setUnstake(event?.target?.value);

  const onApproveTresr = useCallback(() => {
    handleStakeModal.close();
    handleApproveStakingTresrModal.open();

    approveTRESR(stake, 'stake')
      .then(() => setIsApproved(true))
      .catch((err) => {
        console.log(err)
        setIsApproved(false);
        handleApproveStakingTresrModal.close();
        return null;
      });
  }, [stake, approveTRESR]);

  const onStakeClose = () => {
    handleStakeModal.close();
    setStake("");
  };
  const onUnstakeClose = () => {
    handleUnstakeModal.close();
    setUnstake("");
  };
  const onStake = () => {
    onStakeClose();
    stakeTresr(stake).finally(() => {
      handleApproveStakingTresrModal.close();
      setIsApproved(false);
    });
  };
  const onUnstake = async () => {
    onUnstakeClose();
    await onUnstakeTresr(unstake);
  };

  const onClickStake = () => {
    handleStakeModal.open();
  };

  const onClickUnStake = () => {
    handleUnstakeModal.open();
  };

  const onCloseApproveStakingTresrModal = () => {
    handleApproveStakingTresrModal.close();
    setIsApproved(false);
  };


  return (
    <div className="votingEscrow">
      <div className="w-full">
        <div className="votingEscrow__actions">
          <div className="votingEscrow__actions--title">Earn Voting Escrow</div>
          <div className="flex pl-7">
            <div className="votingEscrow__actions--count">{formatterUS(stakingBalances.balanceTresrStaked)}</div>
            <div className="w-[27px] ml-[5px] mt-6">
              {flag && (
                <ClipLoader size={20} color={isDark ? "#fff" : "#4964fe80"} />
              )}
            </div>
          </div>
          <div className="votingEscrow__actions--subtitle">
            Total $TRESR staked
          </div>

          <div className="votingEscrow__actions--buttons">
            <MarketplaceButton
              isBlue
              title="Stake TRESR"
              onClick={onClickStake}
            />
            <MarketplaceButton
              isNormal
              title="Unstake TRESR"
              onClick={onClickUnStake}
            />
          </div>
        </div>
        <div className="votingEscrow__info mt-10">
          <ul>
            <li>
              <span className="votingEscrow__info__label">
                Available $TRESR
              </span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(balances.balanceTresr)}
                </span>
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {flag && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )}
                </div>
              </div>
            </li>
            <li>
              <span className="votingEscrow__info__label">
                Est. Daily veTRESR
              </span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {balanceEstDailyVeTRESR}
                </span>
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {flag && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )}
                </div>
              </div>
            </li>
            <li>
              <div className="bg-[#93A3F830] h-[1px] w-full"></div>
            </li>
            <li>
              <span className="votingEscrow__info__label">
                veTRESR Total Supply
              </span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(stakingBalances.balanceTresrStakedAll)}
                </span>
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {flag && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full">
        <div className="totalPoolReward__image">
          <Tooltip
            tooltipText="More veTRESR = better Bonus Rewards, while making it easier to open Treasure Boxes. veTRESR also boosts your voting power in key protocol votes. You must claim your veTRESR to realize its benefits. See whitepaper for more details."
            top={0}
            left={-650}
            isBottom
          >
            <img src={isDark ? infoImg : infoImgDark} alt={""} />
          </Tooltip>
        </div>

        <div className="votingEscrow__actions max-sm:mt-4">
          <div className="votingEscrow__actions--title">Unclaimed veTRESR</div>
          <div className="flex pl-7">
            <div className="votingEscrow__actions--count">
              {formatterUS(stakingBalances.balanceUnclaimedVeTresr)}
            </div>
            <div className="w-[27px] ml-[5px] mt-6">
              {flag && (
                <ClipLoader size={20} color={isDark ? "#fff" : "#4964fe80"} />
              )}
            </div>
          </div>
          <div className="votingEscrow__actions--subtitle">
            Available veTRESR to claim
          </div>

          <div className="votingEscrow__actions--buttons">
            <MarketplaceButton
              isNormal
              title="Claim veTRESR"
              onClick={() => onclaimVeTresr()}
            />
          </div>
        </div>
        <div className="votingEscrow__info mt-5">
          <ul>
            <li>
              <span className="votingEscrow__info__label">Claimed veTRESR</span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(balances.balanceVeTresr)}
                </span>
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {flag && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )}
                </div>
              </div>
            </li>
            <li>
              <span className="votingEscrow__info__label">veTRESR/hour</span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(rewardsBalances.veTresrPerHour)}
                </span>
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {flag && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )}
                </div>
              </div>
            </li>
            <li>
              <span className="votingEscrow__info__label">Max veTRESR</span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(stakingBalances.maxVeTresr)}
                </span>
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {flag && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )}
                </div>
              </div>
            </li>

            <li>
              <span className="votingEscrow__info__label">veTRESR Probability Boost</span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(100 * (208 - stakingBalances.daysToMaxVeTresr) / 208, 2)}%
                </span>
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {flag && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )}
                </div>
              </div>
            </li>
            <li>
              <span className="votingEscrow__info__label">Days to Max veTRESR Boost</span>
              <div className="flex">
                <span className="votingEscrow__info__value">
                  {formatterUS(stakingBalances.daysToMaxVeTresr)}
                </span>
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {flag && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {handleStakeModal.isActive && (
        <OnStakeTRESRModal
          isOpen={handleStakeModal.isActive}
          onClose={onStakeClose}
          onChangeStake={onChangeStake}
          onStake={onApproveTresr}
          onStakeClose={onStakeClose}
          stake={stake}
          setStake={setStake}
          balance={balances.balanceTresr}
          title="Stake $TRESR"
          description="Are you sure to stake this token? This action cannot be undone."
          placeholder="0.000 TRESR"
        />
      )}
      {handleUnstakeModal.isActive && (
        <OnUnstakeTRESRModal
          onClose={onUnstakeClose}
          isOpen={handleUnstakeModal.isActive}
          onChangeUnstake={onChangeUnstake}
          onUnstake={onUnstake}
          onUnstakeClose={onUnstakeClose}
          unstake={unstake}
          setUnstake={setUnstake}
          balance={stakingBalances.balanceTresrStaked}
          title="UNSTAKE WARNING:"
          description="Confirming this transaction will reset your veTRESR balance. Press cancel to get back to the dashboard."
          placeholder="0.000 TRESR"
          marginTop={60}
        />
      )}
      {handleApproveStakingTresrModal.isActive && (
        <ApproveLoadingModal
          isOpen={handleApproveStakingTresrModal.isActive}
          statusText={APPROVE_STATUS_TEXT.APPROVE_STAKING_TRESR}
          isApproved={isApproved}
          onClose={onCloseApproveStakingTresrModal}
          onNextStep={onStake}
        />
      )}
    </div>
  );
}
