import React, { useMemo, useEffect, useState } from "react";
import "./style.scss";
import { useCountdown } from "hooks/useCountdown";
import {useTokenContext} from "../../../contexts/tokenContext";

const KeyTimer = ({ isActive, size, token }) => {
  const [timeToTokenAvailable, setTimeToTokenAvailable] = useState(0);
  const { refreshTokenList } = useTokenContext();
  const {
    hours,
    minutes,
    seconds,
    days,
  } = useCountdown(timeToTokenAvailable);

  useEffect(() => {
    if (token) {
      const newTime = token.timeToTreasureExpiration * 1000 + Date.now();
      setTimeToTokenAvailable(newTime);
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!token || token.tierTresr === 0) {
      return
    }

    if (Number(hours) === 0 && Number(minutes) === 0 && Number(seconds) === 0 && Number(days) === 0) {
      setTimeout(() => refreshTokenList(), 6000);
    }
  }, [hours, minutes, seconds, days, token, refreshTokenList]);

  return useMemo(
    () => (
      <>
      {size === 'small' && (
      <div
        className={`smallKeyTimer ${isActive ? "smallKeyTimer__isActive" : ""}`}
      >
        <div>{days > 0 ? days : "00"}</div>
        <div>:</div>
        <div>{hours > 0 ? hours : "00"}</div>
        <div>:</div>
        <div>{minutes > 0 ? minutes : "00"}</div>
        <div>:</div>
        <div>{seconds > 0 ? seconds : "00"}</div>
      </div>
      )}
      {size === 'large' && (
        <div className="timer">
          <div className="timer__element">
            <div className="timer__element--count">{days > 0 ? days : "00"}</div>
            <div className="timer__element--label">Days</div>
          </div>
          <div className="timer__element">
            <div className="timer__element--count">{hours > 0 ? hours : "00"}</div>
            <div className="timer__element--label">hrs</div>
          </div>
          <div className="timer__element">
            <div className="timer__element--count">
              {minutes > 0 ? minutes : "00"}
            </div>
            <div className="timer__element--label">mins</div>
          </div>
          <div className="timer__element">
            <div className="timer__element--count">
              {seconds > 0 ? seconds : "00"}
            </div>
            <div className="timer__element--label">secs</div>
          </div>
          <div className="w-[40px] -mr-[50px] ml-[5px] mt-2">
          </div>
        </div>
        )}
        </>
    ),
    [days, hours, minutes, seconds, timeToTokenAvailable, size] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

export default KeyTimer;
