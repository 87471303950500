import React from 'react';
import ModalLayout from "../index";
import './style.scss'
import {useParams} from "react-router-dom";
import {NFKEY_COLLECTION_ADDRESS} from "../../../../constant/blockchain";

const ExpandedNFTView = ({isOpen, onClose, currentToken}) => {
    return (
        <ModalLayout
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={'750px'}
            maxHeight={'fit-content'}
            padding={'0px'}
            height={'fit-content'}
            withCrossIcon
            isFullModalBg
            isCloserView
        >
            <div className='expandedNFTView'>
                <img src={currentToken.image} alt={`Token`}/>
            </div>
        </ModalLayout>
    );
};

export default ExpandedNFTView;
