import {types} from "../types";

export const initialStateTheme = {
    theme: localStorage.getItem('theme') || 'dark',
};

export const reducerTheme = {
    [types.SET_THEME]: (state, theme) => ({
        ...state,
        theme,
    }),
};
