import "../style.scss";
import { useWeb3Modal } from '@web3modal/ethers/react';

export default function ConnectWallet({
  isLoading,
  isNotConnected,
  isMobile,
}) {
  const { open } = useWeb3Modal();
  return (
    <div className="homePage__info">
      {!isLoading && (
        <div className="homePage__info-buttonBlock">
          <button
            className={isNotConnected ? "button w-[320px]" : "button"}
            onClick={() => open()}
          >
            {isNotConnected
              ? isMobile
                ? "Connect Wallet"
                : "Connect Wallet and Mint Your Key"
              : "Connect Wallet"}
          </button>
        </div>
      )}
    </div>
  );
}
