import { useContext, useEffect, useState } from "react";
import styles from "components/Loaders/FullPageLoader/styles.module.scss";
import { Context } from "../../store";
import "./style.scss";
import ConnectWallet from "./ConnectWallet";
import HomeZoneListNew from "./HomeZoneListNew";
import SingleNFTFooter from "../Dashboard/NFTFooter";
import {
  MINT_KEY_LEVEL_DEFAULT,
  MINT_KEY_LEVEL_LIST,
} from "../../constant/blockchain";
import useWindowDimensions from "hooks/useWidowDimensions";
import KeyArtCarousel from "./KeyArtCarousel";
import Benefits from "./Benefits";
import {useWhiteListContext} from "../../contexts/whitelistContext";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";
import {useAppContext} from "../../contexts/appContext";

export default function HomePage() {
  const [{ loader, theme }] = useContext(Context);
  const { whiteList } = useWhiteListContext();
  const { totalNFKeySupply } = useAppContext();
  const isDark = theme === "dark";
  const { isConnected } = useWeb3ModalAccount();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadedList, setIsLoadedList] = useState(true);

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions?.width <= 1000;


  const mintKeyLevelImage = whiteList.details.level
    ? MINT_KEY_LEVEL_LIST[whiteList.details.level] || MINT_KEY_LEVEL_DEFAULT
    : MINT_KEY_LEVEL_DEFAULT;

  const isProductionPreview = process.env.REACT_APP_PREVIEW_SITE === 'true';
  
  const animationCallback = () => {
    setTimeout(() => {
      setIsLoadedList(true);
      setIsLoading(false);
    }, 1);
    setIsLoading(true);
  };

  return (
    <>
      <section className="homePage">
        <div className="homePage__title">MINT A <div className="homePage__break-mob"/>TREASURE KEY</div>
        <p className="homePage__styled-title">UNLOCK REWARDS</p>
        <div className="homePage__tagline">PLAY DEFI ON<div className="homePage__break-mob"/><div className="homePage__tagline--icon" />AVALANCHE</div>
        {isProductionPreview && (<p className="homePage__prod-preview-notice">Reserve your mint spot below, then jump in Discord for latest announcements.</p>)}
        <div
          className={`homePage__mintBlock ${
            isLoading ? "homePage__mintBlock--isLoading" : ""
          }`}
        >
          <div className="homePage__connectBlock">
            <div className="homePage__keyArtContainer">
              <div className="homePage__keyArtContainer--imageContainer">
                <img
                  src={mintKeyLevelImage}
                  alt="NFTreasure key"
                />
                {totalNFKeySupply !== undefined && (
                  <p className="homePage__subtitle">{totalNFKeySupply} / 12,000 minted</p>
                )}
              </div>
              {!loader?.isActive && !isConnected && !isMobile && (
                <div className="flex flex-col justify-center">
                  <div className="w-[450px] h-[72px] homePage__info-text text-left">
                    Minting a Treasure Key NFT unlocks Defi Rewards, gives
                    you access to e-commerce discounts, affiliate commissions,
                    the Keyholder's Lounge, and more.
                  </div>
                  {!isProductionPreview && (<ConnectWallet
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    setIsLoadedList={setIsLoadedList}
                    isNotConnected
                  />)}
                  {isProductionPreview && (
                    <a 
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdVJc45PvUWP5i-NFO6sZkO032cHwPI--RCMh0BLiX6ii5ACw/viewform"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="button">RESERVE YOUR SPOT</button>
                  </a>)}
                </div>
              )}
            </div>
          </div>
          {(!loader?.isActive && isMobile && !isConnected) ? (
            <p className="homePage__subtitle">Connect your Wallet</p>
          ) : null}
          {loader?.isActive ? (
            <div
              className="homePage__zoneloader"
              style={isDark ? { color: "#ECF1F9" } : { color: "#000000" }}
            >
              <div className="homePage__zoneloader__title">
                Take a deep breath
              </div>
              <div className="homePage__zoneloader__content">
                Checking Your Wallet for Whitelist Placement and Key Level...
              </div>
              <div
                className={`${styles.loaderWrap} ${styles[`opacityLevel_0`]}`}
                style={{
                  width: "100px",
                  height: "100px",
                  position: "relative",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, 0)",
                  marginTop: "32px",
                }}
              >
                <div className={`${styles["la-ball-atom"]} ${styles["la-3x"]}`}>
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          ) : null}
          {isConnected && !loader?.isActive && (
            <HomeZoneListNew
              isLoadedList={isLoadedList}
              animationCallback={animationCallback}
            />
          )}
        </div>
        {!loader?.isActive && isMobile && !isConnected && (
          <div className="homePage__mobile-connect">
            <p className="homePage__info-text">
              You need to connect your wallet first to Mint the Treasure Key
              and start earning $TRESR
            </p>
            <center>
            {!isProductionPreview && (<ConnectWallet
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setIsLoadedList={setIsLoadedList}
                isNotConnected
                isMobile
              />)}
              {isProductionPreview && (
                    <a 
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdVJc45PvUWP5i-NFO6sZkO032cHwPI--RCMh0BLiX6ii5ACw/viewform"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="button">RESERVE YOUR SPOT</button>
                  </a>)}
            </center>
          </div>
        )}
        <KeyArtCarousel />
        <Benefits />
      </section>
      <SingleNFTFooter />
    </>
  );
}
