import { Context } from "../../../store";
import {useCallback, useContext, useEffect, useState} from "react";
import "./style.scss";
import MarketplaceButton from "../../common/MarketplaceButton";
import infoImg from "../../../assets/images/info_img.svg";
import infoImgDark from "../../../assets/images/info_img_dark.svg";
import Tooltip from "../../common/Tooltip";
import { formatterUS } from "../../../utils";
import useHandleNFT from "../../../hooks/blockchain/useHandleNFT";
import KeyRewardsDetails from "./KeyRewardsDetails";
import { ClipLoader } from "react-spinners";
import refreshIcon from "assets/images/refresh.svg";
import {useTokenContext} from "../../../contexts/tokenContext";
import {useRewardsContext} from "../../../contexts/rewardsContext";
import {PubSubEvents} from "../../../constant/events";
import PubSub from 'pubsub-js';
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

export default function DashboardTotalPoolReward({
  onClaimAll,
}) {
  const [{ theme }] = useContext(Context);
  const { selectedToken, tokenList, stakedTokenList } = useTokenContext();
  const { rewardsBalances, refreshRewardsBalances } = useRewardsContext();
  const { isConnected } = useWeb3ModalAccount();

  const [ownTokenRewardList, setOwnTokenRewardList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [flag, setFlag] = useState(false);

  const handleNFT = useHandleNFT();

  const isDark = theme === "dark";
  const onClickClaimAllRewards = () => onClaimAll();
  const onToggleDetails = async () => {
    setIsOpen(!isOpen);

    // FIXME: why fetch detail in different places
  };

  useEffect(() => {
    if (stakedTokenList?.length) {
      const tokenIdList = stakedTokenList?.map((item) => +item?.tokenId);

      if (tokenIdList?.length) {
        // baseReward for each key
        handleNFT.calcRewardByTokens(tokenIdList).then((res) => {
          setOwnTokenRewardList(
            tokenIdList?.map((item, key) => ({
              tokenID: item,
              reward: res[key] ? res[key] : 0,
            }))
          );
        });
      }
    }
  }, [
    isConnected,
    stakedTokenList,
    selectedToken?.level,
    selectedToken?.tierTresr,
    rewardsBalances,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = useCallback(() => {
    PubSub.publish(PubSubEvents.BALANCE_CHANGED);
  }, [])

  return (
    <div className={`totalPoolReward ${isOpen ? "totalPoolReward__open" : ""}`}>
      <div className="totalPoolReward__image">
        <Tooltip
          tooltipText="Total Pool Rewards combines all Treasure Keys Rewards and Bonus Pool Rewards"
          isBottom
          top={0}
          left={-650}
        >
          <img src={isDark ? infoImg : infoImgDark} alt={""} />
        </Tooltip>
      </div>
      <div
        className="absolute left-4 top-4 cursor-pointer"
        onClick={refresh}
      >
        <Tooltip tooltipText="Update" top={-40} width={100}>
          <img src={refreshIcon} alt="" />
        </Tooltip>
      </div>

      <div className="totalPoolReward__info">
        <div className="totalPoolReward__info--title">Total Rewards</div>
        <div className="flex pl-7">
          <div
            className="totalPoolReward__info--count"
            onClick={onToggleDetails}
          >
            {formatterUS(rewardsBalances.balanceUnclaimedTotalRewards)}
            <div
              className={`totalPoolReward__info--arrow ${
                isOpen ? "totalPoolReward__info--arrow__open" : ""
              }`}
            />
          </div>
          <div className="w-[27px] mt-5 ml-[5px]">
            {flag && (
              <ClipLoader size={20} color={isDark ? "#fff" : "#4964fe80"} />
            )}
          </div>
        </div>

        <div
          className={`totalPoolReward__details ${
            isOpen ? "totalPoolReward__details--open" : ""
          }`}
        >
          {/*<div className='totalPoolReward__details--block'>*/}
          {/*    <span className='totalPoolReward__details--text'>Daily Bonus Rewards</span>*/}
          {/*    <span className='totalPoolReward__details--value'>{formatterUS(balanceBonusAll, 5)}</span>*/}
          {/*</div>*/}
          {!!ownTokenRewardList?.length &&
            ownTokenRewardList?.map((item, key) => (
              <KeyRewardsDetails token={item} key={key} />
            ))}
          {
            <div className="totalPoolReward__details--block">
              <span className="totalPoolReward__details--text">
                Bonus Rewards
              </span>
              <span className="totalPoolReward__details--value">
                {formatterUS(rewardsBalances.balanceUnclaimedBonusRewards)}
              </span>
            </div>
          }
        </div>

        <div className="totalPoolReward__info--subtitle">
          Available $TRESR to claim
        </div>
      </div>
      <div className="totalPoolReward__actions">
        <MarketplaceButton
          onClick={onClickClaimAllRewards}
          title="Claim All Rewards"
          isBlue
        />
      </div>
    </div>
  );
}
