import React, {createContext, useEffect} from 'react';
import PubSub from "pubsub-js";
import {PubSubEvents} from "../constant/events";
import useHandleContracts from "../hooks/blockchain/useHandleContracts";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

const BlockListenerContext = createContext({});

export const BlockListenerContextProvider = ({ children }) => {
  const { address, isConnected } = useWeb3ModalAccount();

  const {
    contractTresrCoin,
    contractLpCoinSMRTRRAVAX,
    contractLpCoinTRESRAVAX,
    contractSmarterCoin,
    contractVeTresr,
    contractNFKey,
    contractNFKeyStaking,
    contractPrizeSeason,
  } = useHandleContracts();
  const emitAfterDelay = (func) => {
    setTimeout(func, 3000);
  }

  useEffect(() => {
    if (!isConnected || !contractTresrCoin) {
      return;
    }

    const ev = (from, to) => {
      if (from === address || to === address){
        emitAfterDelay(()=> PubSub.publish(PubSubEvents.BALANCE_CHANGED));
      }
    };


    contractTresrCoin.on('Transfer', ev);
    contractLpCoinSMRTRRAVAX.on('Transfer', ev);
    contractLpCoinTRESRAVAX.on('Transfer', ev);
    contractSmarterCoin.on('Transfer', ev);
    contractVeTresr.on('Mint', ev);
    contractVeTresr.on('Burn', ev);

    // const mintEv = (from, to) => {
    //   if (from === address || to === address) {
    //     emitAfterDelay(()=> PubSub.publish(PubSubEvents.NFKEYS_CHANGED.event, PubSubEvents.NFKEYS_CHANGED.args([id]))));
    //   }
    // };
    const staked = (owner, bigId) => {
      if (owner === address) {
        const id = Number(bigId);
        emitAfterDelay(()=> PubSub.publish(PubSubEvents.NFKEYS_CHANGED.event, PubSubEvents.NFKEYS_CHANGED.args([id])));
      }
    }

    // contractNFKey.on('Transfer', mintEv);
    contractNFKeyStaking.on('Staked', staked);
    contractNFKeyStaking.on('Unstaked', staked);

    const batchMintEv = (owner) => {
      if (owner === address) {
        emitAfterDelay(()=> PubSub.publish(PubSubEvents.NFKEYS_BATCH_MINT));
      }
    }
    contractNFKey.on('BatchMinted', batchMintEv);


    const keyUpgraded = (owner, tokenIds) => {
      if (owner === address) {
        const ids = tokenIds.map((bigId) => Number(bigId));
        emitAfterDelay(()=> PubSub.publish(PubSubEvents.NFKEYS_CHANGED.event, PubSubEvents.NFKEYS_CHANGED.args(ids)));
      }
    }

    contractNFKey.on('BulkUpgraded', keyUpgraded);

    const chestOpened = (owner, hexKeyIds, successes) => {
      if (owner === address) {
        const keyIds = hexKeyIds.map(v => Number(v));
        emitAfterDelay(() => {
          PubSub.publish(PubSubEvents.NFKEYS_CHANGED.event, PubSubEvents.NFKEYS_CHANGED.args(keyIds));
          PubSub.publish(PubSubEvents.CHESTS_OPENED.event, PubSubEvents.CHESTS_OPENED.args(keyIds, successes));
        });
      }
    }
    contractNFKeyStaking.on('OpenedBatch', chestOpened);

    return () => {
      contractTresrCoin.off('Transfer', ev);
      contractLpCoinSMRTRRAVAX.off('Transfer', ev);
      contractLpCoinTRESRAVAX.off('Transfer', ev);
      contractSmarterCoin.off('Transfer', ev);
      contractVeTresr.off('Mint', ev);
      contractVeTresr.off('Burn', ev);
      // contractNFKey.off('Transfer', mintEv);
      contractNFKeyStaking.off('Staked', staked);
      contractNFKeyStaking.on('Unstaked', staked);
      contractNFKey.off('KeyUpgraded', keyUpgraded);
      contractNFKeyStaking.off('OpenedBatch', chestOpened);
      contractNFKey.off('BatchMinted', batchMintEv);
    };
  }, [
    address,
    isConnected,
    contractTresrCoin,
    contractLpCoinSMRTRRAVAX,
    contractLpCoinTRESRAVAX,
    contractSmarterCoin,
    contractVeTresr,
    contractNFKey,
    contractNFKeyStaking,
  ]);

  useEffect(() => {
    if (!contractPrizeSeason || !address || !isConnected) {
      return;
    }

    const prizeResultReceived = (player, tokenIDs, won, prizeIDs) => {
      if (player !== address) {
        return;
      }

      const prizes = [];
      for (let i = 0; i < tokenIDs.length; i++) {
        const tokenId = Number(tokenIDs[i]);
        const prizeId = Number(prizeIDs[i]);
        prizes.push({
          tokenId,
          prizeId,
          won: won[i]
        });

      }
      PubSub.publish(PubSubEvents.PRIZE_RESULT.event, PubSubEvents.PRIZE_RESULT.args(prizes));

    };
    contractPrizeSeason.on('PrizeSeasonResult', prizeResultReceived);
  }, [
    contractPrizeSeason,
    address,
    isConnected,
  ]);

  const value = {};

  return (
    <BlockListenerContext.Provider value={value}>
      {children}
    </BlockListenerContext.Provider>
  );
};
