import React, { useContext } from "react";
import heartImg from "../../../../assets/images/heart_transparent.svg";
import heartImgColored from "../../../../assets/images/heart_home.svg";
import "./style.scss";
import plus from "../../../../assets/images/plus.svg";
import plusDark from "../../../../assets/images/plus_blue.svg";
import minus from "../../../../assets/images/minus.svg";
import minusDark from "../../../../assets/images/minus_blue.svg";
import { Context } from "../../../../store";
import useWindowDimensions from "../../../../hooks/useWidowDimensions";

const ZoneItem = ({
  title,
  price,
  amount,
  setAmount,
  onChecked,
  withInput = false,
  isDisabled = true,
  max = 40,
  zoneOpen
}) => {
  const [{ theme }] = useContext(Context);
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions?.width <= 1000;
  const isDark = theme === "dark";

  const onIncrease = () => {
    if (!zoneOpen) {
      return;
    }

    if (isDisabled) {
      onChecked();
    }

    if (amount + 1 <= max) {
      setAmount(amount + 1);
    }
  };

  const onDecrease = () => {
    if (isDisabled) return;
    if (amount <= 0) return;
    if (amount === 1) {
      onChecked();
    }
    setAmount(amount - 1);
  };

  const activate = () => {
    if (!zoneOpen) {
      return;
    }

    if (amount !== 0) {
      setAmount(0);
      onChecked();
      return;
    }
    onIncrease();
  }

  return (
    <div>
      <div className="zoneItem">
        <div className="zoneItem__nameWrapper" onClick={activate} style={{
          cursor: zoneOpen ? 'pointer' : undefined
        }}>
          <img
            src={isDisabled ? heartImg : heartImgColored}
            alt={""}
            className="zoneItem__heart"
          />
          <p
            className={`zoneItem__name ${
              isDisabled && "zoneItem__name--disabled"
            }`}
          >
            {title}
          </p>
          {!!(isMobile && !withInput) && (
            <p
              className={`zoneItem__key ${
                isDisabled && "zoneItem__key--disabled"
              }`}
            >
              1 Key
            </p>
          )}
        </div>

        <div className="zoneItem__priceWrapper">
          {!isMobile && (
            <>
              {withInput ? (
                <button disabled={!zoneOpen} className="zoneItem__button" style={{
                  visibility: !zoneOpen ? 'hidden' : undefined
                }}>
                  <div onClick={onDecrease}>
                    <img src={isDark ? minus : minusDark} alt={""} />
                  </div>
                  <span>{amount}</span>
                  <div onClick={onIncrease}>
                    <img src={isDark ? plus : plusDark} alt={""} />
                  </div>
                </button>
              ) : (
                <p
                  className={`zoneItem__key ${
                    isDisabled && "zoneItem__key--disabled"
                  }`}
                >
                  1 Key
                </p>
              )}
            </>
          )}
          <p className="zoneItem__price">{price}</p>
        </div>
      </div>

      {!!(isMobile && withInput) && (
        <button disabled={!zoneOpen} className="zoneItem__button" style={{
          visibility: !zoneOpen ? 'hidden' : undefined
        }}>
          <div onClick={onDecrease}>
            <img src={isDark ? minus : minusDark} alt={""} />
          </div>
          <span>{amount}</span>
          <div onClick={onIncrease}>
            <img src={isDark ? plus : plusDark} alt={""} />
          </div>
        </button>
      )}
    </div>
  );
};

export default ZoneItem;
