export const customStyles = {
    content: {
        background: '#ECF1F9',
        boxShadow: '0px -4px 15px rgba(86, 111, 254, 0.15)',
        borderRadius: '24px 24px 0px 0px',
        border: 'none',
        position: 'absolute',
        padding: '24px',
        boxSizing: 'border-box',
        overflow: 'scroll',
        width: '100%',
        height: '90%',
        left: '50%',
        right: '50%',
        transform: 'translate(-50%)',
        bottom: '0',
        top: 'auto',
    },
    overlay: {
        background: 'rgba(251, 251, 251, 0.6)',
        borderBottom: '1px solid rgba(3, 7, 16, 0.1)',
        backdropFilter: 'blur(20px)'
    }
}
