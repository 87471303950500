import React from "react";
import arrow from "../../../../../assets/images/arrow_right_white.svg";
import crossWhite from "../../../../../assets/images/cross_keyList_white.svg";
import arrowDown from "../../../../../assets/images/arrow.svg";

import { formatterUS } from "../../../../../utils";
import useWindowDimensions from "hooks/useWidowDimensions";

const TreasureBoxItem = ({ token, onDeleteItem, onAddItem, onDeleteItems, index }) => {
  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 750;
  if (token?.flag)
    return (
      <div className={`upgradeAllKeysModal__list--item `}>
        <div className="upgradeAllKeysModal__wrapper">
          <img
            width={56}
            height={56}
            src={token?.image}
            alt=""
            className="upgradeAllKeysModal__image"
          />
          <div className="upgradeAllKeysModal__name--wrapper">
            <span className="upgradeAllKeysModal__name">
              Treasure Box #{token?.tokenId}
            </span>
            <div className="upgradeAllKeysModal__level">
              <span>Tier {token?.tierTresr}</span>
              <img width={14} height={9} src={arrow} alt="to" />
              <span>Tier {+token?.tierTresr + 1}; Probability {token.openChestProbability.toFixed(1)}% </span>
            </div>
            {isMobile && (
              <span className="modal__text text-[16px] mt-1 text-[var(--currencyInputLabel)]">
                {formatterUS(token.unlockChestAmount)} TRESR
              </span>
            )}
          </div>
        </div>
        <div className="upgradeAllKeysModal__wrapperRight">
          <div style={{
            display: 'flex',
            gap: "15px"
          }}>
            <img
              src={arrowDown}
              alt={"delete all lower"}
              className="cursor-pointer"
              onClick={() => onDeleteItems(index)}
            />
            <img
              src={crossWhite}
              alt={"delete"}
              className="cursor-pointer"
              onClick={() => onDeleteItem(token?.tokenId)}
            />
          </div>
          {!isMobile && <span>{formatterUS(token.unlockChestAmount)} TRESR</span>}
        </div>
      </div>
    );
  else
    return (
      <div className="upgradeAllKeysModal__list--item">
        <div className="upgradeAllKeysModal__wrapper filter brightness-[0.4]">
          <img
            width={56}
            height={56}
            src={token?.image}
            alt=""
            className="upgradeAllKeysModal__image"
          />
          <div className="upgradeAllKeysModal__name--wrapper">
            <span className="upgradeAllKeysModal__name">
              Treasure Box #{token?.tokenId}
            </span>
            <div className="upgradeAllKeysModal__level">
              { token.tierTresr !== 7 && (
                <>
                  <span>Tier {token?.tierTresr}</span>
                  <img width={14} height={9} src={arrow} alt="to" />
                  <span>Tier {+token?.tierTresr + 1}</span>
                </>
              )}
              {
                token.tierTresr === 7 && (
                  <span>Refresh Tier 7</span>
                )
              }
            </div>
            {isMobile && (
              <span className=" modal__text text-[16px] mt-1 text-[var(--currencyInputLabel)]">
                {formatterUS(token.unlockChestAmount)} TRESR
              </span>
            )}
          </div>
        </div>
        <div className="upgradeAllKeysModal__wrapperRight">
          <div
            className="cursor-pointer text-gray-400 hover:text-white"
            onClick={() => onAddItem(token?.tokenId)}
          >
            Add
          </div>
          {!isMobile && (
            <span className="filter brightness-[0.4]">
              {formatterUS(token.unlockChestAmount)} TRESR
            </span>
          )}
        </div>
      </div>
    );
};

export default TreasureBoxItem;
