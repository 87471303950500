import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {ethers, WebSocketProvider} from "ethers";
import WHITELIST_ABI from "../../abi/WhitelistUpgradeableV1.abi.json";
import NFKEY_ABI from "../../abi/NFKeyUpgradeableV1.abi.json";
import SMARTR_ABI from "../../abi/MockSmarterCoin.abi.json";
import NFKEY_STAKING_ABI from "../../abi/NFKeyStakingUpgradeableV1.abi.json";
import TRESR_ABI from "../../abi/TresrCoin.abi.json";
import VETRESR_ABI from '../../abi/veTRESR.abi.json'
import MASTER_REWARDS_ABI from "../../abi/BonusMasterPoolUpgradeableV1.abi.json";
import MAIN_BONUS_POOL_API from '../../abi/MainBonusPoolUpgradeableV1.abi.json';
import TRESR_STAKING_ABI from "../../abi/TresrStakingUpgradeableV1.abi.json";
import LP_SMRTRAVAX_STAKING_ABI from "../../abi/LP_StakingUpgradeableV1.abi.json";
import LP_TRESRAVAX_STAKING_ABI from "../../abi/LP_StakingUpgradeableV1.abi.json";
import LP_SMRTRAVAX_TOKEN_ABI from "../../abi/MockLPToken.abi.json";
import LP_TRESRAVAX_TOKEN_ABI from "../../abi/MockLPToken.abi.json";
import TRESR_FAUCET_ABI from '../../abi/MockTresrFaucet.abi.json';
import SIGNATURE_ABI from '../../abi/SignatureStorage.abi.json';
import ERC20_ABI from "../../abi/IERCFull20.abi.json";
import PRIZE_SEASON_ABI from '../../abi/PrizeSeason.abi.json';
import VIEWS_ABI from '../../abi/NFTreasureViews.abi.json';
import {useWeb3ModalAccount, useWeb3ModalProvider} from "@web3modal/ethers/react";
import {InfuraWebSocketProvider} from "ethers";
import {WEBSOCKET_RPC} from "../../constant/blockchain";

const ContractsContext = createContext({
  ethersProvider: undefined,
  contractWhitelist: undefined,
  contractWhitelistWithSigner: undefined,
  contractSignatureStorageWithSinger: undefined,
  contractNFKey: undefined,
  contractNFKeyWithSigner: undefined,
  contractSmarterCoin: undefined,
  contractSmarterCoinWithSigner: undefined,
  contractNFKeyStaking: undefined,
  contractNFKeyStakingWithSigner: undefined,
  contractTresrCoin: undefined,
  contractTresrCoinWithSigner: undefined,
  contractTresrFaucetWithSigner: undefined,
  contractVeTresr: undefined,
  contractMasterRewards: undefined,
  contractMasterRewardsWithSigner: undefined,
  contractMainBonusPool: undefined,
  contractTresrStakingCoin: undefined,
  contractTresrStakingCoinWithSigner: undefined,
  contractLpStakingTRESRAVAX: undefined,
  contractLpStakingTRESRAVAXWithSigner: undefined,
  contractLpStakingSMRTRAVAX: undefined,
  contractLpStakingSMRTRAVAXWithSigner: undefined,
  contractLpCoinTRESRAVAX: undefined,
  contractLpCoinTRESRAVAXWithSigner: undefined,
  contractLpCoinSMRTRRAVAX: undefined,
  contractLpCoinSMRTRAVAXWithSigner: undefined,
  contractERC20WithSigner: undefined,
  contractPrizeSeason: undefined,
  contractViews: undefined,
  ethersSigner: undefined,
  contractsLoaded: false,
});

const useContracts = () => useContext(ContractsContext);
export default useContracts;

export const makeContract = (name, address, abi, provider) => {
  try {
    return new ethers.Contract(
      address,
      abi,
      provider
    )
  } catch (err) {
    console.error(`Couldn't create contract for ${name}. Address ${address}`)
  }
}

export const ContractsContextProvider = ({ children }) => {
  const [prizeContract, setPrizeContract] = useState();
  const { walletProvider } = useWeb3ModalProvider();
  const { isConnected } = useWeb3ModalAccount();
  const [ethersSigner, setEthersSigner] = useState();
  const [ethersProvider, setEthersProvider] = useState();



  useEffect(() => {
    const setup = async () => {
      if (!walletProvider || !isConnected) {
        return null;
      }

      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      setEthersSigner(signer);

      const websock = new WebSocketProvider(WEBSOCKET_RPC);
      setEthersProvider(websock);
    }
    setup()
  }, [walletProvider, isConnected]);

  const contracts = useMemo(() => {
    if (!ethersProvider || !ethersSigner || !isConnected) {
      return null;
    }

    const contractWhitelist =
      makeContract(
        'whitelist',
        process.env.REACT_APP_WHITELIST_ADDRESS,
        WHITELIST_ABI,
        ethersProvider
      );
    const contractWhitelistWithSigner =
      contractWhitelist.connect(ethersSigner);

    const contractNFKey =
      makeContract(
        'nfkey',
        process.env.REACT_APP_NFKEY_ADDRESS,
        NFKEY_ABI,
        ethersProvider
      );

    const contractNFKeyWithSigner = contractNFKey.connect(ethersSigner);

    const contractSignatureStorageWithSinger = makeContract(
      'signature storage',
      process.env.REACT_APP_SIGNATURE_STORAGE_ADDRESS,
      SIGNATURE_ABI,
      ethersProvider
    ).connect(ethersSigner);

    const contractSmarterCoin = makeContract(
      'smartr',
      process.env.REACT_APP_SMARTR_ADDRESS,
      SMARTR_ABI,
      ethersProvider
    );
    const contractSmarterCoinWithSigner = contractSmarterCoin.connect(ethersSigner);

    const contractNFKeyStaking = makeContract(
      'nfkey staking',
      process.env.REACT_APP_NFKEY_STAKING_ADDRESS,
      NFKEY_STAKING_ABI,
      ethersProvider
    );

    const contractNFKeyStakingWithSigner = contractNFKeyStaking.connect(ethersSigner);
    const contractTresrCoin =
      makeContract(
        'tresr',
        process.env.REACT_APP_TRESR_ADDRESS,
        TRESR_ABI,
        ethersProvider
      );
    const contractTresrCoinWithSigner =
      contractTresrCoin.connect(ethersSigner);

    const contractTresrFaucet = process.env.REACT_APP_IS_BETA_TEST === 'true' ? makeContract(
      'tresr facuet',
      process.env.REACT_APP_TRESR_FAUCET_ADDRESS,
      TRESR_FAUCET_ABI,
      ethersProvider
    ) : undefined;

    const contractTresrFaucetWithSigner = contractTresrFaucet ?  contractTresrFaucet.connect(ethersSigner) : undefined;

    const contractVeTresr = makeContract(
      'vetresr',
      process.env.REACT_APP_VETRESR_ADDRESS,
      VETRESR_ABI,
      ethersProvider
    );

    const contractMasterRewards =
      makeContract(
        'master reward',
        process.env.REACT_APP_MASTER_REWARD_ADDRESS,
        MASTER_REWARDS_ABI,
        ethersProvider
      );
    const contractMasterRewardsWithSigner =
      contractMasterRewards.connect(ethersSigner);

    const contractMainBonusPool =
      makeContract(
        'main bonus pool',
        process.env.REACT_APP_MAIN_BONUS_POOL_ADDRESS,
        MAIN_BONUS_POOL_API,
        ethersProvider
      );

    const contractTresrStakingCoin =
      makeContract(
        'tresr staking',
        process.env.REACT_APP_TRESR_STAKING_ADDRESS,
        TRESR_STAKING_ABI,
        ethersProvider
      );
    const contractTresrStakingCoinWithSigner =
      contractTresrStakingCoin.connect(ethersSigner);

    const contractLpStakingTRESRAVAX =
      makeContract(
        'tresr avax staking',
        process.env.REACT_APP_LP_TRESRAVAX_STAKING_ADDRESS,
        LP_TRESRAVAX_STAKING_ABI,
        ethersProvider
      );
    const contractLpStakingTRESRAVAXWithSigner =
      contractLpStakingTRESRAVAX.connect(ethersSigner);

    const contractLpStakingSMRTRAVAX =
      makeContract(
        'smrtr avax staking',
        process.env.REACT_APP_LP_SMRTRAVAX_STAKING_ADDRESS,
        LP_SMRTRAVAX_STAKING_ABI,
        ethersProvider
      );
    const contractLpStakingSMRTRAVAXWithSigner =
      contractLpStakingSMRTRAVAX.connect(ethersSigner);

    const contractLpCoinTRESRAVAX =
      makeContract(
        'tresr avax lp',
        process.env.REACT_APP_LP_TRESRAVAX_TOKEN_ADDRESS,
        LP_TRESRAVAX_TOKEN_ABI,
        ethersProvider
      );
    const contractLpCoinTRESRAVAXWithSigner =
      contractLpCoinTRESRAVAX.connect(ethersSigner);

    const contractLpCoinSMRTRRAVAX =
      makeContract(
        'smartr avax lp',
        process.env.REACT_APP_LP_SMRTRAVAX_TOKEN_ADDRESS,
        LP_SMRTRAVAX_TOKEN_ABI,
        ethersProvider
      );
    const contractLpCoinSMRTRAVAXWithSigner =
      contractLpCoinSMRTRRAVAX.connect(ethersSigner);

    const contractViews =
      makeContract(
        'views',
        process.env.REACT_APP_VIEWS_ADDRESS,
        VIEWS_ABI,
        ethersProvider
      );


    const contractERC20WithSigner = (contractAddress) => {
      const contract = makeContract(
        contractAddress,
        ERC20_ABI,
        ethersProvider
      );
      return contract.connect(ethersSigner);
    };

    return {
      contractWhitelist,
      contractWhitelistWithSigner,
      contractNFKey,
      contractNFKeyWithSigner,
      contractSmarterCoin,
      contractSmarterCoinWithSigner,
      contractNFKeyStaking,
      contractNFKeyStakingWithSigner,
      contractTresrCoin,
      contractTresrCoinWithSigner,
      contractVeTresr,
      contractMasterRewards,
      contractMasterRewardsWithSigner,
      contractMainBonusPool,
      contractTresrStakingCoin,
      contractTresrStakingCoinWithSigner,
      contractLpStakingTRESRAVAX,
      contractLpStakingTRESRAVAXWithSigner,
      contractLpStakingSMRTRAVAX,
      contractLpStakingSMRTRAVAXWithSigner,
      contractLpCoinTRESRAVAX,
      contractLpCoinTRESRAVAXWithSigner,
      contractLpCoinSMRTRRAVAX,
      contractLpCoinSMRTRAVAXWithSigner,
      contractTresrFaucetWithSigner,
      contractSignatureStorageWithSinger,
      contractViews,
      contractERC20WithSigner,
      ethersSigner,
      ethersProvider,
      contractsLoaded: true
    };
  }, [ethersProvider, ethersSigner, walletProvider, isConnected]);

  useEffect( () => {
    if(!isConnected || !contracts)  {
      return;
    }
    const loadPrize = async () => {
      const isActive = await contracts.contractNFKeyStakingWithSigner.prizeSeasonActive();
      if (isActive) {
        const prizeAddress = await contracts.contractNFKeyStakingWithSigner._prizeSeasonAddress();
        const contract = makeContract('prize season', prizeAddress, PRIZE_SEASON_ABI, ethersProvider);
        setPrizeContract(contract);
      }
    };
    loadPrize();
  }, [isConnected, contracts, ethersProvider]);

  return (
    <ContractsContext.Provider value={{ ...contracts, contractPrizeSeason: prizeContract}}>
      {children}
    </ContractsContext.Provider>
  );
}
