import "../style.scss";
import React from "react";
import { useLocation } from "react-router-dom";
import TransactionLoadingModal from "../../common/Modals/TransactionLoadingModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { useNavigate } from "react-router-dom";
import {getImagePath} from "../../../constant/blockchain";
import {useWeb3Modal, useWeb3ModalAccount} from '@web3modal/ethers/react';

export default function HomeMint() {
  const { isConnected } = useWeb3ModalAccount();
  const location = useLocation();
  const handleTransactionLoadingModal = useHandleModal();
  const isGame = location?.pathname?.includes("/game");
  const navigate = useNavigate();
  const { open } = useWeb3Modal();
  const onCloseTransactionLoadingModal = () => {
    handleTransactionLoadingModal.close();
  }

  return (
    <>
      <div
        className={`${
          isGame
            ? "dashboard__data__content--form__selectedToken homePage__info--dashboard"
            : "homePage__info--container"
        }`}
      >
        {isGame && (
          <div
            className={"dashboard__data__content--form__selectedToken--wrapper"}
          >
            <img
              src={
                getImagePath(1)
              }
              alt={'Key'}
            />
          </div>
        )}

        <div
          className={`${
            isGame ? "homePage__info--container" : "homePage__info"
          }`}
        >
          <div
            className={`homePage__info-title ${
              isGame ? "homePage__info-title__dashboard" : ""
            }`}
          >
            Mint A Treasure Key
          </div>
          <div className="homePage__info-text">
            This area requires a Treasure Key. Mint one to start earning TRESR rewards, unlock prizes, and more
          </div>
          <div className="homePage__info--actions">
            <button
              className={`button ${isGame ? "button__dashboard" : ""}`}
              onClick={
                isConnected ? () => navigate("/") : open
              }
            >
              {isConnected ? "Mint" : "Connect Wallet"}
            </button>
          </div>
        </div>
      </div>


      {handleTransactionLoadingModal.isActive && (
        <TransactionLoadingModal
          isOpen={handleTransactionLoadingModal.isActive}
          onClose={onCloseTransactionLoadingModal}
        />
      )}
    </>
  );
}
