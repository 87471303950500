import ipfsImages from '../ipfs/ipfs_image_hashes.json';
import {createWeb3Modal, defaultConfig} from "@web3modal/ethers/react";

const AVALANCHE_MAINNET_PARAMS = {
    chainId: 43114,
    name: 'Avalanche C-Chain',
    currency: 'AVAX',
    explorerUrl: 'https://snowtrace.io/',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc'
}

export const AVALANCHE_TESTNET_PARAMS = {
    chainId: 43113,
    name: 'Avalanche Testnet C-Chain',
    currency: 'AVAX',
    explorerUrl: 'https://testnet.snowtrace.io/',
    rpcUrl: 'https://api.avax-test.network/ext/bc/C/rpc'
}


export const OPTIMISM_DEV_PARAMS = {
    chainId: 11155420,
    name: 'OP Sepolia',
    currency: 'ETH',
    explorerUrl: 'https://sepolia-optimistic.etherscan.io',
    rpcUrl: `https://optimism-sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
}

const projectId = process.env.REACT_APP_WALLET_CONNECT_ID;

export const AVALANCHE_NETWORK_PARAMS =
  process.env.REACT_APP_DEV === 'true' ? OPTIMISM_DEV_PARAMS :
  process.env.REACT_APP_IS_BETA_TEST === 'true' ? AVALANCHE_TESTNET_PARAMS :
  AVALANCHE_MAINNET_PARAMS

export const WEBSOCKET_RPC =
  process.env.REACT_APP_DEV === 'true' ? `wss://optimism-sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_ID}` :
  process.env.REACT_APP_IS_BETA_TEST === 'true' ? `wss://avalanche-fuji.infura.io/ws/v3/${process.env.REACT_APP_INFURA_ID}` :
    `wss://avalanche-mainnet.infura.io/ws/v3/${process.env.REACT_APP_INFURA_ID}`;

// 3. Create a metadata object
const metadata = {
    name: 'NFTREASURE',
    description: 'The NFTREASURE Defi Game',
    url: process.env.REACT_APP_WALLET_CONNECT_URL
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
    /*Required*/
    metadata,
    enableEIP6963: true,
    enableInjected: true
})

// 5. Create a Web3Modal instance
createWeb3Modal({
    ethersConfig,
    chains: [AVALANCHE_NETWORK_PARAMS],
    projectId,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
})



export const NFKEY_COLLECTION_ADDRESS = process.env.REACT_APP_NFKEY_ADDRESS

export const MAX_LEVEL = 150;
export const MAX_CHEST_TIER = 7;
export const BASE_REWARD = 0.125;

export const getImagePath = (index) => {
  if (ipfsImages[index-1]) {
    return `${process.env.REACT_APP_IPFS_ROOT}${ipfsImages[index-1].thumbHash}`;
  }
  return getImagePath(1)
}
export const MINT_KEY_LEVEL_DEFAULT = getImagePath(1);
export const MINT_KEY_LEVEL_LIST = {
    1: getImagePath(1),
    2: getImagePath(2),
    3: getImagePath(3),
    4: getImagePath(4),
    5: getImagePath(5),
    6: getImagePath(6),
    7: getImagePath(7),
    8: getImagePath(8),
    9: getImagePath(9),
    10: getImagePath(10),
}

export const calcTreasureUnlockTime = (tier) => {
    let sum = 0;
    for(let i = tier; i > 0; i--) {
        sum += ( 8 - i) * process.env.REACT_APP_TIER_DURATION_IN_SECONDS
    }
    return sum;
}

// TODO DOUBLE CHECK
export const calcTreasureUnlockCost = (level, tier) => {
    const chestTierDays = 8 - tier;
    const cost = chestTierDays * level * tier * 125 * process.env.REACT_APP_CHEST_LEVEL_CONSTANT
    return cost;
}

export const calcKeyUpgradeDelay = (level) => level * process.env.UPGRADE_COOLDOWN_IN_SECONDS;
export const calcRewards = (level, tier) => level * tier * 125 * 10 ** 15 / 86400;
