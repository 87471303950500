import {useState} from 'react';
import arrowIcon from '../../../assets/images/arrow.svg';
import './style.scss';
import {DASHBOARD_DROPDOWN_ITEMS} from "../../../constant/navigation";

export default function Dropdown({onSelect, activeItem}) {
    const [isOpen, toggleOpen] = useState(false);

    const onToggleOpen = () => toggleOpen(!isOpen);

    return (
        <div className="dropdown">
            <div className="dropdown__active" onClick={onToggleOpen}>
                <span>{activeItem?.label}</span>
                <img src={arrowIcon} alt="v" className={isOpen ? "opened" : ''}/>
            </div>
            {isOpen && (
                <div className="dropdown__list">
                    {DASHBOARD_DROPDOWN_ITEMS.map((item, key) => {
                        const onClickItem = () => {
                            onSelect(item);
                            toggleOpen(false);
                        };

                        return (
                            <div key={key} className="dropdown__list--item" onClick={onClickItem}>
                                {item?.label}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
}
