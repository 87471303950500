import { createContext, useEffect, useState } from "react";

export const TimerContext = createContext({
  timestamp: Date.now(),
});

export const TimerProvider = ({ children }) => {
  const [timestamp, setTimestamp] = useState("");
  useEffect(() => {
    const timerId = setTimeout(() => {
      setTimestamp(parseInt(Date.now() / 1000));
      //   console.log(`[TimerContext] timestamp: ${timestamp}`);
    }, 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, [timestamp]);
  return (
    <TimerContext.Provider value={{ timestamp }}>
      {children}
    </TimerContext.Provider>
  );
};
