import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import ModalLayout from "../index";
import KeyItem from "./KeyItem";
import useHandleNFT from "../../../../hooks/blockchain/useHandleNFT";
import MarketplaceButton from "../../MarketplaceButton";
import { Context } from "../../../../store";
import { compareUnixDates, sleep } from "../../../../utils";
import { formatterUS } from "../../../../utils";
import useWindowDimensions from "hooks/useWidowDimensions";
import {useAppContext} from "../../../../contexts/appContext";

const UpgradeAllKeysModal = ({
  isOpen,
  onClose,
  filterTokenList,
  onClick,
  setTokenListToUpgrade,
  handleTransactionLoadingModal,
}) => {
  const [{ balance }] = useContext(Context);
  const { balances } = useAppContext();
  const [tokenList, setTokenList] = useState([]);
  const [tokenAmountList, setTokenAmountList] = useState([]);
  const [curDelToken, setCurDelToken] = useState(-1);
  const [totalAmount, setTotalAmount] = useState(0);
  const handleNFT = useHandleNFT();
  const windowParams = useWindowDimensions();
  const isMobile = windowParams?.width <= 750;

  const isInsufficient = +totalAmount > +balances.balanceSmrtr;
  const buttonTitle = isInsufficient
    ? "Insufficient balance"
    : `Upgrade ${
        tokenList?.filter((item) => item?.flag === true)?.length || 0
      } Keys`;

  const onDeleteItem = async (id) => {
    setCurDelToken(id);
    setTokenList(
      tokenList?.map((item, key) => {
        if (item.tokenId === id) item.flag = false;
        return item;
      })
    );
    const temp = tokenList
      ?.filter((item) => item?.flag === true)
      ?.reduce((toRet, item) => toRet + item.keyUpgradeAmount, 0);

    setTotalAmount(temp);
  };

  const onAddItem = async (id) => {
    setTokenList(
      tokenList?.map((item, key) => {
        if (item.tokenId === id) item.flag = true;
        return item;
      })
    );
    const temp = tokenList
      ?.filter((item) => item?.flag === true)
      ?.reduce((toRet, item) => toRet + item.keyUpgradeAmount, 0);

    setTotalAmount(temp);
  };

  const onUpgrade = () => {
    if (tokenList?.length) {
      setTokenListToUpgrade(
        tokenList
          ?.filter((item) => item?.flag === true && item.staked)
          ?.map((item) => +item?.tokenId)
      );
      onClick(totalAmount);
    }
  };

  useEffect(() => {
    (async () => {
      // console.log(`[UpgradeAllKeysModal::useEffect1]`);
      if (isOpen && filterTokenList?.length) {
        const tokenList = filterTokenList
          ?.filter((item) => item.staked && compareUnixDates(new Date().getTime() / 1000, item.keyUpgradeDelay))
          ?.map((tokenItem) => {
            return { ...tokenItem, flag: true };
          })
        setTokenList(tokenList);
        setTotalAmount(tokenList.filter(item => item.flag).reduce((toRet, item) => toRet + item.keyUpgradeAmount, 0));
      }
    })();
  }, [filterTokenList, isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalLayout
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={"608px"}
      maxHeight={"582px"}
      padding={"32px"}
      withCrossIcon
    >
      <p className="modal__title">Upgrade All Keys</p>

      {!isMobile && (
        <div className="modal__text mt-4 text-center">
          Upgrading your Key Level helps you earn more $TRESR.
        </div>
      )}
      {!isMobile && (
        <div className="modal__text text-right text-[16px] mr-[20px]">
          {tokenList?.filter((item) => item?.flag == true)?.length} /{" "}
          {tokenList?.length}
        </div>
      )}
      <div className={`upgradeAllKeysModal__list`}>
        {tokenList?.map((item, key) => {
          if (item.flag) {
            return (
              <KeyItem
                key={key}
                token={item}
                onDeleteItem={onDeleteItem}
                onAddItem={onAddItem}
                index={item?.tokenId}
                delI={curDelToken}
              />
            );
          }
        })}
        {tokenList?.map((item, key) => {
          if (!item.flag)
            return (
              <KeyItem
                key={key}
                token={item}
                onDeleteItem={onDeleteItem}
                onAddItem={onAddItem}
                index={key}
              />
            );
        })}
      </div>

      <div className="upgradeAllKeysModal__total">
        <span className="upgradeAllKeysModal__total--text">
          You need to Burn
        </span>
        <div className="upgradeAllKeysModal__total--price">
          {formatterUS(totalAmount)} SMRTR
        </div>
      </div>

      <div className="upgradeAllKeysModal__buttons flex-grow justify-end items-end">
        <MarketplaceButton title="Cancel" onClick={onClose} />
        <MarketplaceButton
          disabled={isInsufficient}
          title={buttonTitle}
          onClick={onUpgrade}
          isBlue
        />
      </div>
    </ModalLayout>
  );
};

export default UpgradeAllKeysModal;
