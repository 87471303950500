import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Context } from "../../../../store";
import MarketplaceButton from "../../MarketplaceButton";
import ModalLayout from "../../Modals";
import { formatterUS } from "../../../../utils";
import { BASE_REWARD } from "../../../../constant/blockchain";
import useMakeNotificationSound from "../../../../hooks/useMakeSound";
import SocialLinkComponent from "components/common/SocialLinkComponent";
import { NFKEY_SOCIALS } from "constant/singleCollectionPage";
import { useNavigate } from "react-router-dom";
import {useTokenContext} from "../../../../contexts/tokenContext";
import Lottie from "lottie-react";
import celebration from './celebration.json';
import axios from "axios";

const TreasureBoxModal = ({
  isOpen,
  onClose,
  chestStatus,
  onTryAgain,
  amountOpenChest,
  setIsAnimated,
  prize,
}) => {
  const [{ loader }] = useContext(Context);
  const { selectedToken, prizeSeasonRoot, refreshPrizeInfo } = useTokenContext();

  const [isTextShown, setIsTextShown] = useState(false);
  const [socials, setSocials] = useState([]);
  const [prizeRevealed, setPrizeRevealed] = useState(false);
  const audio = useMakeNotificationSound();
  const navigate = useNavigate();
  const showPrize = chestStatus || prize?.won;

  const [prizeImage, setPrizeImage] = useState();
  const [prizeInfo, setPrizeInfo] = useState();
  useEffect(() => {
    const getPrizeData = async () => {
      if (!prizeSeasonRoot) {
        await refreshPrizeInfo();
        return;
      }

      if (prize?.won) {
        setPrizeImage(`${prizeSeasonRoot}images/${prize.prizeId}.svg`)
        const {data} = await axios.get(`${prizeSeasonRoot}info/${prize.prizeId}.json`);
        setPrizeInfo(data);
      } else {
        setPrizeImage(`${prizeSeasonRoot}images/0.svg`)
      }
    };
    getPrizeData();
  }, [prize, prizeSeasonRoot, refreshPrizeInfo]);


  const estPerMonth = selectedToken?.staked
    ? +selectedToken?.level * selectedToken?.tierTresr * BASE_REWARD * 30
    : 0;

  const onCloseModal = () => {
    onClose();
    setIsTextShown(false);
    setIsAnimated(false);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setIsTextShown(true), 6000);
      chestStatus
        ? audio("audio/ModalVideoGame-Success.mp3")
        : audio("audio/ModalVideoGame-Failure.mp3");
    } else setIsTextShown(false);
    setSocials(NFKEY_SOCIALS);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  if (chestStatus === null) return null;
  return (
    <ModalLayout
      maxHeight={"580px"}
      // onClose={onCloseModal}
      maxWidth={"580px"}
      padding={"0"}
      isOpen={isOpen}
      // withCrossIcon
      isFullModalBg
    >
      {chestStatus ? (
        <div
          className={`treasureBoxModal__success ${
            isTextShown ? "treasureBoxModal__visible" : ""
          }`}
        >
          <p className="modal__title treasureBoxModal__title">Success!</p>
          <p className="modal__text treasureBoxModal__title">
            Your Treasure Tier for
            <span className="modal__text--blue">
              {" "}
              Treasure Key #{selectedToken?.tokenId}{" "}
            </span>
            is now{" "}
            <span className="treasureBoxModal__text--yellow">
              Tier {selectedToken?.tierTresr}
            </span>
            !<br />
            Now your rewards are{" "}
            <span className="treasureBoxModal__text--yellow">
              {estPerMonth} $TRESR/month
            </span>
          </p>
          <p className="modal__text treasureBoxModal__title">
            Remember: Your Treasure Tier is temporary and must be updated before
            the timer runs out.
          </p>
          <div className="treasureBoxModal__socials mt-[180px]">
            {socials?.map((item, key) => (
              <SocialLinkComponent
                key={key}
                isEditMode={false}
                link={item?.link}
                index={key}
                isNFTPage
              />
            ))}
          </div>
        </div>
      ) : (
        <div
          className={`treasureBoxModal__failure ${
            isTextShown ? "treasureBoxModal__visible" : ""
          }`}
        >
          <p className="modal__title treasureBoxModal__title">Oooops!</p>
          <p className="modal__text treasureBoxModal__title">
            The box didn't open. Your Treasure Tier will remain at{" "}
            <span className="treasureBoxModal__text--gray">
              Tier {selectedToken?.tierTresr}
            </span>
            .<br />
            You can burn{" "}
            <span className="treasureBoxModal__text--gray">
              {formatterUS(amountOpenChest)} $TRESR
            </span>{" "}
            to try to open the Treasure Box again
          </p>
        </div>
      )}

      {
        prizeRevealed && prize?.won && <div
        style={{ height: "auto"}}
          className={`treasureBoxModal__success ${
            isTextShown ? "treasureBoxModal__visible" : ""
          }`}
        >
          <Lottie className={"treasureBoxModal__celebration"} animationData={celebration} loop={true} />;
          <p className="modal__title treasureBoxModal__title">You've Won!</p>
          <img className={'treasureBoxModal__prize'} src={prizeImage} />
          <p className="modal__text treasureBoxModal__title treasureBoxModal_prize-description">{prizeInfo?.description}</p>
        </div>
      }

      {
        prizeRevealed && !prize?.won && <div
          style={{ height: "auto"}}
          className={`treasureBoxModal__success ${
            isTextShown ? "treasureBoxModal__visible" : ""
          }`}
        >
          <p className="modal__title treasureBoxModal__title">No extra prizes this time.</p>
          <img className={"treasureBoxModal__prize"} src={prizeImage} />
          <p className="modal__text treasureBoxModal__title">But feel free to take a deep breath.</p>
        </div>
      }

      <div className={`treasureBoxModal__video ${chestStatus && "-z-10 relative"}`}>
        <video
          src={
            chestStatus
              ? "https://artifactory-nfkey.nyc3.digitaloceanspaces.com/static-space/TreasureBox-Success-LowRes.mp4"
              : "https://artifactory-nfkey.nyc3.digitaloceanspaces.com/static-space/TreasureBox-Fail-LowRes.mp4"
          }
          autoPlay
          muted
          height={580}
          width={580}
        />
      </div>

      {(!showPrize || prizeRevealed) &&
        <>
          {chestStatus ? (
            <div
              className={`treasureBoxModal__buttons ${
                isTextShown ? "treasureBoxModal__buttons--visible" : ""
              }`}
            >
              <MarketplaceButton
                isWhite
                title={"Back to Dashboard"}
                onClick={onCloseModal}
              />
              {!loader?.isActive &&
              <MarketplaceButton
                isBlue
                title={"Open Another"}
                onClick={onTryAgain}
              />}
            </div>
          ) : (
            <div
              className={`treasureBoxModal__buttons ${
                isTextShown ? "treasureBoxModal__buttons--visible" : ""
              }`}
            >
              <MarketplaceButton
                isWhite
                title={"Back to Dashboard"}
                onClick={onCloseModal}
              />
              <MarketplaceButton isBlue title={"Try Again "} onClick={onTryAgain} />
            </div>
          )}
        </>
      }
      {
        (showPrize && !prizeRevealed) && (
          <div
            className={`treasureBoxModal__buttons ${
              isTextShown ? "treasureBoxModal__buttons--visible" : ""
            }`}
          >
            <MarketplaceButton
              isGolden
              title={"Prize Season Active! Get Result"}
              onClick={() => setPrizeRevealed(true)}
            />
          </div>
        )
      }
    </ModalLayout>
  );
};

export default TreasureBoxModal;
