import treasureImg from "../../../assets/images/treasure.png";
import "./style.scss";
import { BASE_REWARD, MAX_CHEST_TIER } from "../../../constant/blockchain";
import Tooltip from "../../common/Tooltip";
import { formatterUS } from "../../../utils";
import {useTokenContext} from "../../../contexts/tokenContext";
import KeyTimer from "components/common/KeyTimer";

export default function DashboardTreasureTile({
  onOpenModal,
  onOpenWarningModal,
  amountOpenChest,
  isAnimated,
  isDark,
}) {
  const { selectedToken } = useTokenContext();

  const progressWidth = selectedToken?.tierTresr
    ? 100 / (MAX_CHEST_TIER / selectedToken?.tierTresr)
    : 0;
  const isMaxLevel = MAX_CHEST_TIER === selectedToken?.tierTresr;
  const isActive = !!selectedToken?.staked;
  const openAttemptTitle = isMaxLevel
    ? "-"
    : `${formatterUS(amountOpenChest)} TRESR`;
  const nextTierEstTitle = isMaxLevel
    ? "-"
    : `${
        +selectedToken?.level *
        (selectedToken?.tierTresr + 1) *
        BASE_REWARD *
        30
      } TRESR`;

  const onAction = () => (isActive ? onOpenModal() : onOpenWarningModal());

  return (
    <div
      className={`treasure-tile ${
        isAnimated ? "nft__image--placeholder treasure-tile__isAnimated" : ""
      }`}
    >
      {!isAnimated && (
        <div className="treasure-tile__left">
          {!selectedToken && <div className="treasure-tile__blurred" />}
          {!!(selectedToken?.staked || !selectedToken) ? (
            selectedToken?.tierTresr !== 0 ? (
              <>
                <div className="treasure-tile__left__title">
                  Treasure Tier expires in
                </div>
                <KeyTimer
                  size='large'
                  token={selectedToken}
                  isActive={true}
                />
              </>
            ) : (
              <>
                <div className="treasure-tile__left__title text-center">
                  Unlock a Treasure Box to Start Earning $TRESR
                </div>
              </>
            )
          ) : (
            <div className="treasure-tile__left__title">
              Need to Activate Treasure Key
            </div>
          )}
          <div className="treasure-tile__left__description">
            <span className="-mt-1">Open Attempt</span>
            <Tooltip tooltipText={"Burn $TRESR to attempt Unlock"} top={-35}>
              <div className="flex">
                <div className="-mt-1">{openAttemptTitle}</div>
                <div className="w-[23px] ml-[5px] -mr-[35px]">
                  {/* {loader?.componentActive && (
                    <ClipLoader
                      size={16}
                      color={isDark ? "#fff" : "#4964fe80"}
                    />
                  )} */}
                </div>
              </div>
            </Tooltip>
          </div>

          <div className="treasure-tile__left__description treasure-tile__left__description--bottom">
            <span className="-mt-1">Next Tier Est. Monthly</span>
            <div className="flex">
              <div className="-mt-1">{nextTierEstTitle}</div>
              <div className="w-[23px] ml-[5px] -mr-[35px]">
                {/* {loader?.componentActive && (
                  <ClipLoader size={16} color={isDark ? "#fff" : "#4964fe80"} />
                )} */}
              </div>
            </div>
          </div>

          <Tooltip
            tooltipText={
              "Increase Rewards by Unlocking the Next Treasure Tier"
            }
          >
            <div className="treasure-tile__left__progress">
              <div
                className="treasure-tile__left__progress--line"
                style={{ width: `${progressWidth}%` }}
              />
              <div className="treasure-tile__left__progress--text">
                Treasure Tier: {selectedToken?.tierTresr}/{MAX_CHEST_TIER}
              </div>
            </div>
          </Tooltip>
        </div>
      )}
      <div className="treasure-tile__right">
        <div className="flex justify-center">
          <img src={treasureImg} alt="" />
          <button className={`button active`} onClick={onAction}>
            Unlock
          </button>
        </div>
      </div>
    </div>
  );
}
