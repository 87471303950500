import React from "react";
import ModalLayout from "../index";
import { formatterUS } from "../../../../utils";
import MarketplaceButton from "../../MarketplaceButton";
import { BASE_REWARD, MAX_CHEST_TIER } from "constant/blockchain";

const OpenTreasureBoxModal = ({
  onClose,
  isOpen,
  selectedToken,
  amountOpenChest,
  avaxFee,
  onOpenTreasure,
  balances,
  probToOpen,
}) => {
  return (
    <ModalLayout
      onClose={onClose}
      isOpen={isOpen}
      maxWidth={"460px"}
      maxHeight={"560px"}
      padding={"32px"}
      withCrossIcon
    >
      <p className="modal__title">Open a Treasure Box</p>
      <p className="modal__text upgradeKeyModal__text">
        You are about to attempt to open a Treasure Box. { selectedToken?.tierTresr > 0 ? 'Complete both steps.' : '' }
      </p>

      <div className="flex justify-between">
        <p className="modal__text upgradeKeyModal__text">With the Key #</p>
        <p className="modal__text upgradeKeyModal__text">
          Treasure Key #{selectedToken?.tokenId}
        </p>
      </div>

      <div className="flex justify-between -mt-[15px]">
        <p className="modal__text upgradeKeyModal__text">
          Probability of opening
        </p>
        <p className="modal__text upgradeKeyModal__text">
          {probToOpen?.toFixed(1)}%
        </p>
      </div>

      <div className="-mt-[15px]">
        <p className="modal__text text-[#fbfbfb70] text-[12px]">
          Improve your probability by staking TRESR to earn veTRESR.
        </p>
      </div>

      <div className="flex justify-between -mb-8">
        <p className="modal__text upgradeKeyModal__text">Your TRESR Balance</p>
        <div className="flex">
          <p className="modal__text upgradeKeyModal__text">
            {formatterUS(+balances.balanceTresr)} TRESR
          </p>
        </div>
      </div>

      <div className="flex justify-between -mb-8">
        <p className="modal__text upgradeKeyModal__text">Current{"   "}: Tier {selectedToken?.tierTresr} Rewards</p>
        <div className="flex">
          <p className="modal__text upgradeKeyModal__text">
            {+selectedToken?.level * (selectedToken?.tierTresr) * BASE_REWARD * 30} TRESR / MONTH
          </p>
        </div>
      </div>

      <div className="flex justify-between -mb-3">
        <p className="modal__text upgradeKeyModal__text">Attempt: Tier {MAX_CHEST_TIER === selectedToken?.tierTresr ? '-' : selectedToken?.tierTresr + 1} Rewards</p>
        <div className="flex">
          <p className="modal__text upgradeKeyModal__text">
            {MAX_CHEST_TIER === selectedToken?.tierTresr ? '-' : +selectedToken?.level * (selectedToken?.tierTresr + 1) * BASE_REWARD * 30} TRESR / MONTH
          </p>
        </div>
      </div>

      <div className="flex justify-between -mb-8">
        <p className="modal__text upgradeKeyModal__text">Burn Fee</p>
        <div className="flex">
          <p className="modal__text upgradeKeyModal__text">
            {formatterUS(amountOpenChest)} TRESR
          </p>
        </div>
      </div>
      <div className="flex justify-between -mb-5">
        <p className="modal__text upgradeKeyModal__text">Gelato VRF</p>
        <div className="flex">
          <p className="modal__text upgradeKeyModal__text">
            {formatterUS(avaxFee)} AVAX
          </p>
        </div>
      </div>
      <div className="flex-grow justify-center items-end flex">
        <p className="modal__text upgradeKeyModal__text">
          This action cannot be undone.
        </p>
      </div>
      <div className="upgradeKeyModal__buttons">
        <MarketplaceButton title={"Cancel"} onClick={onClose} />
        <MarketplaceButton
          disabled={+amountOpenChest > +balances.balanceTresr || avaxFee > balances.balanceAvax}
          title={
            +amountOpenChest > +balances.balanceTresr
              ? "Insufficient TRESR"
              : avaxFee > balances.balanceAvax
              ? "Insufficient AVAX"
              : `Burn ${formatterUS(amountOpenChest)} $TRESR`
          }
          isBlue
          onClick={onOpenTreasure}
        />
      </div>
    </ModalLayout>
  );
};

export default OpenTreasureBoxModal;
